import { Stack, Group, Select, Text, Button, Image, Title, Box } from '@mantine/core';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useState, useEffect, useRef } from 'react';
import { useAppStore } from 'stores/appStore';
import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth';
import {
  CalendarEventIndex,
  MLB_TEAM_KEYS,
  MLB_TEAM_NAME_MAP,
  MlbTeamType,
  SCHEDULE_TO_YEAR_MAP,
} from 'utils/scheduleConsts';
import { processMlbEntriesForCalendar } from 'utils/scheduleUtils';

import './ScheduleCalendar.module.css';

export function ScheduleCalendar() {
  const navigate = useNavigate({ from: '/schedules' });
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);

  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedTeam, setSelectedTeam] = useState<MlbTeamType>(
    routeSearch.team ? routeSearch.team : 'NYY'
  );

  const calendarRef = useRef<FullCalendar>(null);

  const [events, setEvents] = useState<CalendarEventIndex>([]);
  const [numColumns, setNumColumns] = useState(3);

  useEffect(() => {
    // @ts-ignore
    const newDate = `${SCHEDULE_TO_YEAR_MAP[Number(currentScheduleId)] || '2024'}-03-01`;
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(newDate);
    }
  }, [currentScheduleId]);

  useEffect(() => {
    navigate({
      to: '/schedules',
      search: (prev) => ({ s: prev.s, t: 'calendar', team: selectedTeam, compare: prev.compare }),
    });

    async function fetchScheduleJson() {
      const newEvents = processMlbEntriesForCalendar(currentScheduleId, selectedTeam);
      setEvents(newEvents);

      if (calendarRef.current) {
        calendarRef.current.getApi().updateSize();
      }
    }
    fetchScheduleJson();
  }, [selectedTeam, currentScheduleId]);

  return (
    <Stack gap="xs" align="stretch" h="100%">
      <Group justify="space-between">
        <Group align="center">
          <Text fw={500}>Team</Text>
          <Select
            value={selectedTeam}
            onChange={(value) => setSelectedTeam(value as MlbTeamType)}
            allowDeselect={false}
            data={MLB_TEAM_KEYS.slice().sort()}
            searchable
            w="100px"
          />
          <Group gap="xs">
            <Box h={32}>
              <Image
                src={`https://s3.us-east-1.amazonaws.com/recentive-static/assets/img/mlb_logos/${selectedTeam}_logo.png`}
                height={32}
                width="auto"
                fit="cover"
                alt={`${selectedTeam} logo`}
              />
            </Box>
            <Title order={4}>
              {MLB_TEAM_NAME_MAP[selectedTeam as keyof typeof MLB_TEAM_NAME_MAP]}
            </Title>
          </Group>
        </Group>
        <Button variant="default" size="sm" onClick={() => setNumColumns(numColumns === 3 ? 1 : 3)}>
          Toggle View
        </Button>
      </Group>
      <FullCalendar
        height="100%"
        initialDate="2024-10-01"
        plugins={[multiMonthPlugin]}
        initialView="multiMonthMLBSeason"
        views={{
          multiMonthMLBSeason: {
            type: 'multiMonthYear',
            eventDisplay: 'background',
            duration: { months: 7 },
            titleFormat: () => `${selectedTeam} Schedule`,
          },
        }}
        multiMonthMaxColumns={numColumns}
        headerToolbar={false}
        events={events}
        ref={calendarRef}
      />
    </Stack>
  );
}
