import { useEffect } from 'react';
import { Divider, Flex, Stack, Title, MultiSelect } from '@mantine/core';
import { createFileRoute, getRouteApi, useNavigate } from '@tanstack/react-router';
import { z } from 'zod';

import { useAppStore } from 'stores/appStore';

// import classes from './compare.module.css';
import { SCHEDULE_INDEX_MAP } from 'utils/scheduleConsts';
import CompareTable from 'components/CompareTable';
import {
  MLB_2024_FULL_SCHEDULE_METRICS as MLB_2024_AGGREGATE_METRICS,
  MLB_2025_AGGREGATE_METRICS,
} from 'utils/scheduleData';

function ComparePage() {
  const navigate = useNavigate({ from: '/compare' });
  const routeSearch = getRouteApi('/_authenticated/compare').useSearch();
  const { comparedScheduleIdList, setComparedScheduleIdList } = useAppStore();

  // Load in `compare` search param from URL.
  const compare = Array.from(routeSearch.compare || []);
  useEffect(() => {
    if (!compare.length) {
      const newCompare = Object.keys(SCHEDULE_INDEX_MAP).reverse().slice(0, 3);
      setComparedScheduleIdList(newCompare);
    }
    if (compare.join(',') !== comparedScheduleIdList.join(',')) {
      setComparedScheduleIdList(compare);
    }
  }, []);

  return (
    <Flex h="calc(100dvh - 64px)" m="md">
      <Stack w="100%" gap="xs">
        <Title order={2}>Compare Schedules</Title>
        <Divider />
        <Stack>
          <MultiSelect
            label="Select schedules to compare"
            data={Object.entries(SCHEDULE_INDEX_MAP).map(([id, name]) => ({
              label: name,
              value: id,
            }))}
            value={comparedScheduleIdList}
            searchable
            onChange={(value) => {
              const sortedValue = value.sort((a, b) => Number(a) - Number(b));
              setComparedScheduleIdList(sortedValue);
              navigate({
                to: '/compare',
                search: { compare: sortedValue.length ? sortedValue : undefined },
              });
            }}
            w={800}
          />
          {comparedScheduleIdList.length ? (
            <CompareTable
              scheduleMetrics={[MLB_2025_AGGREGATE_METRICS, MLB_2024_AGGREGATE_METRICS]}
            />
          ) : null}
        </Stack>
      </Stack>
    </Flex>
  );
}

const schedulesSearchSchema = z.object({
  // compare
  compare: z.string().array().optional(),
});

export const Route = createFileRoute('/_authenticated/compare')({
  component: ComparePage,
  validateSearch: (search) => schedulesSearchSchema.parse(search),
});
