import { getBuildsList } from 'api/api';
import { BuildListEntry, MlbSchedule, ScheduleListEntry } from 'utils/scheduleConsts';
import {
  MLB_2024_AL_SCHEDULE,
  MLB_2024_NL_SCHEDULE,
  MLB_2025_AL_SCHEDULE,
  MLB_2025_NL_SCHEDULE,
} from 'utils/scheduleData';
import { create } from 'zustand';

export interface AppState {
  userEmail: string;
  setUserEmail: (email: string) => void;
  isAppLoading: boolean;
  setIsAppLoading: (isAppLoading: boolean) => void;
  isScheduleLoading: boolean;
  setIsScheduleLoading: (isScheduleLoading: boolean) => void;
  currentScheduleId: string;
  setCurrentScheduleId: (id: string) => void;
  currentScheduleName: string;
  schedulesList: ScheduleListEntry[];
  comparedScheduleIdList: string[];
  setComparedScheduleIdList: (idList: string[]) => void;
  buildsList: BuildListEntry[];
  fetchSchedules: (targetScheduleId?: string) => void;
  fetchScheduleData: (id: string) => void;
  fetchMlbSchedule: (id: string) => void;
  currentMlbSchedule: MlbSchedule;
  setCurrentMlbSchedule: (schedule: MlbSchedule) => void;
}

export const useAppStore = create<AppState>((set, get) => ({
  userEmail: '',
  setUserEmail: (email: string) => set(() => ({ userEmail: email })),
  isAppLoading: true,
  setIsAppLoading: (isAppLoading: boolean) => set(() => ({ isAppLoading })),
  isScheduleLoading: false,
  setIsScheduleLoading: (isScheduleLoading: boolean) => set(() => ({ isScheduleLoading })),
  currentScheduleId: '',
  setCurrentScheduleId: (id: string) => set(() => ({ currentScheduleId: id })),
  currentScheduleName: '',
  schedulesList: [],
  comparedScheduleIdList: [],
  setComparedScheduleIdList: (idList: string[]) => {
    set(() => ({ comparedScheduleIdList: idList }));
  },
  buildsList: [],
  setSchedulesList: (schedules: ScheduleListEntry[]) => set(() => ({ schedulesList: schedules })),
  fetchSchedules: async (targetScheduleId?: string) => {
    set(() => ({ isAppLoading: true }));
    try {
      const buildsList = await getBuildsList();

      // Get all schedules from the builds list.
      const schedulesList = buildsList.flatMap((build) => build.results);
      set(() => ({ schedulesList }));
      set(() => ({ buildsList }));
      if (targetScheduleId && schedulesList.find((schedule) => schedule.id === targetScheduleId)) {
        set(() => ({ currentScheduleId: targetScheduleId }));
      } else if (get().currentScheduleId === '' && schedulesList.length) {
        set(() => ({ currentScheduleId: schedulesList[0].id }));
      } else if (get().currentScheduleId !== '') {
        set(() => ({
          currentScheduleName:
            schedulesList.find((schedule) => schedule.id === get().currentScheduleId)?.name || '',
        }));
      }

      set(() => ({ isAppLoading: false }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error in data fetch:', err);
      set(() => ({ isAppLoading: false }));
    }
  },
  fetchScheduleData: async (id: string) => {
    set(() => ({ isScheduleLoading: true }));
    try {
      set(() => ({
        currentScheduleName: get().schedulesList.find((schedule) => schedule.id === id)?.name || '',
      }));

      set(() => ({ isScheduleLoading: false }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error in data fetch:', err);
      set(() => ({ isScheduleLoading: false }));
    }
  },
  fetchMlbSchedule: async (id: string) => {
    if (id === '1') {
      get().setCurrentMlbSchedule([...MLB_2025_AL_SCHEDULE, ...MLB_2025_NL_SCHEDULE]);
    } else if (id === '2') {
      get().setCurrentMlbSchedule([...MLB_2024_AL_SCHEDULE, ...MLB_2024_NL_SCHEDULE]);
    }
  },
  currentMlbSchedule: [],
  setCurrentMlbSchedule: (schedule: MlbSchedule) => set(() => ({ currentMlbSchedule: schedule })),
}));
