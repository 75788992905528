import { Stack, Group, Select, Text, AspectRatio, Box, Image, Title } from '@mantine/core';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useAppStore } from 'stores/appStore';
import { MLB_TEAM_KEYS, MLB_TEAM_NAME_MAP, MlbTeamType } from 'utils/scheduleConsts';
import { getImportedIframeUrl } from 'utils/scheduleUtils';

export function ScheduleMap() {
  const navigate = useNavigate({ from: '/schedules' });
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);

  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedTeam, setSelectedTeam] = useState<MlbTeamType>(
    routeSearch.team ? routeSearch.team : 'NYY'
  );

  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    setIframeUrl('');
    navigate({
      to: '/schedules',
      search: (prev) => ({ s: prev.s, t: 'map', team: selectedTeam, compare: prev.compare }),
    });

    const mapUrl = `schedules/${currentScheduleId}/html/${selectedTeam}_schedule_map.html`;
    getImportedIframeUrl(mapUrl).then((res) => {
      setIframeUrl(res);
    });
  }, [selectedTeam, currentScheduleId]);

  return (
    <Stack gap="xs" h="100%">
      <Group align="center">
        <Text fw={500}>Team</Text>
        <Select
          value={selectedTeam}
          onChange={(value) => setSelectedTeam(value as MlbTeamType)}
          allowDeselect={false}
          data={MLB_TEAM_KEYS.slice().sort()}
          searchable
          w="100px"
        />
        <Group gap="xs">
          <Box h={32}>
            <Image
              src={`https://s3.us-east-1.amazonaws.com/recentive-static/assets/img/mlb_logos/${selectedTeam}_logo.png`}
              height={32}
              width="auto"
              fit="cover"
              alt={`${selectedTeam} logo`}
            />
          </Box>
          <Title order={4}>
            {MLB_TEAM_NAME_MAP[selectedTeam as keyof typeof MLB_TEAM_NAME_MAP]}
          </Title>
        </Group>
      </Group>
      <AspectRatio ratio={16 / 8}>
        {iframeUrl && (
          <iframe src={iframeUrl} title="Schedule Map" style={{ border: 'none' }} loading="lazy" />
        )}
      </AspectRatio>
    </Stack>
  );
}
