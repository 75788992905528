// import { useEffect, useMemo, useState } from 'react';
import { Group, Stack, Table, TableData, Title, rem } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/sharp-regular-svg-icons';

import { MlbAggregateMetrics } from 'utils/scheduleData';

import { SCHEDULE_INDEX_MAP } from 'utils/scheduleConsts';
import { formatNumber } from 'utils/scheduleUtils';
import classes from './CompareTable.module.css';

export function CompareTable({ scheduleMetrics }: { scheduleMetrics: MlbAggregateMetrics[] }) {
  const head = [
    '',
    ...Object.values(SCHEDULE_INDEX_MAP).map((info, idx) => (
      <Group gap="xs" className={idx === 0 ? classes.highestRank : ''}>
        <Title order={6} style={{ textWrap: 'balance' }} title={info}>
          {idx === 0 && (
            <FontAwesomeIcon
              style={{ width: rem(14), height: rem(14), paddingRight: '8px' }}
              icon={faBadgeCheck}
            />
          )}
          {info}
        </Title>
      </Group>
    )),
  ];
  const rows = [
    [
      'Total travel distance',
      ...scheduleMetrics.map((info) => `${formatNumber(info.travel_distance_in_miles_total)} mi`),
    ],
    [
      'Average travel distance',
      ...scheduleMetrics.map((info) => `${formatNumber(info.travel_distance_in_miles_average)} mi`),
    ],
    [
      'Total travel time',
      ...scheduleMetrics.map(
        (info) => `${formatNumber(info.travel_time_in_minutes_total / 1440)} days`
      ),
    ],
    [
      'Average travel time',
      ...scheduleMetrics.map(
        (info) => `${formatNumber(info.travel_time_in_minutes_average / 1440)} days`
      ),
    ],
    ['20-game streak count', ...scheduleMetrics.map((info) => `${info.twenty_games_streak_count}`)],
    [
      'Home off day count',
      ...scheduleMetrics.map((info) => `${info.true_home_off_day_count} days`),
    ],
    [
      'Home weekend average',
      ...scheduleMetrics.map((info) => `${formatNumber(info.home_weekend_average)} days`),
    ],
    [
      'Home series in July average',
      ...scheduleMetrics.map((info) => `${formatNumber(info.home_series_in_july_average)} days`),
    ],
    [
      'Home weekends in July average',
      ...scheduleMetrics.map((info) => `${formatNumber(info.home_weekends_in_july_average)} days`),
    ],
    [
      'Carry over Monday count',
      ...scheduleMetrics.map((info) => `${info.carry_over_monday_count}`),
    ],
    ['Semi-repeater count', ...scheduleMetrics.map((info) => `${info.semi_repeater_count}`)],
  ];
  const tableData: TableData = {
    head,
    body: rows,
  };
  return (
    <Stack className={classes.scheduleCompareContainer} gap="md">
      <Table
        horizontalSpacing="sm"
        data={tableData}
        striped
        highlightOnHover
        withRowBorders={false}
        withColumnBorders
      />
    </Stack>
  );
}
