import { useEffect } from 'react';
import { Outlet, createFileRoute, useSearch } from '@tanstack/react-router';

import UbiquiaAppShell from 'components/UbiquiaAppShell';
import { useAppStore } from 'stores/appStore';
// import { getUserInfo } from 'api/api';

export const Route = createFileRoute('/_authenticated')({
  // beforeLoad: async ({ context }) => {
  //   const { isLogged } = context.authentication;
  //   if (!isLogged()) {
  //     // eslint-disable-next-line @typescript-eslint/no-throw-literal
  //     throw redirect({ to: '/login' });
  //   }
  //   if (!useAppStore.getState().userEmail) {
  //     try {
  //       const userInfo = await getUserInfo();
  //       // datadogRum.setUser({ email: userInfo.email });
  //       useAppStore.getState().setUserEmail(userInfo.email);
  //       localStorage.setItem('mlb-ubiquia-user-id', userInfo.id);
  //     } catch (error) {
  //       useAppStore.getState().setUserEmail('');
  //       // eslint-disable-next-line @typescript-eslint/no-throw-literal
  //       throw redirect({ to: '/logout' });
  //     }
  //   }
  // },
  component: () => {
    // @ts-ignore
    const userProvidedScheduleId = useSearch({
      // @ts-ignore
      select: (search) => search.s,
    });
    const [fetchBuildsList, setIsAppLoading] = useAppStore((store) => [
      store.fetchSchedules,
      store.setIsAppLoading,
    ]);
    useEffect(() => {
      const fetchHelper = async () => {
        await fetchBuildsList((userProvidedScheduleId as string) || undefined);
        setIsAppLoading(false);
      };
      fetchHelper();
    }, []);
    return (
      <UbiquiaAppShell>
        <Outlet />
      </UbiquiaAppShell>
    );
  },
});
