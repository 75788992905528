import { Box, Flex, Group, Image, Stack, Text } from '@mantine/core';
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import cx from 'clsx';

import { EyeChartRow, GameEntry, MLB_TEAM_KEYS, MlbTeamType, TeamType } from 'utils/scheduleConsts';
// import { useAppStore } from 'stores/appStore';
import {
  MLB_2024_AL_SCHEDULE,
  MLB_2024_NL_SCHEDULE,
  MLB_2025_AL_SCHEDULE,
  MLB_2025_NL_SCHEDULE,
} from 'utils/scheduleData';
import dayjs from 'dayjs';
import { useAppStore } from 'stores/appStore';
import { getEyeChartData } from 'utils/scheduleUtils';
import sharedClasses from './EyeChartSharedStyles.module.css';
import classes from './ScheduleEyeChart.module.css';

const columnHelper = createColumnHelper<EyeChartRow>();

const cellGenerator =
  (team: TeamType) => (info: CellContext<EyeChartRow, GameEntry | undefined>) => {
    const home = info.getValue()?.home;
    const away = info.getValue()?.away;
    let val = ' ';

    let isHome = false;
    let isDivision = false;
    let isLeague = false;
    let isInterleague = false;
    if (home && away) {
      val = team === home ? away : home;
      isHome = team === home;
      isDivision =
        Math.floor(MLB_TEAM_KEYS.indexOf(home) / 5) === Math.floor(MLB_TEAM_KEYS.indexOf(away) / 5);
      isLeague =
        !isDivision &&
        Math.floor(MLB_TEAM_KEYS.indexOf(home) / 15) ===
          Math.floor(MLB_TEAM_KEYS.indexOf(away) / 15);
      isInterleague = !isDivision && !isLeague;
    }

    return (
      <p
        title={`${info.getValue()?.date} - ${info.getValue()?.away} @ ${info.getValue()?.home}`}
        className={cx([
          isHome ? classes.home : classes.away,
          isInterleague ? classes.interleague : '',
          isDivision ? classes.division : '',
          isLeague ? classes.league : '',
          info.row.original.isBreak ? classes.break : '',
        ])}
      >
        {val}
      </p>
    );
  };

const columnGenerator = (teams: MlbTeamType[]) =>
  teams.map((team) =>
    columnHelper.accessor(team, {
      cell: cellGenerator(team),
      // @ts-ignore
      header: (
        <Stack gap="1">
          <Image
            src={`https://s3.us-east-1.amazonaws.com/recentive-static/assets/img/mlb_logos/${team}_logo.png`}
            width="auto"
            fit="cover"
            p="8%"
            m="0"
            alt={`${team} logo`}
          />
          <p style={{ margin: 0 }}>{team}</p>
        </Stack>
      ),
    })
  );

const columns = [
  // columnHelper.display({
  //   id: 'week',
  //   cell: (info) => info.row.original.week,
  // }),
  columnHelper.display({
    id: 'date',
    cell: (info) =>
      info.row.original.isBreak ? '-' : dayjs(info.row.original.date, 'YYYY-MM-DD').format('M/D'),
  }),
  columnHelper.group({
    header: 'American',
    columns: [
      columnHelper.group({
        header: 'AL West',
        columns: columnGenerator(MLB_TEAM_KEYS.slice(0, 5)),
      }),
      columnHelper.group({
        header: 'AL Central',
        columns: columnGenerator(MLB_TEAM_KEYS.slice(5, 10)),
      }),
      columnHelper.group({
        header: 'AL East',
        columns: columnGenerator(MLB_TEAM_KEYS.slice(10, 15)),
      }),
    ],
  }),
  columnHelper.group({
    header: 'National',
    columns: [
      columnHelper.group({
        header: 'NL West',
        columns: columnGenerator(MLB_TEAM_KEYS.slice(15, 20)),
      }),
      columnHelper.group({
        header: 'NL Central',
        columns: columnGenerator(MLB_TEAM_KEYS.slice(20, 25)),
      }),
      columnHelper.group({
        header: 'NL East',
        columns: columnGenerator(MLB_TEAM_KEYS.slice(25, 30)),
      }),
    ],
  }),
];

const data = getEyeChartData([...MLB_2025_AL_SCHEDULE, ...MLB_2025_NL_SCHEDULE]);
const data2 = getEyeChartData([...MLB_2024_AL_SCHEDULE, ...MLB_2024_NL_SCHEDULE]);

export function ScheduleEyeChart() {
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);

  const table = useReactTable({
    data: currentScheduleId === '1' ? data : data2,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {' '}
      <Flex
        align="center"
        direction="column"
        gap="0"
        className={[sharedClasses.ubiquiaEyeChartContainer, classes.scheduleEyeChartContainer].join(
          ' '
        )}
      >
        <Group
          gap="xl"
          pt="xs"
          bg="white"
          style={{ zIndex: 2 }}
          w="100%"
          mx="auto"
          justify="center"
        >
          <Group>
            <Text c="gray" size="xs" fw={500}>
              Division
            </Text>
            <Box bg="#0fb48c" w="25px" h="15px" />
          </Group>
          <Group>
            <Text c="gray" size="xs" fw={500}>
              League
            </Text>
            <Box bg="#137cd9" w="25px" h="15px" />
          </Group>
          <Group>
            <Text c="gray" size="xs" fw={500}>
              Interleague
            </Text>
            <Box bg="#ff4252" w="25px" h="15px" />
          </Group>
        </Group>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <Image src={scheduleLegend} alt="Schedule Legend" w="1000" fit="contain" /> */}
      </Flex>
    </>
  );
}
