import { createFileRoute } from '@tanstack/react-router';

import {
  Button,
  Group,
  Container,
  Select,
  Stack,
  Text,
  Title,
  rem,
  Divider,
  Flex,
  Modal,
  Tooltip,
  ActionIcon,
  LoadingOverlay,
  TextInput,
  Tabs,
  Box,
  NumberInput,
  SimpleGrid,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import ConstraintEditor from 'components/ConstraintEditor';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useAppStore } from 'stores/appStore';
import {
  ConstraintEntry,
  ConstraintSlot,
  ConstraintSlotTranslations,
  ConstraintType,
  ConstraintTypeTranslations,
  HolidaySlotTranslations,
  RecursivePartial,
  StaticConstraintEntry,
} from 'utils/scheduleConsts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownToBracket,
  faCircleMinus,
  faPenToSquare,
} from '@fortawesome/sharp-regular-svg-icons';
import { faBolt, faCircle } from '@fortawesome/sharp-solid-svg-icons';

import classes from './schedulebuilder.module.css';

const getStringifiedConstraint = (entry: ConstraintEntry) => {
  switch (entry.type) {
    case ConstraintType.MATCHUP_GUARANTEE:
      return `${entry.params.matchup} ${entry.params.inclusive ? 'plays' : 'does not play'} on ${entry.params.network.sort().join(', ')}${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.week.includes('All') ? '' : ` on week ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.STADIUM_BLOCK:
      // eslint-disable-next-line no-case-declarations
      const allSelected = entry.params.slot.includes(ConstraintSlot.ALL);
      // eslint-disable-next-line no-case-declarations
      const slotText = `${
        allSelected
          ? ''
          : ` during ${entry.params.slot
              .map((slot) => ConstraintSlotTranslations[slot])
              .join(', ')}`
      }`;
      return `${entry.params.team} stadium is not available${slotText} on week ${entry.params.week}`;

    case ConstraintType.TEAM_GUARANTEE:
      return `${entry.params.team} plays ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s) on ${entry.params.network.join(', ')}${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.week.includes('All') ? '' : ` on week ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.BYE_GUARANTEE:
      return `${entry.params.team} ${entry.params.inclusive ? 'has' : 'does not have'} a bye week on week(s) ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`;
    case ConstraintType.HOME_AWAY_GUARANTEE:
      return `${entry.params.team.join(' or ')} plays ${entry.params.home_away === 'home' ? 'at Home' : 'Away'} ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s)${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.network.includes('All') ? '' : ` on ${entry.params.network.join(', ')}`}${entry.params.week.includes('All') ? '' : ` in week(s) ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.PRIMETIME_MIN_MAX:
      return `${entry.params.team.join(', ')} plays ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s)${entry.params.network.includes('All') ? '' : ` on ${entry.params.network.join(', ')}`}`;
    default:
      return 'Unknown Constraint';
  }
};

const isConstraintValid = (constraint: RecursivePartial<ConstraintEntry>) => {
  if (constraint.type === ConstraintType.STADIUM_BLOCK) {
    return Boolean(
      constraint.params?.team && constraint.params?.week && constraint.params?.slot?.length
    );
  }
  if (constraint.type === ConstraintType.MATCHUP_GUARANTEE) {
    return Boolean(
      constraint.params?.matchup &&
        constraint.params?.week?.length &&
        constraint.params?.network?.length &&
        (constraint.params?.inclusive === true || constraint.params?.inclusive === false)
    );
  }
  if (constraint.type === ConstraintType.TEAM_GUARANTEE) {
    return Boolean(
      constraint.params?.team?.length &&
        constraint.params?.week?.length &&
        constraint.params?.network?.length &&
        constraint.params?.min_appearances !== undefined &&
        constraint.params?.max_appearances !== undefined &&
        constraint.params.max_appearances >= constraint.params.min_appearances
    );
  }
  if (constraint.type === ConstraintType.BYE_GUARANTEE) {
    return Boolean(
      constraint.params?.team &&
        constraint.params?.week?.length &&
        (constraint.params?.inclusive === true || constraint.params?.inclusive === false)
    );
  }
  if (constraint.type === ConstraintType.HOME_AWAY_GUARANTEE) {
    return false;
    // return Boolean(
    //   constraint.params?.team?.length &&
    //     constraint.params?.home_away !== undefined &&
    //     constraint.params?.week?.length &&
    //     constraint.params?.network?.length &&
    //     constraint.params?.min_appearances !== undefined &&
    //     constraint.params?.max_appearances !== undefined &&
    //     constraint.params.max_appearances >= constraint.params.min_appearances
    // );
  }
  if (constraint.type === ConstraintType.PRIMETIME_MIN_MAX) {
    return Boolean(
      constraint.params?.team?.length &&
        constraint.params?.network?.length &&
        constraint.params?.min_appearances !== undefined &&
        constraint.params?.max_appearances !== undefined &&
        constraint.params.max_appearances >= constraint.params.min_appearances
    );
  }
  return false;
};

function ScheduleBuilder() {
  const [isValid, setIsValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [buildName, setBuildName] = useState<string>('');
  const [editingEntry, setEditingEntry] = useState<ConstraintEntry | null>(null);
  const [draftContraint, setDraftConstraint] = useState<RecursivePartial<ConstraintEntry>>({
    type: ConstraintType.STADIUM_BLOCK,
  });

  const percentageThresholdsForm = useForm({
    initialValues: {
      ESPN: 0,
      FOX: 0,
      Turner: 0,
      Roku: 0,
      Apple: 0,
      Attendance: 0,
      Travel: 0,
    },
  });

  const [isAppLoading, setIsAppLoading] = useAppStore((store) => [
    store.isAppLoading,
    store.setIsAppLoading,
  ]);

  const buildsList = useAppStore((store) => store.buildsList);
  const [constraints, setConstraints] = useState<ConstraintEntry[]>([]);
  const [baseBuildIds] = useState<string[]>([]);
  const [selectedBuildId, setSelectedBuildId] = useState('');
  const [selectedWarmStartId, setSelectedWarmStartId] = useState('');
  const [staticConstraints] = useState<StaticConstraintEntry[]>([]);

  useEffect(() => {
    setIsValid(isConstraintValid(draftContraint));
  }, [draftContraint]);
  const [editorOpened, editorModalHandlers] = useDisclosure(false);

  // useEffect(() => {
  //   const fetchHelper = async () => {
  //     const staticConstriants = await getStaticConstraintsLocal();
  //     setStaticConstraints(staticConstriants);
  //   };
  //   fetchHelper();
  // }, []);

  const handleImportButtonClick = async () => {
    setIsAppLoading(true);
    // const newContext = await getBuildContext(selectedBuildId);
    // setConstraints([...constraints, ...newContext.constraints]);

    // TODO(maciek): Import percentage thresholds on import during next season.
    // percentageThresholdsForm.setValues(newContext.percentageThresholds);

    // if (!baseBuildIds.includes(selectedBuildId)) {
    //   setBaseBuildIds([...baseBuildIds, selectedBuildId]);
    // }
    setIsAppLoading(false);
  };

  const handleAddConstraintClick = () => {
    setConstraints([draftContraint as ConstraintEntry, ...constraints]);
  };

  const handleUpdateConstraintClick = () => {
    const newConstraints = constraints.filter((d) => d !== editingEntry);
    setConstraints([draftContraint as ConstraintEntry, ...newConstraints]);
    setEditingEntry(null);
  };

  const columns = useMemo<MRT_ColumnDef<ConstraintEntry>[]>(
    () => [
      {
        accessorFn: (row) => `${ConstraintTypeTranslations[row.type]}`,
        id: 'type',
        header: 'Constraint Type',
      },
      {
        accessorFn: (row) => `${getStringifiedConstraint(row)}`,
        id: 'definition',
        header: 'Definition',
      },
    ],
    []
  );

  const staticConstraintsColumns = useMemo<MRT_ColumnDef<StaticConstraintEntry>[]>(
    () => [
      {
        accessorKey: 'summary',
        header: 'Summary',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ],
    []
  );

  const handleExportRows = (rows: MRT_Row<ConstraintEntry>[]) => {
    const rowData = rows.map((row) => {
      const dataArr = row.getAllCells().map((cell) => cell.getValue());
      return {
        'Constraint Type': dataArr[1],
        Definition: dataArr[2],
      };
    });

    const csvConfig = mkConfig({
      filename: 'Constraints draft',
      title: 'Constraints draft',
      fieldSeparator: ',',
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const openDeleteConfirmModal = (row: MRT_Row<ConstraintEntry>) => {
    modals.openConfirmModal({
      title: 'Are you sure you want to delete this constraint?',
      children: <Text>This cannot be undone.</Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        setConstraints(constraints.filter((d) => d !== row.original));
      },
    });
  };

  const constraintsTable = useMantineReactTable({
    data: constraints,
    columns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    initialState: { density: 'xs', pagination: { pageSize: 30, pageIndex: 0 } },
    paginationDisplayMode: 'pages',
    mantineTableProps: {
      striped: true,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          handleExportRows(table.getPrePaginationRowModel().rows);
        }}
        leftSection={
          <FontAwesomeIcon
            style={{ width: rem(12), height: rem(12) }}
            icon={faArrowDownToBracket}
          />
        }
        variant="default"
        size="xs"
      >
        Download CSV
      </Button>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex gap="sm">
        <Tooltip label="Edit">
          <ActionIcon
            variant="default"
            onClick={() => {
              setIsEditing(true);
              setEditingEntry(row.original);
              setDraftConstraint({ ...row.original });
              editorModalHandlers.open();
            }}
          >
            <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faPenToSquare} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon variant="default" onClick={() => openDeleteConfirmModal(row)}>
            <FontAwesomeIcon
              color="red"
              style={{ width: rem(12), height: rem(12) }}
              icon={faCircleMinus}
            />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  const staticConstraintsTable = useMantineReactTable({
    data: staticConstraints,
    columns: staticConstraintsColumns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    initialState: { density: 'xs', pagination: { pageSize: 30, pageIndex: 0 } },
    paginationDisplayMode: 'pages',
    mantineTableProps: {
      striped: true,
    },
  });

  return (
    <Container fluid mt="md" mb="xl">
      <LoadingOverlay
        visible={isAppLoading}
        zIndex={1000}
        overlayProps={{ radius: 'xl', blur: 2 }}
      />
      <Modal
        opened={editorOpened}
        size={1000}
        closeOnClickOutside={false}
        onClose={() => {
          setIsEditing(false);
          editorModalHandlers.close();
          setDraftConstraint({
            type: ConstraintType.STADIUM_BLOCK,
          });
        }}
        title={
          <Text fw={700} size="lg">
            {isEditing ? 'Edit' : 'Add'} constraint
          </Text>
        }
      >
        <ConstraintEditor value={draftContraint} onChange={setDraftConstraint} />
        <Flex>
          <Button
            size="sm"
            ml="auto"
            disabled={!isValid}
            onClick={() => {
              if (isEditing) {
                handleUpdateConstraintClick();
              } else {
                handleAddConstraintClick();
              }
              setDraftConstraint({
                type: ConstraintType.STADIUM_BLOCK,
              });
              editorModalHandlers.close();
            }}
          >
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </Flex>
      </Modal>
      <Stack>
        <Title order={2}>Schedule Builder</Title>
        <Divider />
        <Group align="end">
          <TextInput
            value={buildName}
            onChange={(e) => setBuildName(e.currentTarget.value)}
            style={{ width: '240px' }}
            size="sm"
            label="Build name"
            placeholder="Enter build name"
          />
          <Button
            variant="filled"
            color="green"
            size="sm"
            disabled
            onClick={() => {
              let modalTitle = 'Are you sure you want to submit?';
              let modalText = 'Your draft will be cleared after submission.';

              // Show a warning if attempting to submit a build with no constraints.
              if (constraints.length === 0) {
                modalTitle = 'WARNING: No constraints found';
                modalText =
                  'You are attempting to submit a build with no constraints. Did you forget to click "Import"?';
              }

              modals.openConfirmModal({
                title: modalTitle,
                children: <Text size="sm">{modalText}</Text>,
                labels: { confirm: 'Submit', cancel: 'Cancel' },
                onConfirm: async () => {
                  setIsAppLoading(true);
                  try {
                    // await postConstraints(
                    //   {
                    //     constraints,
                    //     percentageThresholds: percentageThresholdsForm.values,
                    //     baseBuildIds,
                    //     warm_start_id: selectedWarmStartId || null,
                    //   },
                    //   buildName
                    // );
                    modals.openConfirmModal({
                      title: 'Submission Received',
                      children: <Text size="sm">Check for an email in your inbox soon.</Text>,
                      labels: { confirm: 'Close', cancel: '' },
                      cancelProps: { display: 'none' },
                      onConfirm: () => {
                        modals.closeAll();
                      },
                      onClose: () => {
                        // setTimeout(() => {
                        //   // Wrap this in a setTimeout so that React doesn't complain about
                        //   // state being set during a render.
                        //   // https://github.com/facebook/react/issues/18178#issuecomment-595846312
                        //   useAppStore.getState().fetchBuildsList();
                        //   navigate({ to: '/jobmanager' });
                        // }, 0);
                      },
                    });
                  } catch (error: any) {
                    // Start with a generic error and title.
                    let errorTitle = 'Error';
                    let errorMsg =
                      'An error has occurred. If this happens again, please contact support.';

                    if (
                      error?.response?.status === 400 &&
                      error?.response?.data?.name?.[0] ===
                        'nfl scheduler build with this name already exists.'
                    ) {
                      // A build collision is detected.
                      errorTitle = 'Error: Build name exists';
                      errorMsg =
                        'A schedule build with this name already exists. Please choose a different name.';
                    }

                    // Open an error modal.
                    modals.openConfirmModal({
                      title: errorTitle,
                      children: <Text size="sm">{errorMsg}</Text>,
                      onConfirm: () => {
                        modals.closeAll();
                      },
                      labels: { confirm: 'Continue', cancel: '' },
                      cancelProps: { display: 'none' },
                    });
                  }
                  setIsAppLoading(false);
                },
              });
            }}
          >
            Submit
          </Button>
        </Group>
        <Group>
          <Select
            w={400}
            label="Warm start schedule (optional)"
            placeholder="Import builds to see options"
            description="Select a warm start schedule to speed up your build"
            searchable
            data={buildsList
              .filter((build) => baseBuildIds.includes(build.id))
              .flatMap((build) => build.results)
              .filter((result) => result.is_valid)
              .map((result) => ({
                label: result.name,
                value: result.id,
              }))}
            value={selectedWarmStartId}
            onChange={(_value, option) => setSelectedWarmStartId(option?.value || '')}
          />
        </Group>
        <Stack gap={6}>
          <Title order={4}>Minimum Percentage Change</Title>
          <Text size="xs">
            Set a minimum percentage change from last year, by individual networks.
          </Text>
          <Divider />
          <SimpleGrid cols={4} py="sm" spacing={0} verticalSpacing="sm" w={1000}>
            {Object.keys(percentageThresholdsForm.values).map((key) => (
              <NumberInput
                key={key}
                classNames={{
                  root: classes.percentageThresholdInputWrapper,
                }}
                clampBehavior="strict"
                size="xs"
                label={key}
                {...percentageThresholdsForm.getInputProps(key)}
                style={{ width: rem(160) }}
                min={-10}
                max={6}
                onBlur={(e) => {
                  // Set empty string to 0 on blur.
                  if (e.currentTarget.value === '') {
                    percentageThresholdsForm.setFieldValue(key, 0);
                  }
                }}
                suffix="%"
                radius="sm"
              />
            ))}
          </SimpleGrid>
        </Stack>
        <Stack gap={6}>
          <Title order={4}>Constraint Editor</Title>
          <Text size="xs">Edit dynamic constriants or view static constriants.</Text>
          <Divider />
        </Stack>
        <Tabs variant="unstyled" defaultValue="dynamic" classNames={classes}>
          <Tabs.List justify="center">
            <Tabs.Tab
              value="dynamic"
              leftSection={
                <FontAwesomeIcon style={{ width: rem(16), height: rem(16) }} icon={faBolt} />
              }
            >
              Dynamic
            </Tabs.Tab>
            <Tabs.Tab
              value="static"
              leftSection={
                <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faCircle} />
              }
            >
              Static
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="dynamic">
            <Stack justify="center">
              <Group align="end" justify="space-between">
                <Group align="end">
                  <Select
                    w={400}
                    label="Import from previous build"
                    searchable
                    placeholder="Select build"
                    data={buildsList.map((build) => ({
                      label: build.name,
                      value: build.id,
                    }))}
                    allowDeselect={false}
                    value={selectedBuildId}
                    onChange={(_value, option) => setSelectedBuildId(option.value)}
                  />
                  <Button
                    variant="filled"
                    onClick={handleImportButtonClick}
                    disabled={selectedBuildId === ''}
                  >
                    Import
                  </Button>
                </Group>
                <Button variant="default" size="sm" onClick={editorModalHandlers.open}>
                  Add Constraint
                </Button>
              </Group>
              <MantineReactTable table={constraintsTable} />
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value="static">
            <Box mt="sm">
              <MantineReactTable table={staticConstraintsTable} />
            </Box>
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </Container>
  );
}

export const Route = createFileRoute('/_authenticated/schedulebuilder')({
  component: ScheduleBuilder,
});
