import { MlbSchedule, MlbTeamType } from './scheduleConsts';

export const MLB_2025_AL_SCHEDULE: MlbSchedule = [
  {
    week: 1,
    date: '2025-03-27',
    home: 'HOU',
    away: 'NYM',
    time: '15:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'SEA',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'TEX',
    away: 'BOS',
    time: '15:05',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'CWS',
    away: 'LAA',
    time: '15:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'KC',
    away: 'CLE',
    time: '15:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'NYY',
    away: 'MIL',
    time: '13:05',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'TB',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'TOR',
    away: 'BAL',
    time: '15:07',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'SEA',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'TEX',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'HOU',
    away: 'NYM',
    time: '18:15',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'SEA',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'TEX',
    away: 'BOS',
    time: '18:05',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'CWS',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'KC',
    away: 'CLE',
    time: '15:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'NYY',
    away: 'MIL',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'TB',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'TOR',
    away: 'BAL',
    time: '15:07',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'HOU',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'SEA',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'TEX',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'CWS',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'KC',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'NYY',
    away: 'MIL',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'TB',
    away: 'COL',
    time: '13:40',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'TOR',
    away: 'BAL',
    time: '13:37',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'HOU',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'OAK',
    away: 'CHC',
    time: '19:05',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'SEA',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'CWS',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'BAL',
    away: 'BOS',
    time: '15:05',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'TB',
    away: 'PIT',
    time: '18:50',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'TOR',
    away: 'WSH',
    time: '19:07',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'HOU',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'OAK',
    away: 'CHC',
    time: '19:05',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'SEA',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'CWS',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'NYY',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'TB',
    away: 'PIT',
    time: '18:50',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'TOR',
    away: 'WSH',
    time: '19:07',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'HOU',
    away: 'SF',
    time: '15:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'OAK',
    away: 'CHC',
    time: '12:35',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'SEA',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'CWS',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'NYY',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'TB',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'TOR',
    away: 'WSH',
    time: '15:07',
  },
  {
    week: 2,
    date: '2025-04-03',
    home: 'MIN',
    away: 'HOU',
    time: '15:10',
  },
  {
    week: 2,
    date: '2025-04-03',
    home: 'BAL',
    away: 'BOS',
    time: '13:05',
  },
  {
    week: 2,
    date: '2025-04-03',
    home: 'NYY',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'LAA',
    away: 'CLE',
    time: '18:38',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'TEX',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'DET',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'KC',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'BOS',
    away: 'STL',
    time: '14:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'LAA',
    away: 'CLE',
    time: '18:38',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'TEX',
    away: 'TB',
    time: '18:05',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'DET',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'KC',
    away: 'BAL',
    time: '15:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'MIN',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'BOS',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'LAA',
    away: 'CLE',
    time: '13:07',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'TEX',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'DET',
    away: 'CWS',
    time: '13:40',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'KC',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'MIN',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'BOS',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'LAA',
    away: 'TB',
    time: '18:38',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'OAK',
    away: 'SD',
    time: '19:05',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'SEA',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'DET',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'KC',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'LAA',
    away: 'TB',
    time: '18:38',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'OAK',
    away: 'SD',
    time: '19:05',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'SEA',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'CLE',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'DET',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'KC',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'LAA',
    away: 'TB',
    time: '13:07',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'OAK',
    away: 'SD',
    time: '12:35',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'SEA',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'CLE',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'DET',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'KC',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-10',
    home: 'CLE',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-10',
    home: 'KC',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-10',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'HOU',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'OAK',
    away: 'NYM',
    time: '19:05',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'SEA',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'CLE',
    away: 'KC',
    time: '18:10',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'CWS',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'MIN',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'BAL',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'NYY',
    away: 'SF',
    time: '19:05',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'TB',
    away: 'ATL',
    time: '18:50',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'HOU',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'OAK',
    away: 'NYM',
    time: '13:05',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'SEA',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'CLE',
    away: 'KC',
    time: '18:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'CWS',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'MIN',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'BAL',
    away: 'TOR',
    time: '16:05',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'NYY',
    away: 'SF',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'TB',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'HOU',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'OAK',
    away: 'NYM',
    time: '13:05',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'SEA',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'CLE',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'CWS',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'MIN',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'BAL',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'NYY',
    away: 'SF',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'TB',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'MIN',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'NYY',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'TOR',
    away: 'ATL',
    time: '19:07',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'TEX',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'CWS',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'MIN',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'BAL',
    away: 'CLE',
    time: '18:35',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'NYY',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'TOR',
    away: 'ATL',
    time: '19:07',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'TEX',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'CWS',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'MIN',
    away: 'NYM',
    time: '12:10',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'BAL',
    away: 'CLE',
    time: '18:35',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'NYY',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'TB',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'TOR',
    away: 'ATL',
    time: '13:07',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'TEX',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'CWS',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'DET',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'BAL',
    away: 'CLE',
    time: '18:35',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'HOU',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'LAA',
    away: 'SF',
    time: '18:38',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'TEX',
    away: 'LAD',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'DET',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'BAL',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'BOS',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'TOR',
    away: 'SEA',
    time: '19:07',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'HOU',
    away: 'SD',
    time: '18:10',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'LAA',
    away: 'SF',
    time: '18:38',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'TEX',
    away: 'LAD',
    time: '15:05',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'DET',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'BAL',
    away: 'CIN',
    time: '16:05',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'BOS',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'TB',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'TOR',
    away: 'SEA',
    time: '15:07',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'HOU',
    away: 'SD',
    time: '15:10',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'LAA',
    away: 'SF',
    time: '13:07',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'TEX',
    away: 'LAD',
    time: '13:35',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'DET',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'BAL',
    away: 'CIN',
    time: '13:35',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'BOS',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'TB',
    away: 'NYY',
    time: '13:40',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'TOR',
    away: 'SEA',
    time: '13:37',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'HOU',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'CLE',
    away: 'NYY',
    time: '18:10',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'DET',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'BOS',
    away: 'CWS',
    time: '11:10',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'HOU',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'LAA',
    away: 'PIT',
    time: '18:38',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'OAK',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'CLE',
    away: 'NYY',
    time: '18:10',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'DET',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'KC',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'MIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'BOS',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'HOU',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'LAA',
    away: 'PIT',
    time: '18:38',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'OAK',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'CLE',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'DET',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'KC',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'MIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'BOS',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'LAA',
    away: 'PIT',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'OAK',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'KC',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'MIN',
    away: 'CWS',
    time: '12:10',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'BOS',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'OAK',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'SEA',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'CLE',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'DET',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'KC',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'MIN',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'NYY',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'OAK',
    away: 'CWS',
    time: '13:05',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'SEA',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'CLE',
    away: 'BOS',
    time: '18:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'DET',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'KC',
    away: 'HOU',
    time: '18:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'MIN',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'NYY',
    away: 'TOR',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'OAK',
    away: 'CWS',
    time: '13:05',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'SEA',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'CLE',
    away: 'BOS',
    time: '13:40',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'DET',
    away: 'BAL',
    time: '13:40',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'KC',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'MIN',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'NYY',
    away: 'TOR',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'HOU',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'CLE',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'HOU',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'SEA',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'CLE',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'CWS',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'TB',
    away: 'KC',
    time: '18:50',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'HOU',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'SEA',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'CLE',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'CWS',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'TB',
    away: 'KC',
    time: '18:50',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'LAA',
    away: 'DET',
    time: '18:38',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'TEX',
    away: 'OAK',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'CLE',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'CWS',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'TB',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'LAA',
    away: 'DET',
    time: '18:38',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'TEX',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'CWS',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'BAL',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'BOS',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'NYY',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'TOR',
    away: 'CLE',
    time: '19:07',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'LAA',
    away: 'DET',
    time: '18:38',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'TEX',
    away: 'SEA',
    time: '18:05',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'CWS',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'BAL',
    away: 'KC',
    time: '16:05',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'BOS',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'NYY',
    away: 'TB',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'TOR',
    away: 'CLE',
    time: '15:07',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'LAA',
    away: 'DET',
    time: '13:07',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'TEX',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'CWS',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'BAL',
    away: 'KC',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'BOS',
    away: 'MIN',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'NYY',
    away: 'TB',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'TOR',
    away: 'CLE',
    time: '13:37',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'OAK',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'KC',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'NYY',
    away: 'SD',
    time: '19:05',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'LAA',
    away: 'TOR',
    time: '18:38',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'OAK',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'KC',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'MIN',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'BOS',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'NYY',
    away: 'SD',
    time: '19:05',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'TB',
    away: 'PHI',
    time: '18:50',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'LAA',
    away: 'TOR',
    time: '18:38',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'OAK',
    away: 'SEA',
    time: '12:35',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'KC',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'MIN',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'BOS',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'NYY',
    away: 'SD',
    time: '19:05',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'TB',
    away: 'PHI',
    time: '18:50',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'LAA',
    away: 'TOR',
    time: '18:38',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'KC',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'MIN',
    away: 'BAL',
    time: '12:10',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'BOS',
    away: 'TEX',
    time: '13:35',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'TB',
    away: 'PHI',
    time: '18:50',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'HOU',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'LAA',
    away: 'BAL',
    time: '18:38',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'OAK',
    away: 'NYY',
    time: '19:05',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'SEA',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'CLE',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'CWS',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'DET',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'KC',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'MIN',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'TB',
    away: 'MIL',
    time: '18:50',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'HOU',
    away: 'CIN',
    time: '18:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'LAA',
    away: 'BAL',
    time: '18:38',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'OAK',
    away: 'NYY',
    time: '13:05',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'SEA',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'CLE',
    away: 'PHI',
    time: '18:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'CWS',
    away: 'MIA',
    time: '18:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'DET',
    away: 'TEX',
    time: '18:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'KC',
    away: 'BOS',
    time: '18:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'MIN',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'TB',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'HOU',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'LAA',
    away: 'BAL',
    time: '13:07',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'OAK',
    away: 'NYY',
    time: '13:05',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'SEA',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'CLE',
    away: 'PHI',
    time: '13:40',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'CWS',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'DET',
    away: 'TEX',
    time: '13:40',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'KC',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'MIN',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'TB',
    away: 'MIL',
    time: '13:40',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'HOU',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'SEA',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'TEX',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'CLE',
    away: 'MIL',
    time: '18:10',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'DET',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'HOU',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'SEA',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'TEX',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'CLE',
    away: 'MIL',
    time: '18:10',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'DET',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'BAL',
    away: 'MIN',
    time: '18:35',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'TOR',
    away: 'TB',
    time: '19:07',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'HOU',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'SEA',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'TEX',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'CLE',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'DET',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'BAL',
    away: 'MIN',
    time: '18:35',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'TOR',
    away: 'TB',
    time: '19:07',
  },
  {
    week: 8,
    date: '2025-05-15',
    home: 'TEX',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-15',
    home: 'BAL',
    away: 'MIN',
    time: '12:35',
  },
  {
    week: 8,
    date: '2025-05-15',
    home: 'TOR',
    away: 'TB',
    time: '15:07',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'TEX',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'KC',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'BAL',
    away: 'WSH',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'BOS',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'NYY',
    away: 'NYM',
    time: '19:05',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'TOR',
    away: 'DET',
    time: '19:07',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'TEX',
    away: 'HOU',
    time: '18:05',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'KC',
    away: 'STL',
    time: '18:10',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'BAL',
    away: 'WSH',
    time: '16:05',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'BOS',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'NYY',
    away: 'NYM',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'TOR',
    away: 'DET',
    time: '15:07',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'TEX',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'KC',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'BAL',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'BOS',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'NYY',
    away: 'NYM',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'TOR',
    away: 'DET',
    time: '13:37',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'OAK',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'CWS',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'MIN',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'BOS',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'TB',
    away: 'HOU',
    time: '18:50',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'OAK',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'CWS',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'MIN',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'BOS',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'NYY',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'TB',
    away: 'HOU',
    time: '18:50',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'TOR',
    away: 'SD',
    time: '19:07',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'OAK',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'CWS',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'MIN',
    away: 'CLE',
    time: '12:10',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'BOS',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'NYY',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'TB',
    away: 'HOU',
    time: '18:50',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'TOR',
    away: 'SD',
    time: '19:07',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'HOU',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'OAK',
    away: 'LAA',
    time: '12:35',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'NYY',
    away: 'TEX',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'TOR',
    away: 'SD',
    time: '13:07',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'HOU',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'LAA',
    away: 'MIA',
    time: '18:38',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'OAK',
    away: 'PHI',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'CWS',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'DET',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'MIN',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'TB',
    away: 'TOR',
    time: '18:50',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'HOU',
    away: 'SEA',
    time: '15:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'LAA',
    away: 'MIA',
    time: '19:07',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'OAK',
    away: 'PHI',
    time: '19:05',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'CWS',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'DET',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'MIN',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'BOS',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'TB',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'HOU',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'LAA',
    away: 'MIA',
    time: '13:07',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'OAK',
    away: 'PHI',
    time: '13:05',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'CWS',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'DET',
    away: 'CLE',
    time: '13:40',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'MIN',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'BOS',
    away: 'BAL',
    time: '13:35',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'TB',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'LAA',
    away: 'NYY',
    time: '18:38',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'TEX',
    away: 'TOR',
    time: '15:05',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'CLE',
    away: 'LAD',
    time: '18:10',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'DET',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'KC',
    away: 'CIN',
    time: '15:10',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'MIN',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'BAL',
    away: 'STL',
    time: '13:05',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'HOU',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'LAA',
    away: 'NYY',
    time: '18:38',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'SEA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'TEX',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'CLE',
    away: 'LAD',
    time: '18:10',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'DET',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'KC',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'MIN',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'BAL',
    away: 'STL',
    time: '18:35',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'HOU',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'LAA',
    away: 'NYY',
    time: '18:38',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'SEA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'TEX',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'CLE',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'DET',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'KC',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'MIN',
    away: 'TB',
    time: '12:10',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'BAL',
    away: 'STL',
    time: '18:35',
  },
  {
    week: 10,
    date: '2025-05-29',
    home: 'HOU',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-29',
    home: 'SEA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-29',
    home: 'TOR',
    away: 'OAK',
    time: '19:07',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'HOU',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'SEA',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'TEX',
    away: 'STL',
    time: '19:05',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'CLE',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'KC',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'BAL',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'TOR',
    away: 'OAK',
    time: '19:07',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'HOU',
    away: 'TB',
    time: '15:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'SEA',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'TEX',
    away: 'STL',
    time: '15:05',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'CLE',
    away: 'LAA',
    time: '16:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'KC',
    away: 'DET',
    time: '15:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'BAL',
    away: 'CWS',
    time: '16:05',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'TOR',
    away: 'OAK',
    time: '15:07',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'HOU',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'SEA',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'TEX',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'CLE',
    away: 'LAA',
    time: '13:40',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'KC',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'BAL',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'TOR',
    away: 'OAK',
    time: '13:37',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'OAK',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'CWS',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'BOS',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'OAK',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'SEA',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'CWS',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'BOS',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'NYY',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'TB',
    away: 'TEX',
    time: '18:50',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'TOR',
    away: 'PHI',
    time: '19:07',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'OAK',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'SEA',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'CWS',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'BOS',
    away: 'LAA',
    time: '13:35',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'NYY',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'TB',
    away: 'TEX',
    time: '18:50',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'TOR',
    away: 'PHI',
    time: '19:07',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'OAK',
    away: 'MIN',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'SEA',
    away: 'BAL',
    time: '12:40',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'CWS',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'NYY',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'TB',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'TOR',
    away: 'PHI',
    time: '15:07',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'LAA',
    away: 'SEA',
    time: '18:38',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'OAK',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'CLE',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'CWS',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'DET',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'MIN',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'NYY',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'TB',
    away: 'MIA',
    time: '18:50',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'LAA',
    away: 'SEA',
    time: '19:07',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'OAK',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'CLE',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'CWS',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'DET',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'MIN',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'NYY',
    away: 'BOS',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'TB',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'LAA',
    away: 'SEA',
    time: '13:07',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'OAK',
    away: 'BAL',
    time: '13:05',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'CLE',
    away: 'HOU',
    time: '13:40',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'CWS',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'DET',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'MIN',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'NYY',
    away: 'BOS',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'TB',
    away: 'MIA',
    time: '13:40',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'LAA',
    away: 'OAK',
    time: '18:38',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'CLE',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'HOU',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'LAA',
    away: 'OAK',
    time: '18:38',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'CLE',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'KC',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'MIN',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'BAL',
    away: 'DET',
    time: '18:35',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'HOU',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'LAA',
    away: 'OAK',
    time: '13:07',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'CLE',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'KC',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'MIN',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'BAL',
    away: 'DET',
    time: '18:35',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'HOU',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'KC',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'MIN',
    away: 'TEX',
    time: '12:10',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'BAL',
    away: 'DET',
    time: '13:05',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'HOU',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'SEA',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'TEX',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'DET',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'KC',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'BAL',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'BOS',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'HOU',
    away: 'MIN',
    time: '15:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'SEA',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'TEX',
    away: 'CWS',
    time: '15:05',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'DET',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'KC',
    away: 'OAK',
    time: '15:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'BAL',
    away: 'LAA',
    time: '16:05',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'BOS',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'HOU',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'SEA',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'TEX',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'DET',
    away: 'CIN',
    time: '13:40',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'KC',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'BAL',
    away: 'LAA',
    time: '13:35',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'BOS',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'SEA',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'NYY',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'SEA',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'TEX',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'CWS',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'DET',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'NYY',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'TOR',
    away: 'AZ',
    time: '19:07',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'SEA',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'TEX',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'CWS',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'DET',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'NYY',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'TOR',
    away: 'AZ',
    time: '19:07',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'TEX',
    away: 'KC',
    time: '13:35',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'CWS',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'DET',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'NYY',
    away: 'LAA',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'TB',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'TOR',
    away: 'AZ',
    time: '15:07',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'LAA',
    away: 'HOU',
    time: '18:38',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'OAK',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'MIN',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'TB',
    away: 'DET',
    time: '18:50',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'TOR',
    away: 'CWS',
    time: '19:07',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'LAA',
    away: 'HOU',
    time: '19:07',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'OAK',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'MIN',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'NYY',
    away: 'BAL',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'TB',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'TOR',
    away: 'CWS',
    time: '15:07',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'LAA',
    away: 'HOU',
    time: '13:07',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'OAK',
    away: 'CLE',
    time: '13:05',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'MIN',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'NYY',
    away: 'BAL',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'TB',
    away: 'DET',
    time: '13:40',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'TOR',
    away: 'CWS',
    time: '13:37',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'LAA',
    away: 'BOS',
    time: '18:38',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'CWS',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'MIN',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'BAL',
    away: 'TEX',
    time: '18:35',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'HOU',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'LAA',
    away: 'BOS',
    time: '18:38',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'CLE',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'CWS',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'DET',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'KC',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'MIN',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'BAL',
    away: 'TEX',
    time: '18:35',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'HOU',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'LAA',
    away: 'BOS',
    time: '13:07',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'CLE',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'CWS',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'DET',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'KC',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'MIN',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'BAL',
    away: 'TEX',
    time: '18:35',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'HOU',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'CLE',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'DET',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'KC',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'MIN',
    away: 'SEA',
    time: '12:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'HOU',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'LAA',
    away: 'WSH',
    time: '18:38',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'TEX',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'CLE',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'CWS',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'DET',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'KC',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'BAL',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'NYY',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'HOU',
    away: 'CHC',
    time: '15:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'LAA',
    away: 'WSH',
    time: '19:07',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'TEX',
    away: 'SEA',
    time: '15:05',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'CLE',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'CWS',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'DET',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'KC',
    away: 'LAD',
    time: '15:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'BAL',
    away: 'TB',
    time: '16:05',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'BOS',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'NYY',
    away: 'OAK',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'HOU',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'LAA',
    away: 'WSH',
    time: '13:07',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'TEX',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'CLE',
    away: 'STL',
    time: '13:40',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'CWS',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'DET',
    away: 'MIN',
    time: '13:40',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'KC',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'BAL',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'BOS',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'NYY',
    away: 'OAK',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'SEA',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'TEX',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'BOS',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'TB',
    away: 'OAK',
    time: '18:50',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'SEA',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'TEX',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'BOS',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'TB',
    away: 'OAK',
    time: '18:50',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'TOR',
    away: 'NYY',
    time: '15:07',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'SEA',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'TEX',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'BOS',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'TB',
    away: 'OAK',
    time: '12:10',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'SEA',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'OAK',
    away: 'SF',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'SEA',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'CLE',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'TB',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'TOR',
    away: 'LAA',
    time: '19:07',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'OAK',
    away: 'SF',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'SEA',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'CLE',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'TB',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'TOR',
    away: 'LAA',
    time: '15:07',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'OAK',
    away: 'SF',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'SEA',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'CLE',
    away: 'DET',
    time: '13:40',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'TB',
    away: 'MIN',
    time: '13:40',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'TOR',
    away: 'LAA',
    time: '13:37',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'HOU',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'CWS',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'DET',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'KC',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'BOS',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'HOU',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'OAK',
    away: 'ATL',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'CWS',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'DET',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'KC',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'MIN',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'BAL',
    away: 'NYM',
    time: '18:35',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'BOS',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'NYY',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'HOU',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'OAK',
    away: 'ATL',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'CWS',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'DET',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'KC',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'MIN',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'BAL',
    away: 'NYM',
    time: '18:35',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'BOS',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'NYY',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'OAK',
    away: 'ATL',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'CWS',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'MIN',
    away: 'CHC',
    time: '12:10',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'BAL',
    away: 'NYM',
    time: '13:05',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'NYY',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'HOU',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'LAA',
    away: 'AZ',
    time: '18:38',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'OAK',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'CWS',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'DET',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'KC',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'MIN',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'BAL',
    away: 'MIA',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'NYY',
    away: 'CHC',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'HOU',
    away: 'TEX',
    time: '15:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'LAA',
    away: 'AZ',
    time: '19:07',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'OAK',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'CWS',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'DET',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'KC',
    away: 'NYM',
    time: '15:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'MIN',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'BAL',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'BOS',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'NYY',
    away: 'CHC',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'HOU',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'LAA',
    away: 'AZ',
    time: '13:07',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'OAK',
    away: 'TOR',
    time: '13:05',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'CWS',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'DET',
    away: 'SEA',
    time: '13:40',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'KC',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'MIN',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'BAL',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'BOS',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'NYY',
    away: 'CHC',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'SEA',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'TEX',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'CLE',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'TOR',
    away: 'SF',
    time: '19:07',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'SEA',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'TEX',
    away: 'DET',
    time: '18:05',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'CLE',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'TB',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'TOR',
    away: 'SF',
    time: '15:07',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'SEA',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'TEX',
    away: 'DET',
    time: '13:35',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'CLE',
    away: 'OAK',
    time: '13:40',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'TB',
    away: 'BAL',
    time: '13:40',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'TOR',
    away: 'SF',
    time: '13:37',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'SEA',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'CLE',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'TB',
    away: 'CWS',
    time: '18:50',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'SEA',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'CLE',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'TB',
    away: 'CWS',
    time: '18:50',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'SEA',
    away: 'MIL',
    time: '12:40',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'CLE',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'TB',
    away: 'CWS',
    time: '12:10',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 18,
    date: '2025-07-24',
    home: 'HOU',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-24',
    home: 'LAA',
    away: 'SEA',
    time: '18:38',
  },
  {
    week: 18,
    date: '2025-07-24',
    home: 'CLE',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-24',
    home: 'DET',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'HOU',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'LAA',
    away: 'SEA',
    time: '18:38',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'TEX',
    away: 'ATL',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'CWS',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'DET',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'KC',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'MIN',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'BAL',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'BOS',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'NYY',
    away: 'PHI',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'HOU',
    away: 'OAK',
    time: '18:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'LAA',
    away: 'SEA',
    time: '18:38',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'TEX',
    away: 'ATL',
    time: '18:05',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'CWS',
    away: 'CHC',
    time: '18:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'DET',
    away: 'TOR',
    time: '18:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'KC',
    away: 'CLE',
    time: '18:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'MIN',
    away: 'WSH',
    time: '18:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'BAL',
    away: 'COL',
    time: '16:05',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'BOS',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'NYY',
    away: 'PHI',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'HOU',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'LAA',
    away: 'SEA',
    time: '13:07',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'TEX',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'CWS',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'DET',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'KC',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'MIN',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'BAL',
    away: 'COL',
    time: '13:35',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'BOS',
    away: 'LAD',
    time: '13:35',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'NYY',
    away: 'PHI',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'HOU',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'OAK',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'CLE',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'CWS',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'DET',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'KC',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'MIN',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'BAL',
    away: 'TOR',
    time: '18:35',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'NYY',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'HOU',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'OAK',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'CLE',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'CWS',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'DET',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'KC',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'MIN',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'BAL',
    away: 'TOR',
    time: '18:35',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'NYY',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'HOU',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'LAA',
    away: 'TEX',
    time: '18:38',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'OAK',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'CLE',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'CWS',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'DET',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'KC',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'MIN',
    away: 'BOS',
    time: '12:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'BAL',
    away: 'TOR',
    time: '12:35',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'NYY',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-07-31',
    home: 'SEA',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-31',
    home: 'NYY',
    away: 'TB',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'LAA',
    away: 'CWS',
    time: '18:38',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'OAK',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'SEA',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'CLE',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'BOS',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'TB',
    away: 'LAD',
    time: '18:50',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'TOR',
    away: 'KC',
    time: '19:07',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'LAA',
    away: 'CWS',
    time: '19:07',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'OAK',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'SEA',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'CLE',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'BOS',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'TB',
    away: 'LAD',
    time: '16:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'TOR',
    away: 'KC',
    time: '15:07',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'LAA',
    away: 'CWS',
    time: '13:07',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'OAK',
    away: 'AZ',
    time: '13:05',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'SEA',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'CLE',
    away: 'MIN',
    time: '13:40',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'BOS',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'TB',
    away: 'LAD',
    time: '13:40',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'TOR',
    away: 'KC',
    time: '13:37',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'TEX',
    away: 'NYY',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'DET',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'BOS',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'SEA',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'TEX',
    away: 'NYY',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'DET',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'BOS',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'TB',
    away: 'LAA',
    time: '18:50',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'SEA',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'TEX',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'DET',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'BOS',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'TB',
    away: 'LAA',
    time: '18:50',
  },
  {
    week: 20,
    date: '2025-08-07',
    home: 'SEA',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-07',
    home: 'TB',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'SEA',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'TEX',
    away: 'PHI',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'CWS',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'DET',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'MIN',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'BAL',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'NYY',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'SEA',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'TEX',
    away: 'PHI',
    time: '18:05',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'CWS',
    away: 'CLE',
    time: '18:10',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'DET',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'MIN',
    away: 'KC',
    time: '18:10',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'BAL',
    away: 'OAK',
    time: '16:05',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'NYY',
    away: 'HOU',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'SEA',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'TEX',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'CWS',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'DET',
    away: 'LAA',
    time: '13:40',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'MIN',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'BAL',
    away: 'OAK',
    time: '13:35',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'NYY',
    away: 'HOU',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'HOU',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'LAA',
    away: 'LAD',
    time: '18:38',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'OAK',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'TEX',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'CWS',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'KC',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'NYY',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'HOU',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'LAA',
    away: 'LAD',
    time: '18:38',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'OAK',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'TEX',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'CLE',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'CWS',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'KC',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'BAL',
    away: 'SEA',
    time: '18:35',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'NYY',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'TOR',
    away: 'CHC',
    time: '19:07',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'HOU',
    away: 'BOS',
    time: '18:10',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'LAA',
    away: 'LAD',
    time: '18:38',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'OAK',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'TEX',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'CLE',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'CWS',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'KC',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'BAL',
    away: 'SEA',
    time: '18:35',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'NYY',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'TOR',
    away: 'CHC',
    time: '19:07',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'CLE',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'MIN',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'BAL',
    away: 'SEA',
    time: '18:35',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'TOR',
    away: 'CHC',
    time: '15:07',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'HOU',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'OAK',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'CLE',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'KC',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'MIN',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'BOS',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'TOR',
    away: 'TEX',
    time: '19:07',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'HOU',
    away: 'BAL',
    time: '18:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'OAK',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'CLE',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'KC',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'MIN',
    away: 'DET',
    time: '18:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'BOS',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'TOR',
    away: 'TEX',
    time: '15:07',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'HOU',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'OAK',
    away: 'LAA',
    time: '13:05',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'CLE',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'KC',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'MIN',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'BOS',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'TOR',
    away: 'TEX',
    time: '13:37',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'LAA',
    away: 'CIN',
    time: '18:38',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'DET',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'KC',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'LAA',
    away: 'CIN',
    time: '18:38',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'DET',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'KC',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'MIN',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'LAA',
    away: 'CIN',
    time: '18:38',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'DET',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'KC',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'MIN',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'KC',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'MIN',
    away: 'OAK',
    time: '12:10',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'BAL',
    away: 'HOU',
    time: '18:35',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'NYY',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'LAA',
    away: 'CHC',
    time: '18:38',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'SEA',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'TEX',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'CWS',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'DET',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'BAL',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'NYY',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'TB',
    away: 'STL',
    time: '18:50',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'LAA',
    away: 'CHC',
    time: '18:38',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'SEA',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'TEX',
    away: 'CLE',
    time: '18:05',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'CWS',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'DET',
    away: 'KC',
    time: '18:10',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'BAL',
    away: 'HOU',
    time: '16:05',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'NYY',
    away: 'BOS',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'TB',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'LAA',
    away: 'CHC',
    time: '13:07',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'SEA',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'TEX',
    away: 'CLE',
    time: '13:35',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'CWS',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'DET',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'BAL',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'NYY',
    away: 'BOS',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'TB',
    away: 'STL',
    time: '13:40',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'OAK',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'SEA',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'TEX',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'CLE',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'CWS',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'NYY',
    away: 'WSH',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'TOR',
    away: 'MIN',
    time: '19:07',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'HOU',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'OAK',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'SEA',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'TEX',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'CLE',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'CWS',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'NYY',
    away: 'WSH',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'TOR',
    away: 'MIN',
    time: '19:07',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'HOU',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'OAK',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'SEA',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'TEX',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'CLE',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'CWS',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'NYY',
    away: 'WSH',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'TOR',
    away: 'MIN',
    time: '19:07',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'HOU',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'CWS',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'BAL',
    away: 'BOS',
    time: '13:05',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'HOU',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'OAK',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'CLE',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'CWS',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'KC',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'MIN',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'BOS',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'TOR',
    away: 'MIL',
    time: '19:07',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'HOU',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'OAK',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'CLE',
    away: 'SEA',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'CWS',
    away: 'NYY',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'KC',
    away: 'DET',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'MIN',
    away: 'SD',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'BOS',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'TOR',
    away: 'MIL',
    time: '15:07',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'HOU',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'OAK',
    away: 'TEX',
    time: '13:05',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'CLE',
    away: 'SEA',
    time: '13:40',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'CWS',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'KC',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'MIN',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'BOS',
    away: 'PIT',
    time: '13:35',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'TOR',
    away: 'MIL',
    time: '13:37',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'HOU',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'DET',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'MIN',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'BOS',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'TB',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'HOU',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'DET',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'KC',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'MIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'BOS',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'TB',
    away: 'SEA',
    time: '18:50',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'HOU',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'DET',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'KC',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'MIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'BOS',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'TB',
    away: 'SEA',
    time: '18:50',
  },
  {
    week: 24,
    date: '2025-09-04',
    home: 'HOU',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-04',
    home: 'KC',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-04',
    home: 'MIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-04',
    home: 'TB',
    away: 'CLE',
    time: '18:50',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'LAA',
    away: 'OAK',
    time: '18:38',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'TEX',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'DET',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'KC',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'BAL',
    away: 'LAD',
    time: '19:05',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'NYY',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'TB',
    away: 'CLE',
    time: '18:50',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'LAA',
    away: 'OAK',
    time: '18:38',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'TEX',
    away: 'HOU',
    time: '18:05',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'DET',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'KC',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'BAL',
    away: 'LAD',
    time: '16:05',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'NYY',
    away: 'TOR',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'TB',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'LAA',
    away: 'OAK',
    time: '13:07',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'TEX',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'DET',
    away: 'CWS',
    time: '13:40',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'KC',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'BAL',
    away: 'LAD',
    time: '13:35',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'NYY',
    away: 'TOR',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'TB',
    away: 'CLE',
    time: '13:40',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'LAA',
    away: 'MIN',
    time: '18:38',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'OAK',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'SEA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'TEX',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'LAA',
    away: 'MIN',
    time: '18:38',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'OAK',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'SEA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'TEX',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'CWS',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'BAL',
    away: 'PIT',
    time: '18:35',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'NYY',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'TOR',
    away: 'HOU',
    time: '19:07',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'LAA',
    away: 'MIN',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'OAK',
    away: 'BOS',
    time: '12:35',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'SEA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'TEX',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'CWS',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'BAL',
    away: 'PIT',
    time: '18:35',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'NYY',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'TOR',
    away: 'HOU',
    time: '19:07',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'SEA',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'CWS',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'BAL',
    away: 'PIT',
    time: '13:05',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'NYY',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'TOR',
    away: 'HOU',
    time: '19:07',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'OAK',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'SEA',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'CLE',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'MIN',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'BOS',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'OAK',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'SEA',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'CLE',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'MIN',
    away: 'AZ',
    time: '18:10',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'BOS',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'TOR',
    away: 'BAL',
    time: '15:07',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'OAK',
    away: 'CIN',
    time: '13:05',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'SEA',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'CLE',
    away: 'CWS',
    time: '13:40',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'MIN',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'BOS',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'TOR',
    away: 'BAL',
    time: '13:37',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'HOU',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'CWS',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'MIN',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'TB',
    away: 'TOR',
    time: '18:50',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'HOU',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'CWS',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'KC',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'MIN',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'BOS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'TB',
    away: 'TOR',
    time: '18:50',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'HOU',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'CWS',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'KC',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'MIN',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'BOS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'TB',
    away: 'TOR',
    time: '18:50',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'DET',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'KC',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'BOS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'TB',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'HOU',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'TEX',
    away: 'MIA',
    time: '19:05',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'CWS',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'DET',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'KC',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'MIN',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'BAL',
    away: 'NYY',
    time: '19:05',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'HOU',
    away: 'SEA',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'TEX',
    away: 'MIA',
    time: '18:05',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'CWS',
    away: 'SD',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'DET',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'KC',
    away: 'TOR',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'MIN',
    away: 'CLE',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'BAL',
    away: 'NYY',
    time: '16:05',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'TB',
    away: 'BOS',
    time: '16:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'HOU',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'TEX',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'CWS',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'DET',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'KC',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'MIN',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'BAL',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'TB',
    away: 'BOS',
    time: '13:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'LAA',
    away: 'KC',
    time: '18:38',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'SEA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'TEX',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'CLE',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'BAL',
    away: 'TB',
    time: '18:35',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'NYY',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'LAA',
    away: 'KC',
    time: '18:38',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'SEA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'TEX',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'CLE',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'BAL',
    away: 'TB',
    time: '18:35',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'NYY',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'LAA',
    away: 'KC',
    time: '18:38',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'OAK',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'SEA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'TEX',
    away: 'MIN',
    time: '13:35',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'CLE',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'BAL',
    away: 'TB',
    time: '13:05',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'NYY',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'LAA',
    away: 'HOU',
    time: '18:38',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'OAK',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'SEA',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'CLE',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'BOS',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'TOR',
    away: 'TB',
    time: '19:07',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'LAA',
    away: 'HOU',
    time: '18:38',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'OAK',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'SEA',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'CLE',
    away: 'TEX',
    time: '18:10',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'BOS',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'NYY',
    away: 'BAL',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'TOR',
    away: 'TB',
    time: '15:07',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'LAA',
    away: 'HOU',
    time: '12:07',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'OAK',
    away: 'KC',
    time: '12:05',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'SEA',
    away: 'LAD',
    time: '12:10',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'CLE',
    away: 'TEX',
    time: '15:10',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'BOS',
    away: 'DET',
    time: '15:05',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'NYY',
    away: 'BAL',
    time: '15:05',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'TOR',
    away: 'TB',
    time: '15:07',
  },
];

export const MLB_2025_NL_SCHEDULE: MlbSchedule = [
  {
    week: 0,
    date: '2025-03-18',
    home: 'CHC',
    away: 'LAD',
  },
  {
    week: 0,
    date: '2025-03-19',
    home: 'CHC',
    away: 'LAD',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'AZ',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'LAD',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'SD',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'CIN',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'STL',
    away: 'MIN',
    time: '15:15',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'MIA',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 1,
    date: '2025-03-27',
    home: 'WSH',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'AZ',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'LAD',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'SD',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 1,
    date: '2025-03-28',
    home: 'MIA',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'AZ',
    away: 'CHC',
    time: '17:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'LAD',
    away: 'DET',
    time: '18:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'SD',
    away: 'ATL',
    time: '16:15',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'CIN',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'STL',
    away: 'MIN',
    time: '13:15',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'MIA',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 1,
    date: '2025-03-29',
    home: 'WSH',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'AZ',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'SD',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'CIN',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'STL',
    away: 'MIN',
    time: '13:15',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'MIA',
    away: 'PIT',
    time: '13:40',
  },
  {
    week: 1,
    date: '2025-03-30',
    home: 'WSH',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'LAD',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'SD',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'CIN',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'MIL',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'STL',
    away: 'LAA',
    time: '18:45',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'MIA',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-03-31',
    home: 'PHI',
    away: 'COL',
    time: '15:05',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'LAD',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'SD',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'CIN',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'MIL',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'STL',
    away: 'LAA',
    time: '18:45',
  },
  {
    week: 2,
    date: '2025-04-01',
    home: 'MIA',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'LAD',
    away: 'ATL',
    time: '17:38',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'SD',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'CIN',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'MIL',
    away: 'KC',
    time: '12:10',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'STL',
    away: 'LAA',
    time: '12:15',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'MIA',
    away: 'NYM',
    time: '16:40',
  },
  {
    week: 2,
    date: '2025-04-02',
    home: 'PHI',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 2,
    date: '2025-04-03',
    home: 'MIL',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 2,
    date: '2025-04-03',
    home: 'PHI',
    away: 'COL',
    time: '13:05',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'COL',
    away: 'OAK',
    time: '14:10',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'SF',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'CHC',
    away: 'SD',
    time: '13:20',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'MIL',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'PIT',
    away: 'NYY',
    time: '16:12',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'ATL',
    away: 'MIA',
    time: '19:15',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'NYM',
    away: 'TOR',
    time: '15:10',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'PHI',
    away: 'LAD',
    time: '18:45',
  },
  {
    week: 2,
    date: '2025-04-04',
    home: 'WSH',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'COL',
    away: 'OAK',
    time: '18:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'SF',
    away: 'SEA',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'CHC',
    away: 'SD',
    time: '13:20',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'MIL',
    away: 'CIN',
    time: '18:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'PIT',
    away: 'NYY',
    time: '16:05',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'ATL',
    away: 'MIA',
    time: '19:15',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'NYM',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'PHI',
    away: 'LAD',
    time: '16:05',
  },
  {
    week: 2,
    date: '2025-04-05',
    home: 'WSH',
    away: 'AZ',
    time: '16:05',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'COL',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'SF',
    away: 'SEA',
    time: '13:05',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'CHC',
    away: 'SD',
    time: '13:20',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'MIL',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'PIT',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'ATL',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'NYM',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'PHI',
    away: 'LAD',
    time: '13:35',
  },
  {
    week: 2,
    date: '2025-04-06',
    home: 'WSH',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'AZ',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'SF',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'CHC',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-07',
    home: 'WSH',
    away: 'LAD',
    time: '18:45',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'AZ',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'COL',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'SF',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'CHC',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'ATL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-08',
    home: 'WSH',
    away: 'LAD',
    time: '18:45',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'AZ',
    away: 'BAL',
    time: '12:40',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'COL',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'SF',
    away: 'CIN',
    time: '12:45',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'CHC',
    away: 'TEX',
    time: '13:20',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'PIT',
    away: 'STL',
    time: '12:35',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'ATL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'NYM',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-09',
    home: 'WSH',
    away: 'LAD',
    time: '16:05',
  },
  {
    week: 3,
    date: '2025-04-10',
    home: 'COL',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-10',
    home: 'ATL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'AZ',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'LAD',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'SD',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'CIN',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'STL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 3,
    date: '2025-04-11',
    home: 'MIA',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'AZ',
    away: 'MIL',
    time: '17:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'LAD',
    away: 'CHC',
    time: '18:10',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'SD',
    away: 'COL',
    time: '17:40',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'CIN',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'STL',
    away: 'PHI',
    time: '13:15',
  },
  {
    week: 3,
    date: '2025-04-12',
    home: 'MIA',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'AZ',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'LAD',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'SD',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'CIN',
    away: 'PIT',
    time: '13:40',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'STL',
    away: 'PHI',
    time: '13:15',
  },
  {
    week: 3,
    date: '2025-04-13',
    home: 'MIA',
    away: 'WSH',
    time: '13:40',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'LAD',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'SD',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'MIL',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'PIT',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'STL',
    away: 'HOU',
    time: '18:45',
  },
  {
    week: 4,
    date: '2025-04-14',
    home: 'PHI',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'LAD',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'SD',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'CIN',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'MIL',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'PIT',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'STL',
    away: 'HOU',
    time: '18:45',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'MIA',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-15',
    home: 'PHI',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'LAD',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'SD',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'CIN',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'MIL',
    away: 'DET',
    time: '12:10',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'PIT',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'STL',
    away: 'HOU',
    time: '12:15',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'MIA',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-16',
    home: 'PHI',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'CIN',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'PIT',
    away: 'WSH',
    time: '12:35',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'MIA',
    away: 'AZ',
    time: '12:10',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'NYM',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-17',
    home: 'PHI',
    away: 'SF',
    time: '16:05',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'COL',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'CHC',
    away: 'AZ',
    time: '13:20',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'MIL',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'PIT',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'ATL',
    away: 'MIN',
    time: '19:15',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'NYM',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 4,
    date: '2025-04-18',
    home: 'PHI',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'COL',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'CHC',
    away: 'AZ',
    time: '13:20',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'MIL',
    away: 'OAK',
    time: '18:10',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'PIT',
    away: 'CLE',
    time: '16:05',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'ATL',
    away: 'MIN',
    time: '19:15',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'NYM',
    away: 'STL',
    time: '16:05',
  },
  {
    week: 4,
    date: '2025-04-19',
    home: 'PHI',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'COL',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'CHC',
    away: 'AZ',
    time: '13:20',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'MIL',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'PIT',
    away: 'CLE',
    time: '13:35',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'ATL',
    away: 'MIN',
    time: '13:35',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'NYM',
    away: 'STL',
    time: '13:40',
  },
  {
    week: 4,
    date: '2025-04-20',
    home: 'PHI',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'SF',
    away: 'MIL',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'ATL',
    away: 'STL',
    time: '19:15',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'MIA',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-21',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'AZ',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'SF',
    away: 'MIL',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'CHC',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'ATL',
    away: 'STL',
    time: '19:15',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'MIA',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-22',
    home: 'WSH',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'AZ',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'SF',
    away: 'MIL',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'CHC',
    away: 'LAD',
    time: '18:00',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'ATL',
    away: 'STL',
    time: '12:15',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'MIA',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'NYM',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-23',
    home: 'WSH',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'AZ',
    away: 'TB',
    time: '06:40',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'SF',
    away: 'MIL',
    time: '12:45',
  },
  {
    week: 5,
    date: '2025-04-24',
    home: 'WSH',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'AZ',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'COL',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'LAD',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'SD',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'SF',
    away: 'TEX',
    time: '19:15',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'CHC',
    away: 'PHI',
    time: '13:20',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'STL',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 5,
    date: '2025-04-25',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'AZ',
    away: 'ATL',
    time: '17:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'COL',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'LAD',
    away: 'PIT',
    time: '18:10',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'SD',
    away: 'TB',
    time: '17:40',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'SF',
    away: 'TEX',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'CHC',
    away: 'PHI',
    time: '13:20',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'STL',
    away: 'MIL',
    time: '13:15',
  },
  {
    week: 5,
    date: '2025-04-26',
    home: 'WSH',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'AZ',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'COL',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'LAD',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'SD',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'SF',
    away: 'TEX',
    time: '13:05',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'CHC',
    away: 'PHI',
    time: '13:20',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'STL',
    away: 'MIL',
    time: '13:15',
  },
  {
    week: 5,
    date: '2025-04-27',
    home: 'WSH',
    away: 'NYM',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'COL',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'LAD',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-28',
    home: 'WSH',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'COL',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'LAD',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'SD',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'NYM',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-04-29',
    home: 'PHI',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'COL',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'LAD',
    away: 'MIA',
    time: '12:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'SD',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'NYM',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-04-30',
    home: 'PHI',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'SF',
    away: 'COL',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'CIN',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'PIT',
    away: 'CHC',
    time: '12:35',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'NYM',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-01',
    home: 'PHI',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'SF',
    away: 'COL',
    time: '19:15',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'CIN',
    away: 'WSH',
    time: '18:10',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'MIL',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'PIT',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'STL',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'ATL',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'MIA',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 6,
    date: '2025-05-02',
    home: 'PHI',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'SF',
    away: 'COL',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'CIN',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'MIL',
    away: 'CHC',
    time: '18:10',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'PIT',
    away: 'SD',
    time: '16:05',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'STL',
    away: 'NYM',
    time: '13:15',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'ATL',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'MIA',
    away: 'OAK',
    time: '16:10',
  },
  {
    week: 6,
    date: '2025-05-03',
    home: 'PHI',
    away: 'AZ',
    time: '18:05',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'SF',
    away: 'COL',
    time: '13:05',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'CIN',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'MIL',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'PIT',
    away: 'SD',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'STL',
    away: 'NYM',
    time: '13:15',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'ATL',
    away: 'LAD',
    time: '13:35',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'MIA',
    away: 'OAK',
    time: '13:40',
  },
  {
    week: 6,
    date: '2025-05-04',
    home: 'PHI',
    away: 'AZ',
    time: '14:05',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'AZ',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'CHC',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'MIL',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'STL',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'ATL',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'MIA',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-05',
    home: 'WSH',
    away: 'CLE',
    time: '18:45',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'AZ',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'COL',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'CHC',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'MIL',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'STL',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'ATL',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'MIA',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-06',
    home: 'WSH',
    away: 'CLE',
    time: '18:45',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'AZ',
    away: 'NYM',
    time: '12:40',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'COL',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'CHC',
    away: 'SF',
    time: '13:20',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'MIL',
    away: 'HOU',
    time: '12:10',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'STL',
    away: 'PIT',
    time: '12:15',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'ATL',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'MIA',
    away: 'LAD',
    time: '16:40',
  },
  {
    week: 7,
    date: '2025-05-07',
    home: 'WSH',
    away: 'CLE',
    time: '12:05',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'AZ',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'COL',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-08',
    home: 'ATL',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'AZ',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'COL',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'PIT',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'NYM',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 7,
    date: '2025-05-09',
    home: 'WSH',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'AZ',
    away: 'LAD',
    time: '17:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'COL',
    away: 'SD',
    time: '18:10',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'PIT',
    away: 'ATL',
    time: '16:05',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'NYM',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 7,
    date: '2025-05-10',
    home: 'WSH',
    away: 'STL',
    time: '16:05',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'AZ',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'COL',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'PIT',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'NYM',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 7,
    date: '2025-05-11',
    home: 'WSH',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'SD',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'SF',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'CHC',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'ATL',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'NYM',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-12',
    home: 'PHI',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'LAD',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'SD',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'SF',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'CHC',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'CIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'ATL',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'NYM',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-13',
    home: 'PHI',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'LAD',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'SD',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'SF',
    away: 'AZ',
    time: '12:45',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'CHC',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'CIN',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'ATL',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'NYM',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-14',
    home: 'PHI',
    away: 'STL',
    time: '13:05',
  },
  {
    week: 8,
    date: '2025-05-15',
    home: 'LAD',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-15',
    home: 'CIN',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-15',
    home: 'ATL',
    away: 'WSH',
    time: '12:15',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'AZ',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'LAD',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'SD',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'SF',
    away: 'OAK',
    time: '18:45',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'CHC',
    away: 'CWS',
    time: '13:20',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'CIN',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'MIL',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'MIA',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 8,
    date: '2025-05-16',
    home: 'PHI',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'AZ',
    away: 'COL',
    time: '17:10',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'LAD',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'SD',
    away: 'SEA',
    time: '17:40',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'SF',
    away: 'OAK',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'CHC',
    away: 'CWS',
    time: '13:20',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'CIN',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'MIL',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'MIA',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 8,
    date: '2025-05-17',
    home: 'PHI',
    away: 'PIT',
    time: '18:05',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'AZ',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'LAD',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'SD',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'SF',
    away: 'OAK',
    time: '13:05',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'CHC',
    away: 'CWS',
    time: '13:20',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'CIN',
    away: 'CLE',
    time: '13:40',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'MIL',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'MIA',
    away: 'TB',
    time: '13:40',
  },
  {
    week: 8,
    date: '2025-05-18',
    home: 'PHI',
    away: 'PIT',
    time: '13:35',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'COL',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'LAD',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'SF',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'MIL',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'STL',
    away: 'DET',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-19',
    home: 'MIA',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'COL',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'LAD',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'SF',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'MIL',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'STL',
    away: 'DET',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'MIA',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-20',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'COL',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'LAD',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'SF',
    away: 'KC',
    time: '12:45',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'MIL',
    away: 'BAL',
    time: '12:10',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'PIT',
    away: 'CIN',
    time: '12:35',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'STL',
    away: 'DET',
    time: '12:15',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'MIA',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-21',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'COL',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-22',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'COL',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'CIN',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'STL',
    away: 'AZ',
    time: '19:15',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'ATL',
    away: 'SD',
    time: '19:15',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'NYM',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 9,
    date: '2025-05-23',
    home: 'WSH',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'COL',
    away: 'NYY',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'CIN',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'PIT',
    away: 'MIL',
    time: '16:05',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'STL',
    away: 'AZ',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'ATL',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'NYM',
    away: 'LAD',
    time: '13:40',
  },
  {
    week: 9,
    date: '2025-05-24',
    home: 'WSH',
    away: 'SF',
    time: '16:05',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'COL',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'CIN',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'PIT',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'STL',
    away: 'AZ',
    time: '13:15',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'ATL',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'NYM',
    away: 'LAD',
    time: '13:40',
  },
  {
    week: 9,
    date: '2025-05-25',
    home: 'WSH',
    away: 'SF',
    time: '13:35',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'AZ',
    away: 'PIT',
    time: '17:10',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'SD',
    away: 'MIA',
    time: '15:40',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'CHC',
    away: 'COL',
    time: '13:20',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'MIL',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-26',
    home: 'NYM',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'AZ',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'SD',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'CHC',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'MIL',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'NYM',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-27',
    home: 'PHI',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'AZ',
    away: 'PIT',
    time: '12:40',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'SD',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'CHC',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'MIL',
    away: 'BOS',
    time: '12:10',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'NYM',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-28',
    home: 'PHI',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 10,
    date: '2025-05-29',
    home: 'PHI',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'AZ',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'LAD',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'SD',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'CHC',
    away: 'CIN',
    time: '13:20',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'ATL',
    away: 'BOS',
    time: '19:15',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'MIA',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'NYM',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-30',
    home: 'PHI',
    away: 'MIL',
    time: '18:45',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'AZ',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'LAD',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'SD',
    away: 'PIT',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'CHC',
    away: 'CIN',
    time: '13:20',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'ATL',
    away: 'BOS',
    time: '16:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'MIA',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'NYM',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 10,
    date: '2025-05-31',
    home: 'PHI',
    away: 'MIL',
    time: '16:05',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'AZ',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'LAD',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'SD',
    away: 'PIT',
    time: '14:10',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'CHC',
    away: 'CIN',
    time: '13:20',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'ATL',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'MIA',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'NYM',
    away: 'COL',
    time: '13:40',
  },
  {
    week: 10,
    date: '2025-06-01',
    home: 'PHI',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'LAD',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'SF',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'CIN',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-02',
    home: 'MIA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'LAD',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'SF',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'CIN',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'PIT',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'STL',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'ATL',
    away: 'AZ',
    time: '19:15',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'MIA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-03',
    home: 'WSH',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'LAD',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'SF',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'CIN',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'PIT',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'STL',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'ATL',
    away: 'AZ',
    time: '19:15',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'MIA',
    away: 'COL',
    time: '12:10',
  },
  {
    week: 11,
    date: '2025-06-04',
    home: 'WSH',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'LAD',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'SF',
    away: 'SD',
    time: '12:45',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'PIT',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'STL',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'ATL',
    away: 'AZ',
    time: '12:15',
  },
  {
    week: 11,
    date: '2025-06-05',
    home: 'WSH',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'COL',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'SF',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'CIN',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'MIL',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'PIT',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'STL',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 11,
    date: '2025-06-06',
    home: 'WSH',
    away: 'TEX',
    time: '18:45',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'COL',
    away: 'NYM',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'SF',
    away: 'ATL',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'CIN',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'MIL',
    away: 'SD',
    time: '15:10',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'PIT',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'STL',
    away: 'LAD',
  },
  {
    week: 11,
    date: '2025-06-07',
    home: 'WSH',
    away: 'TEX',
    time: '16:05',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'COL',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'SF',
    away: 'ATL',
    time: '13:05',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'CIN',
    away: 'AZ',
    time: '13:40',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'MIL',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'PIT',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'STL',
    away: 'LAD',
    time: '13:15',
  },
  {
    week: 11,
    date: '2025-06-08',
    home: 'WSH',
    away: 'TEX',
    time: '13:35',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'AZ',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'SD',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'MIL',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'PIT',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'STL',
    away: 'TOR',
    time: '18:45',
  },
  {
    week: 12,
    date: '2025-06-09',
    home: 'PHI',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'AZ',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'COL',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'SD',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'MIL',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'PIT',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'STL',
    away: 'TOR',
    time: '18:45',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-10',
    home: 'PHI',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'AZ',
    away: 'SEA',
    time: '12:40',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'COL',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'SD',
    away: 'LAD',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'MIL',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'PIT',
    away: 'MIA',
    time: '12:35',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'STL',
    away: 'TOR',
    time: '13:15',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-11',
    home: 'PHI',
    away: 'CHC',
    time: '13:05',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'COL',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'CHC',
    away: 'PIT',
    time: '19:05',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'MIL',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-12',
    home: 'NYM',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'AZ',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'LAD',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'CHC',
    away: 'PIT',
    time: '13:20',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'MIL',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'ATL',
    away: 'COL',
    time: '19:15',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'NYM',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'PHI',
    away: 'TOR',
    time: '18:45',
  },
  {
    week: 12,
    date: '2025-06-13',
    home: 'WSH',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'AZ',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'LAD',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'CHC',
    away: 'PIT',
    time: '13:20',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'MIL',
    away: 'STL',
    time: '15:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'ATL',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'NYM',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'PHI',
    away: 'TOR',
    time: '16:05',
  },
  {
    week: 12,
    date: '2025-06-14',
    home: 'WSH',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'AZ',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'LAD',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'CHC',
    away: 'PIT',
    time: '13:20',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'MIL',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'ATL',
    away: 'COL',
    time: '13:35',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'NYM',
    away: 'TB',
    time: '13:40',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'PHI',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 12,
    date: '2025-06-15',
    home: 'WSH',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'LAD',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'MIA',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-16',
    home: 'WSH',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'LAD',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'SF',
    away: 'CLE',
    time: '18:45',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'CHC',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'CIN',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'ATL',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'MIA',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-17',
    home: 'WSH',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'LAD',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'SF',
    away: 'CLE',
    time: '18:45',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'CHC',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'CIN',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'ATL',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'MIA',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-18',
    home: 'WSH',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'LAD',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'SF',
    away: 'CLE',
    time: '13:05',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'CHC',
    away: 'MIL',
    time: '13:20',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'CIN',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'ATL',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'MIA',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-19',
    home: 'WSH',
    away: 'COL',
    time: '13:05',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'COL',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'LAD',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'SD',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'SF',
    away: 'BOS',
    time: '19:15',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'CHC',
    away: 'SEA',
    time: '13:20',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'PIT',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'STL',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'MIA',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-20',
    home: 'PHI',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'COL',
    away: 'AZ',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'LAD',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'SD',
    away: 'KC',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'SF',
    away: 'BOS',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'CHC',
    away: 'SEA',
    time: '13:20',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'PIT',
    away: 'TEX',
    time: '16:05',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'STL',
    away: 'CIN',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'MIA',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 13,
    date: '2025-06-21',
    home: 'PHI',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'COL',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'LAD',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'SD',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'SF',
    away: 'BOS',
    time: '13:05',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'CHC',
    away: 'SEA',
    time: '13:20',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'PIT',
    away: 'TEX',
    time: '13:35',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'STL',
    away: 'CIN',
    time: '13:15',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'MIA',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 13,
    date: '2025-06-22',
    home: 'PHI',
    away: 'NYM',
    time: '13:35',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'SD',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'CIN',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'MIL',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'STL',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 14,
    date: '2025-06-23',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'COL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'SD',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'SF',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'CIN',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'MIL',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'STL',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 14,
    date: '2025-06-24',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'COL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'SD',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'SF',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'CIN',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'MIL',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'STL',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 14,
    date: '2025-06-25',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'COL',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'SF',
    away: 'MIA',
    time: '12:45',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'STL',
    away: 'CHC',
    time: '13:15',
  },
  {
    week: 14,
    date: '2025-06-26',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'AZ',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'CIN',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'MIL',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'PIT',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 14,
    date: '2025-06-27',
    home: 'ATL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'AZ',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'CIN',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'MIL',
    away: 'COL',
    time: '15:10',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'PIT',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 14,
    date: '2025-06-28',
    home: 'ATL',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'AZ',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'CIN',
    away: 'SD',
    time: '13:40',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'MIL',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'PIT',
    away: 'NYM',
    time: '13:35',
  },
  {
    week: 14,
    date: '2025-06-29',
    home: 'ATL',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'AZ',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-06-30',
    home: 'PHI',
    away: 'SD',
    time: '18:30',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'AZ',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'COL',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'LAD',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'CHC',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'ATL',
    away: 'LAA',
    time: '19:15',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'MIA',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'NYM',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'PHI',
    away: 'SD',
    time: '18:30',
  },
  {
    week: 15,
    date: '2025-07-01',
    home: 'WSH',
    away: 'DET',
    time: '18:45',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'AZ',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'COL',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'LAD',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'CHC',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'PIT',
    away: 'STL',
    time: '12:35',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'ATL',
    away: 'LAA',
    time: '19:15',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'MIA',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'NYM',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'PHI',
    away: 'SD',
    time: '13:05',
  },
  {
    week: 15,
    date: '2025-07-02',
    home: 'WSH',
    away: 'DET',
    time: '18:45',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'AZ',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'COL',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'LAD',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'CHC',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'ATL',
    away: 'LAA',
    time: '19:15',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'MIA',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'NYM',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-03',
    home: 'WSH',
    away: 'DET',
    time: '18:45',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'AZ',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'COL',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'LAD',
    away: 'HOU',
    time: '18:10',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'SD',
    away: 'TEX',
    time: '15:40',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'CHC',
    away: 'STL',
    time: '13:20',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'ATL',
    away: 'BAL',
    time: '19:15',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'MIA',
    away: 'MIL',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'NYM',
    away: 'NYY',
    time: '15:10',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'PHI',
    away: 'CIN',
    time: '16:05',
  },
  {
    week: 15,
    date: '2025-07-04',
    home: 'WSH',
    away: 'BOS',
    time: '11:05',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'AZ',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'COL',
    away: 'CWS',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'LAD',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'SD',
    away: 'TEX',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'CHC',
    away: 'STL',
    time: '13:20',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'ATL',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'MIA',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'NYM',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'PHI',
    away: 'CIN',
    time: '16:05',
  },
  {
    week: 15,
    date: '2025-07-05',
    home: 'WSH',
    away: 'BOS',
    time: '16:05',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'AZ',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'COL',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'LAD',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'SD',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'CHC',
    away: 'STL',
    time: '13:20',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'ATL',
    away: 'BAL',
    time: '13:35',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'MIA',
    away: 'MIL',
    time: '13:40',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'NYM',
    away: 'NYY',
    time: '13:40',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'PHI',
    away: 'CIN',
    time: '13:35',
  },
  {
    week: 15,
    date: '2025-07-06',
    home: 'WSH',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'SD',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'SF',
    away: 'PHI',
    time: '18:45',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'CIN',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-07',
    home: 'MIL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'SD',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'SF',
    away: 'PHI',
    time: '18:45',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'CIN',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'MIL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-08',
    home: 'STL',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'SD',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'SF',
    away: 'PHI',
    time: '12:45',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'CIN',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'MIL',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-09',
    home: 'STL',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'SD',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'CIN',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-10',
    home: 'STL',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'SD',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'SF',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'CIN',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'MIL',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 16,
    date: '2025-07-11',
    home: 'STL',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'SD',
    away: 'PHI',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'SF',
    away: 'LAD',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'CIN',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'MIL',
    away: 'WSH',
    time: '15:10',
  },
  {
    week: 16,
    date: '2025-07-12',
    home: 'STL',
    away: 'ATL',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'SD',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'SF',
    away: 'LAD',
    time: '13:05',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'CIN',
    away: 'COL',
    time: '13:40',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'MIL',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 16,
    date: '2025-07-13',
    home: 'STL',
    away: 'ATL',
    time: '13:15',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'AZ',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'COL',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'LAD',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'CHC',
    away: 'BOS',
    time: '13:20',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'PIT',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'ATL',
    away: 'NYY',
    time: '19:15',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'MIA',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'NYM',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'PHI',
    away: 'LAA',
    time: '18:45',
  },
  {
    week: 17,
    date: '2025-07-18',
    home: 'WSH',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'AZ',
    away: 'STL',
    time: '17:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'COL',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'LAD',
    away: 'MIL',
    time: '18:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'CHC',
    away: 'BOS',
    time: '13:20',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'PIT',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'ATL',
    away: 'NYY',
    time: '19:15',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'MIA',
    away: 'KC',
    time: '16:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'NYM',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'PHI',
    away: 'LAA',
    time: '18:05',
  },
  {
    week: 17,
    date: '2025-07-19',
    home: 'WSH',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'AZ',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'COL',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'LAD',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'CHC',
    away: 'BOS',
    time: '13:20',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'PIT',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'ATL',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'MIA',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'NYM',
    away: 'CIN',
    time: '13:40',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'PHI',
    away: 'LAA',
    time: '13:35',
  },
  {
    week: 17,
    date: '2025-07-20',
    home: 'WSH',
    away: 'SD',
    time: '13:35',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'AZ',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'COL',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'LAD',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'CHC',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'PIT',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'ATL',
    away: 'SF',
    time: '19:15',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'MIA',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'NYM',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'PHI',
    away: 'BOS',
    time: '18:45',
  },
  {
    week: 18,
    date: '2025-07-21',
    home: 'WSH',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'AZ',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'COL',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'LAD',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'CHC',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'PIT',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'ATL',
    away: 'SF',
    time: '19:15',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'MIA',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'NYM',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'PHI',
    away: 'BOS',
    time: '18:45',
  },
  {
    week: 18,
    date: '2025-07-22',
    home: 'WSH',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'AZ',
    away: 'HOU',
    time: '12:40',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'COL',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'LAD',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'CHC',
    away: 'KC',
    time: '13:20',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'PIT',
    away: 'DET',
    time: '12:35',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'ATL',
    away: 'SF',
    time: '12:15',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'MIA',
    away: 'SD',
    time: '12:10',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'NYM',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'PHI',
    away: 'BOS',
    time: '18:05',
  },
  {
    week: 18,
    date: '2025-07-23',
    home: 'WSH',
    away: 'CIN',
    time: '12:05',
  },
  {
    week: 18,
    date: '2025-07-24',
    home: 'STL',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'SF',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'CIN',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'MIL',
    away: 'MIA',
    time: '15:10',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'PIT',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-25',
    home: 'STL',
    away: 'SD',
    time: '19:15',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'SF',
    away: 'NYM',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'CIN',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'MIL',
    away: 'MIA',
    time: '18:10',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'PIT',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 18,
    date: '2025-07-26',
    home: 'STL',
    away: 'SD',
    time: '18:15',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'SF',
    away: 'NYM',
    time: '13:05',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'CIN',
    away: 'TB',
    time: '13:40',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'MIL',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'PIT',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 18,
    date: '2025-07-27',
    home: 'STL',
    away: 'SD',
    time: '13:15',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'SD',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'SF',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'CIN',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'MIL',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-28',
    home: 'STL',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'SD',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'SF',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'CIN',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'MIL',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-07-29',
    home: 'STL',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'SD',
    away: 'NYM',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'SF',
    away: 'PIT',
    time: '12:45',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'CIN',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'MIL',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-07-30',
    home: 'STL',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-07-31',
    home: 'CIN',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'COL',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'SD',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'CHC',
    away: 'BAL',
    time: '13:20',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'CIN',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'MIA',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'NYM',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'PHI',
    away: 'DET',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-08-01',
    home: 'WSH',
    away: 'MIL',
    time: '18:45',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'COL',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'SD',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'CHC',
    away: 'BAL',
    time: '13:20',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'CIN',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'MIA',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'NYM',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'PHI',
    away: 'DET',
    time: '16:05',
  },
  {
    week: 19,
    date: '2025-08-02',
    home: 'WSH',
    away: 'MIL',
    time: '16:05',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'COL',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'SD',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'CHC',
    away: 'BAL',
    time: '13:20',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'MIA',
    away: 'NYY',
    time: '13:40',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'NYM',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'PHI',
    away: 'DET',
    time: '13:35',
  },
  {
    week: 19,
    date: '2025-08-03',
    home: 'WSH',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'AZ',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'COL',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'LAD',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'CHC',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'PIT',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'ATL',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'MIA',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'NYM',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-04',
    home: 'PHI',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'AZ',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'COL',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'LAD',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'CHC',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'PIT',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'ATL',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'MIA',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'NYM',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'PHI',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 20,
    date: '2025-08-05',
    home: 'WSH',
    away: 'OAK',
    time: '18:45',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'AZ',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'COL',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'LAD',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'CHC',
    away: 'CIN',
    time: '13:20',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'PIT',
    away: 'SF',
    time: '12:35',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'ATL',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'MIA',
    away: 'HOU',
    time: '16:40',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'NYM',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'PHI',
    away: 'BAL',
    time: '12:35',
  },
  {
    week: 20,
    date: '2025-08-06',
    home: 'WSH',
    away: 'OAK',
    time: '18:45',
  },
  {
    week: 20,
    date: '2025-08-07',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-07',
    home: 'ATL',
    away: 'MIA',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-07',
    home: 'WSH',
    away: 'OAK',
    time: '12:05',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'AZ',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'LAD',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'SD',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'SF',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'MIL',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'STL',
    away: 'CHC',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-08',
    home: 'ATL',
    away: 'MIA',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'AZ',
    away: 'COL',
    time: '17:10',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'LAD',
    away: 'TOR',
    time: '18:10',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'SD',
    away: 'BOS',
    time: '17:40',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'SF',
    away: 'WSH',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'MIL',
    away: 'NYM',
    time: '18:10',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'STL',
    away: 'CHC',
    time: '18:15',
  },
  {
    week: 20,
    date: '2025-08-09',
    home: 'ATL',
    away: 'MIA',
    time: '19:15',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'AZ',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'LAD',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'SD',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'SF',
    away: 'WSH',
    time: '13:05',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'MIL',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'PIT',
    away: 'CIN',
    time: '13:35',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'STL',
    away: 'CHC',
    time: '13:15',
  },
  {
    week: 20,
    date: '2025-08-10',
    home: 'ATL',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'SF',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'CIN',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'MIL',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-11',
    home: 'STL',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'SF',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'CIN',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'MIL',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'STL',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 21,
    date: '2025-08-12',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'SF',
    away: 'SD',
    time: '12:45',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'CIN',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'MIL',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'STL',
    away: 'COL',
    time: '13:15',
  },
  {
    week: 21,
    date: '2025-08-13',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'COL',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-14',
    home: 'WSH',
    away: 'PHI',
    time: '18:45',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'COL',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'LAD',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'SF',
    away: 'TB',
    time: '19:15',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'CHC',
    away: 'PIT',
    time: '13:20',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'CIN',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'STL',
    away: 'NYY',
    time: '19:15',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'NYM',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-15',
    home: 'WSH',
    away: 'PHI',
    time: '18:45',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'COL',
    away: 'AZ',
    time: '18:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'LAD',
    away: 'SD',
    time: '18:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'SF',
    away: 'TB',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'CHC',
    away: 'PIT',
    time: '13:20',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'CIN',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'STL',
    away: 'NYY',
    time: '18:15',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'NYM',
    away: 'SEA',
    time: '16:10',
  },
  {
    week: 21,
    date: '2025-08-16',
    home: 'WSH',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'COL',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'LAD',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'SF',
    away: 'TB',
    time: '13:05',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'CHC',
    away: 'PIT',
    time: '13:20',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'CIN',
    away: 'MIL',
    time: '13:40',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'STL',
    away: 'NYY',
    time: '13:15',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'NYM',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 21,
    date: '2025-08-17',
    home: 'WSH',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'AZ',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'COL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'SD',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'CHC',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'PIT',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'ATL',
    away: 'CWS',
    time: '19:15',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'MIA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-18',
    home: 'PHI',
    away: 'SEA',
    time: '18:45',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'AZ',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'COL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'SD',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'CHC',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'PIT',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'ATL',
    away: 'CWS',
    time: '19:15',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'MIA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'PHI',
    away: 'SEA',
    time: '18:45',
  },
  {
    week: 22,
    date: '2025-08-19',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'AZ',
    away: 'CLE',
    time: '12:40',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'COL',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'SD',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'CHC',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'PIT',
    away: 'TOR',
    time: '12:35',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'ATL',
    away: 'CWS',
    time: '19:15',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'MIA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'PHI',
    away: 'SEA',
    time: '16:05',
  },
  {
    week: 22,
    date: '2025-08-20',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'COL',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'SD',
    away: 'SF',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'CHC',
    away: 'MIL',
    time: '13:20',
  },
  {
    week: 22,
    date: '2025-08-21',
    home: 'WSH',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'AZ',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'SD',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'MIL',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'PIT',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'ATL',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'MIA',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 22,
    date: '2025-08-22',
    home: 'PHI',
    away: 'WSH',
    time: '18:45',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'AZ',
    away: 'CIN',
    time: '17:10',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'SD',
    away: 'LAD',
    time: '17:40',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'MIL',
    away: 'SF',
    time: '18:10',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'PIT',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'ATL',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'MIA',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 22,
    date: '2025-08-23',
    home: 'PHI',
    away: 'WSH',
    time: '18:05',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'AZ',
    away: 'CIN',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'SD',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'MIL',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'PIT',
    away: 'COL',
    time: '13:35',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'ATL',
    away: 'NYM',
    time: '13:35',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'MIA',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 22,
    date: '2025-08-24',
    home: 'PHI',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'LAD',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'MIL',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'STL',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'MIA',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-25',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'LAD',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'SF',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'MIL',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'STL',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'MIA',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-26',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'LAD',
    away: 'CIN',
    time: '17:40',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'SF',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'MIL',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'STL',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'MIA',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-27',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'SF',
    away: 'CHC',
    time: '12:45',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'MIL',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'STL',
    away: 'PIT',
    time: '13:15',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-28',
    home: 'PHI',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'COL',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'LAD',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'SF',
    away: 'BAL',
    time: '19:15',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'PHI',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-29',
    home: 'WSH',
    away: 'TB',
    time: '18:45',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'COL',
    away: 'CHC',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'LAD',
    away: 'AZ',
    time: '18:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'SF',
    away: 'BAL',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'NYM',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'PHI',
    away: 'ATL',
    time: '18:05',
  },
  {
    week: 23,
    date: '2025-08-30',
    home: 'WSH',
    away: 'TB',
    time: '16:05',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'COL',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'LAD',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'SF',
    away: 'BAL',
    time: '13:05',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'CIN',
    away: 'STL',
    time: '12:10',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'NYM',
    away: 'MIA',
    time: '13:40',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'PHI',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 23,
    date: '2025-08-31',
    home: 'WSH',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'AZ',
    away: 'TEX',
    time: '17:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'COL',
    away: 'SF',
    time: '14:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'SD',
    away: 'BAL',
    time: '15:40',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'CHC',
    away: 'ATL',
    time: '13:20',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'CIN',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'MIL',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'STL',
    away: 'OAK',
    time: '13:15',
  },
  {
    week: 24,
    date: '2025-09-01',
    home: 'WSH',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'AZ',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'COL',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'SD',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'CHC',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'CIN',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'PIT',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'STL',
    away: 'OAK',
    time: '18:45',
  },
  {
    week: 24,
    date: '2025-09-02',
    home: 'WSH',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'AZ',
    away: 'TEX',
    time: '12:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'COL',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'SD',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'CHC',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'CIN',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'MIL',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'PIT',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'STL',
    away: 'OAK',
    time: '18:45',
  },
  {
    week: 24,
    date: '2025-09-03',
    home: 'WSH',
    away: 'MIA',
    time: '13:05',
  },
  {
    week: 24,
    date: '2025-09-04',
    home: 'MIL',
    away: 'PHI',
  },
  {
    week: 24,
    date: '2025-09-04',
    home: 'PIT',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'AZ',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'COL',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'CHC',
    away: 'WSH',
    time: '13:20',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'CIN',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'STL',
    away: 'SF',
    time: '19:15',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'ATL',
    away: 'SEA',
    time: '19:15',
  },
  {
    week: 24,
    date: '2025-09-05',
    home: 'MIA',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'AZ',
    away: 'BOS',
    time: '17:10',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'COL',
    away: 'SD',
    time: '18:10',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'CHC',
    away: 'WSH',
    time: '13:20',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'CIN',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'STL',
    away: 'SF',
    time: '18:15',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'ATL',
    away: 'SEA',
    time: '19:15',
  },
  {
    week: 24,
    date: '2025-09-06',
    home: 'MIA',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'AZ',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'COL',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'CHC',
    away: 'WSH',
    time: '13:20',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'CIN',
    away: 'NYM',
    time: '13:40',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'PIT',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'STL',
    away: 'SF',
    time: '13:15',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'ATL',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 24,
    date: '2025-09-07',
    home: 'MIA',
    away: 'PHI',
    time: '13:40',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'LAD',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'SD',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'SF',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'ATL',
    away: 'CHC',
    time: '19:15',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'MIA',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 25,
    date: '2025-09-08',
    home: 'PHI',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'LAD',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'SD',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'SF',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'ATL',
    away: 'CHC',
    time: '19:15',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'MIA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-09',
    home: 'PHI',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'LAD',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'SD',
    away: 'CIN',
    time: '17:40',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'SF',
    away: 'AZ',
    time: '12:45',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'ATL',
    away: 'CHC',
    time: '19:15',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'MIA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-10',
    home: 'PHI',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'SD',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'MIA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-11',
    home: 'PHI',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'SD',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'SF',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'CHC',
    away: 'TB',
    time: '13:20',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'MIL',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'ATL',
    away: 'HOU',
    time: '19:15',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'MIA',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'NYM',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'PHI',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-12',
    home: 'WSH',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'SD',
    away: 'COL',
    time: '17:40',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'SF',
    away: 'LAD',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'CHC',
    away: 'TB',
    time: '13:20',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'MIL',
    away: 'STL',
    time: '18:10',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'ATL',
    away: 'HOU',
    time: '19:15',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'MIA',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'NYM',
    away: 'TEX',
    time: '16:10',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'PHI',
    away: 'KC',
    time: '18:05',
  },
  {
    week: 25,
    date: '2025-09-13',
    home: 'WSH',
    away: 'PIT',
    time: '16:05',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'SD',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'SF',
    away: 'LAD',
    time: '13:05',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'CHC',
    away: 'TB',
    time: '13:20',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'MIL',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'ATL',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'MIA',
    away: 'DET',
    time: '13:40',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'NYM',
    away: 'TEX',
    time: '13:40',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'PHI',
    away: 'KC',
    time: '13:35',
  },
  {
    week: 25,
    date: '2025-09-14',
    home: 'WSH',
    away: 'PIT',
    time: '13:35',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'AZ',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'LAD',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'STL',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 26,
    date: '2025-09-15',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'AZ',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'COL',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'LAD',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'MIL',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'STL',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'NYM',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-16',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'AZ',
    away: 'SF',
    time: '12:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'COL',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'LAD',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'MIL',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'PIT',
    away: 'CHC',
    time: '12:35',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'STL',
    away: 'CIN',
    time: '12:15',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'NYM',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-17',
    home: 'WSH',
    away: 'ATL',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'COL',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'LAD',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'CIN',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'MIL',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-18',
    home: 'NYM',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'AZ',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'COL',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'LAD',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'CIN',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'PIT',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'STL',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 26,
    date: '2025-09-19',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'AZ',
    away: 'PHI',
    time: '17:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'COL',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'LAD',
    away: 'SF',
    time: '18:10',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'CIN',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'PIT',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'STL',
    away: 'MIL',
    time: '18:15',
  },
  {
    week: 26,
    date: '2025-09-20',
    home: 'NYM',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'AZ',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'COL',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'LAD',
    away: 'SF',
    time: '13:10',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'CIN',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'PIT',
    away: 'OAK',
    time: '13:35',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'STL',
    away: 'MIL',
    time: '13:15',
  },
  {
    week: 26,
    date: '2025-09-21',
    home: 'NYM',
    away: 'WSH',
    time: '13:40',
  },
  {
    week: 27,
    date: '2025-09-22',
    home: 'SD',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-22',
    home: 'SF',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-22',
    home: 'ATL',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'AZ',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'SD',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'SF',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'CHC',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'CIN',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'ATL',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 27,
    date: '2025-09-23',
    home: 'PHI',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'AZ',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'SD',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'SF',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'CHC',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'CIN',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'ATL',
    away: 'WSH',
    time: '12:15',
  },
  {
    week: 27,
    date: '2025-09-24',
    home: 'PHI',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'AZ',
    away: 'LAD',
    time: '12:40',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'CHC',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'CIN',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 27,
    date: '2025-09-25',
    home: 'PHI',
    away: 'MIA',
    time: '18:05',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'SD',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'SF',
    away: 'COL',
    time: '19:15',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'CHC',
    away: 'STL',
    time: '13:20',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'MIL',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'ATL',
    away: 'PIT',
    time: '19:15',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'MIA',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'PHI',
    away: 'MIN',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-26',
    home: 'WSH',
    away: 'CWS',
    time: '18:45',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'SD',
    away: 'AZ',
    time: '17:40',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'SF',
    away: 'COL',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'CHC',
    away: 'STL',
    time: '13:20',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'MIL',
    away: 'CIN',
    time: '18:10',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'ATL',
    away: 'PIT',
    time: '19:15',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'MIA',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'PHI',
    away: 'MIN',
    time: '16:05',
  },
  {
    week: 27,
    date: '2025-09-27',
    home: 'WSH',
    away: 'CWS',
    time: '16:05',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'SD',
    away: 'AZ',
    time: '12:10',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'SF',
    away: 'COL',
    time: '12:05',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'MIL',
    away: 'CIN',
    time: '14:10',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'ATL',
    away: 'PIT',
    time: '15:15',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'MIA',
    away: 'NYM',
    time: '15:10',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'PHI',
    away: 'MIN',
    time: '15:05',
  },
  {
    week: 27,
    date: '2025-09-28',
    home: 'WSH',
    away: 'CWS',
    time: '15:05',
  },
];

export const MLB_2024_AL_SCHEDULE: MlbSchedule = [
  {
    week: 1,
    date: '2024-03-28',
    home: 'HOU',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'OAK',
    away: 'CLE',
    time: '22:07',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'SEA',
    away: 'BOS',
    time: '22:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'TEX',
    away: 'CHC',
    time: '19:35',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'CWS',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'KC',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'BAL',
    away: 'LAA',
    time: '15:05',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'TB',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'HOU',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'OAK',
    away: 'CLE',
    time: '21:40',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'SEA',
    away: 'BOS',
    time: '21:40',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'TB',
    away: 'TOR',
    time: '18:50',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'HOU',
    away: 'NYY',
    time: '19:15',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'OAK',
    away: 'CLE',
    time: '16:07',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'SEA',
    away: 'BOS',
    time: '21:40',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'TEX',
    away: 'CHC',
    time: '19:05',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'CWS',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'KC',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'BAL',
    away: 'LAA',
    time: '16:05',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'TB',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'HOU',
    away: 'NYY',
    time: '14:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'OAK',
    away: 'CLE',
    time: '16:07',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'SEA',
    away: 'BOS',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'TEX',
    away: 'CHC',
    time: '14:35',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'CWS',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'KC',
    away: 'MIN',
    time: '14:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'BAL',
    away: 'LAA',
    time: '13:35',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'TB',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'HOU',
    away: 'TOR',
    time: '20:10',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'OAK',
    away: 'BOS',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'SEA',
    away: 'CLE',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'CWS',
    away: 'ATL',
    time: '14:10',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'BAL',
    away: 'KC',
    time: '18:35',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'TB',
    away: 'TEX',
    time: '18:50',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'HOU',
    away: 'TOR',
    time: '20:10',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'OAK',
    away: 'BOS',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'SEA',
    away: 'CLE',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'CWS',
    away: 'ATL',
    time: '19:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'BAL',
    away: 'KC',
    time: '18:35',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'TB',
    away: 'TEX',
    time: '18:50',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'HOU',
    away: 'TOR',
    time: '20:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'OAK',
    away: 'BOS',
    time: '15:37',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'SEA',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'CWS',
    away: 'ATL',
    time: '14:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'BAL',
    away: 'KC',
    time: '13:05',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'TB',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 2,
    date: '2024-04-04',
    home: 'KC',
    away: 'CWS',
    time: '19:40',
  },
  {
    week: 2,
    date: '2024-04-04',
    home: 'MIN',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'LAA',
    away: 'BOS',
    time: '21:38',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'TEX',
    away: 'HOU',
    time: '20:05',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'DET',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'KC',
    away: 'CWS',
    time: '19:40',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'NYY',
    away: 'TOR',
    time: '13:05',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'LAA',
    away: 'BOS',
    time: '21:38',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'TEX',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'DET',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'KC',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'MIN',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'NYY',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'LAA',
    away: 'BOS',
    time: '16:07',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'TEX',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'DET',
    away: 'OAK',
    time: '13:40',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'KC',
    away: 'CWS',
    time: '14:10',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'MIN',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'NYY',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'LAA',
    away: 'TB',
    time: '21:38',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'TEX',
    away: 'HOU',
    time: '20:05',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'CLE',
    away: 'CWS',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'MIN',
    away: 'LAD',
    time: '19:40',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'NYY',
    away: 'MIA',
    time: '14:05',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'TOR',
    away: 'SEA',
    time: '19:07',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'LAA',
    away: 'TB',
    time: '21:38',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'TEX',
    away: 'OAK',
    time: '20:05',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'CLE',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'KC',
    away: 'HOU',
    time: '19:40',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'MIN',
    away: 'LAD',
    time: '19:40',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'BOS',
    away: 'BAL',
    time: '14:10',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'NYY',
    away: 'MIA',
    time: '19:05',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'TOR',
    away: 'SEA',
    time: '19:07',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'LAA',
    away: 'TB',
    time: '16:07',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'TEX',
    away: 'OAK',
    time: '20:05',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'CLE',
    away: 'CWS',
    time: '18:10',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'KC',
    away: 'HOU',
    time: '19:40',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'MIN',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'NYY',
    away: 'MIA',
    time: '19:05',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'TOR',
    away: 'SEA',
    time: '15:07',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'TEX',
    away: 'OAK',
    time: '14:35',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'DET',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'KC',
    away: 'HOU',
    time: '14:10',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'HOU',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'OAK',
    away: 'WSH',
    time: '21:40',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'SEA',
    away: 'CHC',
    time: '21:40',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'CLE',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'CWS',
    away: 'CIN',
    time: '19:40',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'DET',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'BAL',
    away: 'MIL',
    time: '19:05',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'BOS',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'TB',
    away: 'SF',
    time: '18:50',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'TOR',
    away: 'COL',
    time: '19:07',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'HOU',
    away: 'TEX',
    time: '16:05',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'OAK',
    away: 'WSH',
    time: '16:07',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'SEA',
    away: 'CHC',
    time: '21:40',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'CLE',
    away: 'NYY',
    time: '18:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'CWS',
    away: 'CIN',
    time: '14:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'DET',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'BAL',
    away: 'MIL',
    time: '16:05',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'BOS',
    away: 'LAA',
    time: '16:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'TB',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'TOR',
    away: 'COL',
    time: '15:07',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'HOU',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'OAK',
    away: 'WSH',
    time: '16:07',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'SEA',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'CLE',
    away: 'NYY',
    time: '13:40',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'CWS',
    away: 'CIN',
    time: '14:10',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'DET',
    away: 'MIN',
    time: '13:40',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'BAL',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'BOS',
    away: 'LAA',
    time: '13:35',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'TB',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'TOR',
    away: 'COL',
    time: '13:37',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'HOU',
    away: 'ATL',
    time: '20:10',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'OAK',
    away: 'STL',
    time: '21:40',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'SEA',
    away: 'CIN',
    time: '21:42',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'CWS',
    away: 'KC',
    time: '19:40',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'DET',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'BAL',
    away: 'MIN',
    time: '18:35',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'BOS',
    away: 'CLE',
    time: '11:10',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'TB',
    away: 'LAA',
    time: '18:50',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'HOU',
    away: 'ATL',
    time: '20:10',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'OAK',
    away: 'STL',
    time: '21:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'SEA',
    away: 'CIN',
    time: '21:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'CWS',
    away: 'KC',
    time: '19:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'DET',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'BAL',
    away: 'MIN',
    time: '18:35',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'BOS',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'TB',
    away: 'LAA',
    time: '18:50',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'HOU',
    away: 'ATL',
    time: '14:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'OAK',
    away: 'STL',
    time: '15:37',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'SEA',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'CWS',
    away: 'KC',
    time: '14:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'DET',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'BAL',
    away: 'MIN',
    time: '13:05',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'BOS',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'TB',
    away: 'LAA',
    time: '18:50',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'TOR',
    away: 'NYY',
    time: '15:07',
  },
  {
    week: 4,
    date: '2024-04-18',
    home: 'DET',
    away: 'TEX',
    time: '13:10',
  },
  {
    week: 4,
    date: '2024-04-18',
    home: 'BOS',
    away: 'CLE',
    time: '13:35',
  },
  {
    week: 4,
    date: '2024-04-18',
    home: 'TB',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'CLE',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'KC',
    away: 'BAL',
    time: '19:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'MIN',
    away: 'DET',
    time: '20:10',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'NYY',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'CLE',
    away: 'OAK',
    time: '18:10',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'KC',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'MIN',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'NYY',
    away: 'TB',
    time: '13:05',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'CLE',
    away: 'OAK',
    time: '13:40',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'KC',
    away: 'BAL',
    time: '14:10',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'MIN',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'NYY',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'LAA',
    away: 'BAL',
    time: '21:38',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'KC',
    away: 'TOR',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'MIN',
    away: 'CWS',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'NYY',
    away: 'OAK',
    time: '13:05',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'TB',
    away: 'DET',
    time: '18:50',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'LAA',
    away: 'BAL',
    time: '21:38',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'TEX',
    away: 'SEA',
    time: '20:05',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'CLE',
    away: 'BOS',
    time: '18:10',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'KC',
    away: 'TOR',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'MIN',
    away: 'CWS',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'NYY',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'TB',
    away: 'DET',
    time: '18:50',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'LAA',
    away: 'BAL',
    time: '16:07',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'TEX',
    away: 'SEA',
    time: '20:05',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'CLE',
    away: 'BOS',
    time: '18:10',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'KC',
    away: 'TOR',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'MIN',
    away: 'CWS',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'NYY',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'TB',
    away: 'DET',
    time: '18:50',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'TEX',
    away: 'SEA',
    time: '14:35',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'CLE',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'KC',
    away: 'TOR',
    time: '14:10',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'MIN',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'NYY',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'LAA',
    away: 'MIN',
    time: '21:38',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'SEA',
    away: 'AZ',
    time: '21:40',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'TEX',
    away: 'CIN',
    time: '20:05',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'CWS',
    away: 'TB',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'DET',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'BAL',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'BOS',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'TOR',
    away: 'LAD',
    time: '19:07',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'LAA',
    away: 'MIN',
    time: '21:38',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'SEA',
    away: 'AZ',
    time: '21:40',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'TEX',
    away: 'CIN',
    time: '16:05',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'CWS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'DET',
    away: 'KC',
    time: '18:10',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'BAL',
    away: 'OAK',
    time: '16:05',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'BOS',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'TOR',
    away: 'LAD',
    time: '15:07',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'LAA',
    away: 'MIN',
    time: '16:07',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'SEA',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'TEX',
    away: 'CIN',
    time: '14:35',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'CWS',
    away: 'TB',
    time: '14:10',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'DET',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'BAL',
    away: 'OAK',
    time: '13:35',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'BOS',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'TOR',
    away: 'LAD',
    time: '13:37',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'LAA',
    away: 'PHI',
    time: '21:38',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'OAK',
    away: 'PIT',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'SEA',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'CWS',
    away: 'MIN',
    time: '19:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'DET',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'TOR',
    away: 'KC',
    time: '19:07',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'HOU',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'LAA',
    away: 'PHI',
    time: '21:38',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'OAK',
    away: 'PIT',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'SEA',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'TEX',
    away: 'WSH',
    time: '20:05',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'CWS',
    away: 'MIN',
    time: '19:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'DET',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'BOS',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'TOR',
    away: 'KC',
    time: '19:07',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'HOU',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'LAA',
    away: 'PHI',
    time: '16:07',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'OAK',
    away: 'PIT',
    time: '15:37',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'SEA',
    away: 'ATL',
    time: '15:40',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'TEX',
    away: 'WSH',
    time: '20:05',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'CWS',
    away: 'MIN',
    time: '14:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'DET',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'BAL',
    away: 'NYY',
    time: '18:35',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'BOS',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'TOR',
    away: 'KC',
    time: '15:07',
  },
  {
    week: 6,
    date: '2024-05-02',
    home: 'HOU',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 6,
    date: '2024-05-02',
    home: 'TEX',
    away: 'WSH',
    time: '14:35',
  },
  {
    week: 6,
    date: '2024-05-02',
    home: 'BAL',
    away: 'NYY',
    time: '13:05',
  },
  {
    week: 6,
    date: '2024-05-02',
    home: 'BOS',
    away: 'SF',
    time: '13:35',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'HOU',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'OAK',
    away: 'MIA',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'CLE',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'KC',
    away: 'TEX',
    time: '19:40',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'MIN',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'NYY',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'TB',
    away: 'NYM',
    time: '18:50',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'HOU',
    away: 'SEA',
    time: '19:15',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'OAK',
    away: 'MIA',
    time: '16:07',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'CLE',
    away: 'LAA',
    time: '18:10',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'KC',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'MIN',
    away: 'BOS',
    time: '14:10',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'NYY',
    away: 'DET',
    time: '13:05',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'TB',
    away: 'NYM',
    time: '19:15',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'HOU',
    away: 'SEA',
    time: '14:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'OAK',
    away: 'MIA',
    time: '16:07',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'CLE',
    away: 'LAA',
    time: '13:40',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'KC',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'MIN',
    away: 'BOS',
    time: '14:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'NYY',
    away: 'DET',
    time: '13:35',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'TB',
    away: 'NYM',
    time: '13:40',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'OAK',
    away: 'TEX',
    time: '21:40',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'CLE',
    away: 'DET',
    time: '18:10',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'KC',
    away: 'MIL',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'MIN',
    away: 'SEA',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'TB',
    away: 'CWS',
    time: '18:50',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'OAK',
    away: 'TEX',
    time: '15:37',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'CLE',
    away: 'DET',
    time: '18:10',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'KC',
    away: 'MIL',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'MIN',
    away: 'SEA',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'NYY',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'TB',
    away: 'CWS',
    time: '18:50',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'OAK',
    away: 'TEX',
    time: '15:37',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'CLE',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'KC',
    away: 'MIL',
    time: '14:10',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'MIN',
    away: 'SEA',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'NYY',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'TB',
    away: 'CWS',
    time: '18:50',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'LAA',
    away: 'KC',
    time: '21:38',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'CWS',
    away: 'CLE',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'MIN',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'NYY',
    away: 'HOU',
    time: '17:05',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'LAA',
    away: 'KC',
    time: '21:38',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'SEA',
    away: 'OAK',
    time: '21:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'CWS',
    away: 'CLE',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'DET',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'BAL',
    away: 'AZ',
    time: '19:05',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'BOS',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'TOR',
    away: 'MIN',
    time: '19:07',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'LAA',
    away: 'KC',
    time: '21:38',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'SEA',
    away: 'OAK',
    time: '21:40',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'CWS',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'DET',
    away: 'HOU',
    time: '18:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'BAL',
    away: 'AZ',
    time: '16:05',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'BOS',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'TB',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'TOR',
    away: 'MIN',
    time: '15:07',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'LAA',
    away: 'KC',
    time: '16:07',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'SEA',
    away: 'OAK',
    time: '16:10',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'CWS',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'DET',
    away: 'HOU',
    time: '13:40',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'BAL',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'BOS',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'TB',
    away: 'NYY',
    time: '13:40',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'TOR',
    away: 'MIN',
    time: '13:37',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'HOU',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'LAA',
    away: 'STL',
    time: '21:38',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'SEA',
    away: 'KC',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'TEX',
    away: 'CLE',
    time: '20:05',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'CWS',
    away: 'WSH',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'DET',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'BAL',
    away: 'TOR',
    time: '18:35',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'HOU',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'LAA',
    away: 'STL',
    time: '21:38',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'SEA',
    away: 'KC',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'TEX',
    away: 'CLE',
    time: '20:05',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'CWS',
    away: 'WSH',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'DET',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'MIN',
    away: 'NYY',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'BAL',
    away: 'TOR',
    time: '18:35',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'HOU',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'LAA',
    away: 'STL',
    time: '21:07',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'SEA',
    away: 'KC',
    time: '16:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'TEX',
    away: 'CLE',
    time: '20:05',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'CWS',
    away: 'WSH',
    time: '14:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'DET',
    away: 'MIA',
    time: '13:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'MIN',
    away: 'NYY',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'BAL',
    away: 'TOR',
    time: '12:35',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-16',
    home: 'HOU',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 8,
    date: '2024-05-16',
    home: 'MIN',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 8,
    date: '2024-05-16',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'HOU',
    away: 'MIL',
    time: '20:10',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'TEX',
    away: 'LAA',
    time: '20:05',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'CLE',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'KC',
    away: 'OAK',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'BAL',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'NYY',
    away: 'CWS',
    time: '19:05',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'TOR',
    away: 'TB',
    time: '19:07',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'HOU',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'TEX',
    away: 'LAA',
    time: '19:15',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'CLE',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'KC',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'BAL',
    away: 'SEA',
    time: '16:05',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'NYY',
    away: 'CWS',
    time: '13:05',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'TOR',
    away: 'TB',
    time: '15:07',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'HOU',
    away: 'MIL',
    time: '14:10',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'TEX',
    away: 'LAA',
    time: '14:35',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'CLE',
    away: 'MIN',
    time: '13:40',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'KC',
    away: 'OAK',
    time: '14:10',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'BAL',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'NYY',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'TOR',
    away: 'TB',
    time: '13:37',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'HOU',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'CLE',
    away: 'NYM',
    time: '18:10',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'KC',
    away: 'DET',
    time: '19:40',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'NYY',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'TOR',
    away: 'CWS',
    time: '15:07',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'HOU',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'OAK',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'CLE',
    away: 'NYM',
    time: '18:10',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'KC',
    away: 'DET',
    time: '19:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'NYY',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'TOR',
    away: 'CWS',
    time: '19:07',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'HOU',
    away: 'LAA',
    time: '14:10',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'OAK',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'CLE',
    away: 'NYM',
    time: '13:10',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'KC',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'NYY',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'TOR',
    away: 'CWS',
    time: '19:07',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'OAK',
    away: 'COL',
    time: '15:37',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'CWS',
    away: 'BAL',
    time: '19:40',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'DET',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'NYY',
    away: 'SEA',
    time: '12:35',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'LAA',
    away: 'CLE',
    time: '21:38',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'OAK',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'CWS',
    away: 'BAL',
    time: '19:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'DET',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'MIN',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'BOS',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'TB',
    away: 'KC',
    time: '18:50',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'LAA',
    away: 'CLE',
    time: '21:38',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'OAK',
    away: 'HOU',
    time: '16:07',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'CWS',
    away: 'BAL',
    time: '14:10',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'DET',
    away: 'TOR',
    time: '13:10',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'MIN',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'BOS',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'TB',
    away: 'KC',
    time: '16:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'LAA',
    away: 'CLE',
    time: '16:07',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'OAK',
    away: 'HOU',
    time: '16:07',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'CWS',
    away: 'BAL',
    time: '14:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'DET',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'MIN',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'BOS',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'TB',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'SEA',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'CWS',
    away: 'TOR',
    time: '14:10',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'MIN',
    away: 'KC',
    time: '14:10',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'BAL',
    away: 'BOS',
    time: '13:05',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'LAA',
    away: 'NYY',
    time: '21:38',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'SEA',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'TEX',
    away: 'AZ',
    time: '20:05',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'CWS',
    away: 'TOR',
    time: '19:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'DET',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'MIN',
    away: 'KC',
    time: '19:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'TB',
    away: 'OAK',
    time: '18:50',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'LAA',
    away: 'NYY',
    time: '21:38',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'SEA',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'TEX',
    away: 'AZ',
    time: '14:35',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'CWS',
    away: 'TOR',
    time: '19:40',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'DET',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'MIN',
    away: 'KC',
    time: '19:40',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'TB',
    away: 'OAK',
    time: '18:50',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'LAA',
    away: 'NYY',
    time: '21:38',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'SEA',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'MIN',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'BOS',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'TB',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'HOU',
    away: 'MIN',
    time: '20:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'SEA',
    away: 'LAA',
    time: '22:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'CLE',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'KC',
    away: 'SD',
    time: '20:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'BAL',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'BOS',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'TOR',
    away: 'PIT',
    time: '19:07',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'HOU',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'SEA',
    away: 'LAA',
    time: '19:15',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'CLE',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'KC',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'BAL',
    away: 'TB',
    time: '16:05',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'BOS',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'TOR',
    away: 'PIT',
    time: '15:07',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'HOU',
    away: 'MIN',
    time: '14:10',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'SEA',
    away: 'LAA',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'CLE',
    away: 'WSH',
    time: '13:40',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'KC',
    away: 'SD',
    time: '14:10',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'BAL',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'BOS',
    away: 'DET',
    time: '13:35',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'TOR',
    away: 'PIT',
    time: '13:37',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'HOU',
    away: 'STL',
    time: '20:10',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'LAA',
    away: 'SD',
    time: '21:38',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'TEX',
    away: 'DET',
    time: '20:05',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'HOU',
    away: 'STL',
    time: '20:10',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'LAA',
    away: 'SD',
    time: '21:38',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'OAK',
    away: 'SEA',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'TEX',
    away: 'DET',
    time: '20:05',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'BOS',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'NYY',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'HOU',
    away: 'STL',
    time: '14:10',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'LAA',
    away: 'SD',
    time: '21:38',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'OAK',
    away: 'SEA',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'TEX',
    away: 'DET',
    time: '20:05',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'BOS',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'NYY',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'OAK',
    away: 'SEA',
    time: '15:37',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'CLE',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'CWS',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'NYY',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'TOR',
    away: 'BAL',
    time: '13:07',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'LAA',
    away: 'HOU',
    time: '21:38',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'OAK',
    away: 'TOR',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'TEX',
    away: 'SF',
    time: '20:05',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'CWS',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'DET',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'KC',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'NYY',
    away: 'LAD',
    time: '19:05',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'LAA',
    away: 'HOU',
    time: '22:07',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'OAK',
    away: 'TOR',
    time: '16:07',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'TEX',
    away: 'SF',
    time: '16:05',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'CWS',
    away: 'BOS',
    time: '16:10',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'DET',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'KC',
    away: 'SEA',
    time: '16:10',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'NYY',
    away: 'LAD',
    time: '19:35',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'TB',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'LAA',
    away: 'HOU',
    time: '16:07',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'OAK',
    away: 'TOR',
    time: '16:07',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'TEX',
    away: 'SF',
    time: '14:35',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'CWS',
    away: 'BOS',
    time: '14:10',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'DET',
    away: 'MIL',
    time: '13:40',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'KC',
    away: 'SEA',
    time: '14:10',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'NYY',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'TB',
    away: 'BAL',
    time: '13:40',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'SEA',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'KC',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'MIN',
    away: 'COL',
    time: '19:40',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'SEA',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'DET',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'KC',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'MIN',
    away: 'COL',
    time: '19:40',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'BAL',
    away: 'ATL',
    time: '18:35',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'BOS',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'TB',
    away: 'CHC',
    time: '18:50',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'SEA',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'DET',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'KC',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'MIN',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'BAL',
    away: 'ATL',
    time: '18:35',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'BOS',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'TB',
    away: 'CHC',
    time: '18:50',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'SEA',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'DET',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'KC',
    away: 'NYY',
    time: '14:10',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'MIN',
    away: 'OAK',
    time: '19:40',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'BAL',
    away: 'ATL',
    time: '13:05',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'BOS',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'TB',
    away: 'CHC',
    time: '18:50',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'HOU',
    away: 'DET',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'SEA',
    away: 'TEX',
    time: '22:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'MIN',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'BAL',
    away: 'PHI',
    time: '19:05',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'BOS',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'TOR',
    away: 'CLE',
    time: '19:07',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'HOU',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'SEA',
    away: 'TEX',
    time: '19:15',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'MIN',
    away: 'OAK',
    time: '14:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'BAL',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'BOS',
    away: 'NYY',
    time: '19:15',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'TOR',
    away: 'CLE',
    time: '15:07',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'HOU',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'SEA',
    away: 'TEX',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'MIN',
    away: 'OAK',
    time: '14:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'BAL',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'BOS',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'TOR',
    away: 'CLE',
    time: '13:37',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'LAA',
    away: 'MIL',
    time: '21:38',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'TEX',
    away: 'NYM',
    time: '20:05',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'LAA',
    away: 'MIL',
    time: '21:38',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'OAK',
    away: 'KC',
    time: '21:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'TEX',
    away: 'NYM',
    time: '20:05',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'CLE',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'CWS',
    away: 'HOU',
    time: '20:10',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'MIN',
    away: 'TB',
    time: '19:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'LAA',
    away: 'MIL',
    time: '21:38',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'OAK',
    away: 'KC',
    time: '21:40',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'TEX',
    away: 'NYM',
    time: '20:05',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'CLE',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'CWS',
    away: 'HOU',
    time: '20:10',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'MIN',
    away: 'TB',
    time: '19:40',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'OAK',
    away: 'KC',
    time: '15:37',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'CLE',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'CWS',
    away: 'HOU',
    time: '14:10',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'MIN',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'NYY',
    away: 'BAL',
    time: '16:05',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'HOU',
    away: 'BAL',
    time: '20:10',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'OAK',
    away: 'MIN',
    time: '21:40',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'TEX',
    away: 'KC',
    time: '20:05',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'CLE',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'DET',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'NYY',
    away: 'ATL',
    time: '19:05',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'HOU',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'OAK',
    away: 'MIN',
    time: '16:07',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'TEX',
    away: 'KC',
    time: '16:05',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'CLE',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'DET',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'NYY',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'HOU',
    away: 'BAL',
    time: '14:10',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'OAK',
    away: 'MIN',
    time: '16:07',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'TEX',
    away: 'KC',
    time: '14:35',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'CLE',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'DET',
    away: 'CWS',
    time: '13:40',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'NYY',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'LAA',
    away: 'OAK',
    time: '21:38',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'CWS',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'DET',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'KC',
    away: 'MIA',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'BAL',
    away: 'CLE',
    time: '18:35',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'TB',
    away: 'SEA',
    time: '18:50',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'HOU',
    away: 'COL',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'LAA',
    away: 'OAK',
    time: '21:38',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'CWS',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'DET',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'KC',
    away: 'MIA',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'BAL',
    away: 'CLE',
    time: '18:35',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'TB',
    away: 'SEA',
    time: '18:50',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'HOU',
    away: 'COL',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'LAA',
    away: 'OAK',
    time: '16:07',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'CWS',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'DET',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'KC',
    away: 'MIA',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'BAL',
    away: 'CLE',
    time: '18:35',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'TB',
    away: 'SEA',
    time: '12:10',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'LAA',
    away: 'DET',
    time: '21:38',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'KC',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'BAL',
    away: 'TEX',
    time: '18:35',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'LAA',
    away: 'DET',
    time: '21:38',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'SEA',
    away: 'MIN',
    time: '21:40',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'CWS',
    away: 'COL',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'KC',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'BAL',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'BOS',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'TB',
    away: 'WSH',
    time: '18:50',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'TOR',
    away: 'NYY',
    time: '19:07',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'LAA',
    away: 'DET',
    time: '22:07',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'SEA',
    away: 'MIN',
    time: '22:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'CWS',
    away: 'COL',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'KC',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'BAL',
    away: 'TEX',
    time: '19:15',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'BOS',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'TB',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'TOR',
    away: 'NYY',
    time: '15:07',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'LAA',
    away: 'DET',
    time: '16:07',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'SEA',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'CWS',
    away: 'COL',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'KC',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'BAL',
    away: 'TEX',
    time: '13:35',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'BOS',
    away: 'SD',
    time: '13:35',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'TB',
    away: 'WSH',
    time: '13:40',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'TOR',
    away: 'NYY',
    time: '13:37',
  },
  {
    week: 15,
    date: '2024-07-01',
    home: 'TOR',
    away: 'HOU',
    time: '15:07',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'OAK',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'SEA',
    away: 'BAL',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'TEX',
    away: 'SD',
    time: '20:05',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'CLE',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'KC',
    away: 'TB',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'MIN',
    away: 'DET',
    time: '19:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'NYY',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'TOR',
    away: 'HOU',
    time: '19:07',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'OAK',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'SEA',
    away: 'BAL',
    time: '22:10',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'TEX',
    away: 'SD',
    time: '20:05',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'CLE',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'KC',
    away: 'TB',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'MIN',
    away: 'DET',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'NYY',
    away: 'CIN',
    time: '19:05',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'TOR',
    away: 'HOU',
    time: '19:07',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'OAK',
    away: 'LAA',
    time: '16:07',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'SEA',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'TEX',
    away: 'SD',
    time: '14:35',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'CLE',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'KC',
    away: 'TB',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'MIN',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'NYY',
    away: 'CIN',
    time: '13:05',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'TOR',
    away: 'HOU',
    time: '13:07',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'OAK',
    away: 'BAL',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'SEA',
    away: 'TOR',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'TEX',
    away: 'TB',
    time: '20:05',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'CLE',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'MIN',
    away: 'HOU',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'NYY',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'OAK',
    away: 'BAL',
    time: '16:07',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'SEA',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'TEX',
    away: 'TB',
    time: '16:05',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'CLE',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'MIN',
    away: 'HOU',
    time: '14:10',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'NYY',
    away: 'BOS',
    time: '13:05',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'OAK',
    away: 'BAL',
    time: '16:07',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'SEA',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'TEX',
    away: 'TB',
    time: '14:35',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'CLE',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'MIN',
    away: 'HOU',
    time: '14:10',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'NYY',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'LAA',
    away: 'TEX',
    time: '21:38',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'CWS',
    away: 'MIN',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'HOU',
    away: 'MIA',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'LAA',
    away: 'TEX',
    time: '21:38',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'CWS',
    away: 'MIN',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'BAL',
    away: 'CHC',
    time: '18:35',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'BOS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'HOU',
    away: 'MIA',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'LAA',
    away: 'TEX',
    time: '21:38',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'CWS',
    away: 'MIN',
    time: '14:10',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'BAL',
    away: 'CHC',
    time: '18:35',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'BOS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'HOU',
    away: 'MIA',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'LAA',
    away: 'SEA',
    time: '21:38',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'DET',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'BAL',
    away: 'CHC',
    time: '18:35',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'BOS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'TB',
    away: 'NYY',
    time: '18:50',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'HOU',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'LAA',
    away: 'SEA',
    time: '21:38',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'CWS',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'DET',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'BAL',
    away: 'NYY',
    time: '19:05',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'BOS',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'TB',
    away: 'CLE',
    time: '18:50',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'HOU',
    away: 'TEX',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'LAA',
    away: 'SEA',
    time: '21:38',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'CWS',
    away: 'PIT',
    time: '14:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'DET',
    away: 'LAD',
    time: '13:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'BAL',
    away: 'NYY',
    time: '16:05',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'BOS',
    away: 'KC',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'TB',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'HOU',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'LAA',
    away: 'SEA',
    time: '16:07',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'CWS',
    away: 'PIT',
    time: '14:10',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'DET',
    away: 'LAD',
    time: '13:40',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'BAL',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'BOS',
    away: 'KC',
    time: '13:35',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'TB',
    away: 'CLE',
    time: '13:40',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'OAK',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'SEA',
    away: 'HOU',
    time: '22:10',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'TEX',
    away: 'BAL',
    time: '20:05',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'CLE',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'KC',
    away: 'CWS',
    time: '20:10',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'NYY',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'TOR',
    away: 'DET',
    time: '19:07',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'OAK',
    away: 'LAA',
    time: '16:07',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'SEA',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'TEX',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'CLE',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'KC',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'MIN',
    away: 'MIL',
    time: '19:10',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'NYY',
    away: 'TB',
    time: '13:05',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'TOR',
    away: 'DET',
    time: '15:07',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'OAK',
    away: 'LAA',
    time: '16:07',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'SEA',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'TEX',
    away: 'BAL',
    time: '14:35',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'CLE',
    away: 'SD',
    time: '13:40',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'KC',
    away: 'CWS',
    time: '14:10',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'MIN',
    away: 'MIL',
    time: '14:10',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'NYY',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'TOR',
    away: 'DET',
    time: '13:37',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'OAK',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'SEA',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'TEX',
    away: 'CWS',
    time: '20:05',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'CLE',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'KC',
    away: 'AZ',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'MIN',
    away: 'PHI',
    time: '19:40',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'NYY',
    away: 'TB',
    time: '13:05',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'OAK',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'SEA',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'TEX',
    away: 'CWS',
    time: '20:05',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'CLE',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'KC',
    away: 'AZ',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'MIN',
    away: 'PHI',
    time: '19:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'NYY',
    away: 'NYM',
    time: '19:05',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'TOR',
    away: 'TB',
    time: '19:07',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'OAK',
    away: 'HOU',
    time: '15:37',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'SEA',
    away: 'LAA',
    time: '15:40',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'TEX',
    away: 'CWS',
    time: '20:05',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'CLE',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'KC',
    away: 'AZ',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'MIN',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'NYY',
    away: 'NYM',
    time: '19:05',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'TOR',
    away: 'TB',
    time: '19:07',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'LAA',
    away: 'OAK',
    time: '21:38',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'TEX',
    away: 'CWS',
    time: '14:35',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'CLE',
    away: 'DET',
    time: '13:10',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'TOR',
    away: 'TB',
    time: '15:07',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'HOU',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'LAA',
    away: 'OAK',
    time: '21:38',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'CWS',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'DET',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'KC',
    away: 'CHC',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'BAL',
    away: 'SD',
    time: '19:05',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'BOS',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'TB',
    away: 'CIN',
    time: '18:50',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'TOR',
    away: 'TEX',
    time: '19:07',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'HOU',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'LAA',
    away: 'OAK',
    time: '21:38',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'CWS',
    away: 'SEA',
    time: '19:15',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'DET',
    away: 'MIN',
    time: '18:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'KC',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'BAL',
    away: 'SD',
    time: '16:05',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'BOS',
    away: 'NYY',
    time: '19:15',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'TB',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'TOR',
    away: 'TEX',
    time: '15:07',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'HOU',
    away: 'LAD',
    time: '14:10',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'LAA',
    away: 'OAK',
    time: '16:07',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'CWS',
    away: 'SEA',
    time: '14:10',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'DET',
    away: 'MIN',
    time: '13:40',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'KC',
    away: 'CHC',
    time: '14:10',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'BAL',
    away: 'SD',
    time: '13:35',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'BOS',
    away: 'NYY',
    time: '13:35',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'TB',
    away: 'CIN',
    time: '13:40',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'TOR',
    away: 'TEX',
    time: '13:37',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'HOU',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'CWS',
    away: 'KC',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'DET',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'BAL',
    away: 'TOR',
    time: '18:35',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'BOS',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'HOU',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'LAA',
    away: 'COL',
    time: '21:38',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'CWS',
    away: 'KC',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'DET',
    away: 'CLE',
    time: '13:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'BAL',
    away: 'TOR',
    time: '18:35',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'BOS',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'TB',
    away: 'MIA',
    time: '18:50',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'HOU',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'LAA',
    away: 'COL',
    time: '21:38',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'CWS',
    away: 'KC',
    time: '14:10',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'BAL',
    away: 'TOR',
    time: '12:35',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'BOS',
    away: 'SEA',
    time: '16:10',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'TB',
    away: 'MIA',
    time: '12:10',
  },
  {
    week: 19,
    date: '2024-08-01',
    home: 'LAA',
    away: 'COL',
    time: '21:38',
  },
  {
    week: 19,
    date: '2024-08-01',
    home: 'CLE',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-08-01',
    home: 'DET',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'HOU',
    away: 'TB',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'LAA',
    away: 'NYM',
    time: '21:38',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'OAK',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'SEA',
    away: 'PHI',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'TEX',
    away: 'BOS',
    time: '20:05',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'CLE',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'DET',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'MIN',
    away: 'CWS',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'NYY',
    away: 'TOR',
    time: '19:05',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'HOU',
    away: 'TB',
    time: '19:15',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'LAA',
    away: 'NYM',
    time: '21:38',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'OAK',
    away: 'LAD',
    time: '21:07',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'SEA',
    away: 'PHI',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'TEX',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'CLE',
    away: 'BAL',
    time: '19:15',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'DET',
    away: 'KC',
    time: '18:10',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'MIN',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'NYY',
    away: 'TOR',
    time: '13:05',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'HOU',
    away: 'TB',
    time: '14:10',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'LAA',
    away: 'NYM',
    time: '16:07',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'OAK',
    away: 'LAD',
    time: '16:07',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'SEA',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'TEX',
    away: 'BOS',
    time: '14:35',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'CLE',
    away: 'BAL',
    time: '13:40',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'DET',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'MIN',
    away: 'CWS',
    time: '14:10',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'NYY',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'OAK',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'TEX',
    away: 'HOU',
    time: '20:05',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'CLE',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'KC',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'OAK',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'SEA',
    away: 'DET',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'TEX',
    away: 'HOU',
    time: '20:05',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'CLE',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'KC',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'NYY',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'OAK',
    away: 'CWS',
    time: '15:37',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'SEA',
    away: 'DET',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'TEX',
    away: 'HOU',
    time: '14:35',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'CLE',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'KC',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'NYY',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'SEA',
    away: 'DET',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'NYY',
    away: 'LAA',
    time: '19:05',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'TOR',
    away: 'BAL',
    time: '19:07',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'SEA',
    away: 'NYM',
    time: '22:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'CWS',
    away: 'CHC',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'KC',
    away: 'STL',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'MIN',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'BOS',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'NYY',
    away: 'TEX',
    time: '19:05',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'TB',
    away: 'BAL',
    time: '18:50',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'TOR',
    away: 'OAK',
    time: '19:07',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'SEA',
    away: 'NYM',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'CWS',
    away: 'CHC',
    time: '19:15',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'KC',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'MIN',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'BOS',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'NYY',
    away: 'TEX',
    time: '13:05',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'TB',
    away: 'BAL',
    time: '19:15',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'TOR',
    away: 'OAK',
    time: '15:07',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'SEA',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'MIN',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'BOS',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'NYY',
    away: 'TEX',
    time: '13:35',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'TB',
    away: 'BAL',
    time: '13:40',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'TOR',
    away: 'OAK',
    time: '13:37',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'LAA',
    away: 'TOR',
    time: '21:38',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'CLE',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'CWS',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'MIN',
    away: 'KC',
    time: '19:40',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'BOS',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'TB',
    away: 'HOU',
    time: '18:50',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'LAA',
    away: 'TOR',
    time: '21:38',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'CLE',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'CWS',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'DET',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'MIN',
    away: 'KC',
    time: '19:40',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'BAL',
    away: 'WSH',
    time: '18:35',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'BOS',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'TB',
    away: 'HOU',
    time: '18:50',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'LAA',
    away: 'TOR',
    time: '21:38',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'CLE',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'CWS',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'DET',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'MIN',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'BAL',
    away: 'WSH',
    time: '18:35',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'BOS',
    away: 'TEX',
    time: '18:10',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'TB',
    away: 'HOU',
    time: '18:50',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'TEX',
    away: 'MIN',
    time: '20:05',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'DET',
    away: 'SEA',
    time: '13:10',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'BAL',
    away: 'BOS',
    time: '18:35',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'HOU',
    away: 'CWS',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'LAA',
    away: 'ATL',
    time: '21:38',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'TEX',
    away: 'MIN',
    time: '20:05',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'DET',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'BAL',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'TB',
    away: 'AZ',
    time: '18:50',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'HOU',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'LAA',
    away: 'ATL',
    time: '21:38',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'OAK',
    away: 'SF',
    time: '19:07',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'TEX',
    away: 'MIN',
    time: '19:05',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'DET',
    away: 'NYY',
    time: '13:10',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'BAL',
    away: 'BOS',
    time: '16:05',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'TB',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'HOU',
    away: 'CWS',
    time: '14:10',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'LAA',
    away: 'ATL',
    time: '16:07',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'OAK',
    away: 'SF',
    time: '16:07',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'TEX',
    away: 'MIN',
    time: '14:35',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'DET',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'BAL',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'TB',
    away: 'AZ',
    time: '13:40',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'HOU',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'OAK',
    away: 'TB',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'TEX',
    away: 'PIT',
    time: '20:05',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'KC',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'TOR',
    away: 'CIN',
    time: '19:07',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'HOU',
    away: 'BOS',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'OAK',
    away: 'TB',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'TEX',
    away: 'PIT',
    time: '20:05',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'KC',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'NYY',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'TOR',
    away: 'CIN',
    time: '19:07',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'HOU',
    away: 'BOS',
    time: '14:10',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'OAK',
    away: 'TB',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'TEX',
    away: 'PIT',
    time: '14:35',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'KC',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'NYY',
    away: 'CLE',
    time: '19:05',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'TOR',
    away: 'CIN',
    time: '19:07',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'OAK',
    away: 'TB',
    time: '15:37',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'BAL',
    away: 'HOU',
    time: '19:15',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'NYY',
    away: 'CLE',
    time: '13:05',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'TOR',
    away: 'LAA',
    time: '19:07',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'OAK',
    away: 'MIL',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'SEA',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'CLE',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'CWS',
    away: 'DET',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'KC',
    away: 'PHI',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'MIN',
    away: 'STL',
    time: '20:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'BAL',
    away: 'HOU',
    time: '19:05',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'BOS',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'NYY',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'TOR',
    away: 'LAA',
    time: '19:07',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'OAK',
    away: 'MIL',
    time: '16:07',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'SEA',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'CLE',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'CWS',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'KC',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'MIN',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'BAL',
    away: 'HOU',
    time: '16:05',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'BOS',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'NYY',
    away: 'COL',
    time: '14:05',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'TOR',
    away: 'LAA',
    time: '15:07',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'OAK',
    away: 'MIL',
    time: '16:07',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'SEA',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'CLE',
    away: 'TEX',
    time: '13:40',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'CWS',
    away: 'DET',
    time: '14:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'KC',
    away: 'PHI',
    time: '14:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'MIN',
    away: 'STL',
    time: '14:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'BAL',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'BOS',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'NYY',
    away: 'COL',
    time: '13:35',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'TOR',
    away: 'LAA',
    time: '13:37',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'SEA',
    away: 'TB',
    time: '21:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'CWS',
    away: 'DET',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'MIN',
    away: 'ATL',
    time: '19:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'SEA',
    away: 'TB',
    time: '21:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'CLE',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'CWS',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'DET',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'MIN',
    away: 'ATL',
    time: '19:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'SEA',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'CLE',
    away: 'KC',
    time: '13:10',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'CWS',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'DET',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'MIN',
    away: 'ATL',
    time: '19:40',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'HOU',
    away: 'KC',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'CWS',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'DET',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'BOS',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'HOU',
    away: 'KC',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'LAA',
    away: 'SEA',
    time: '21:38',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'TEX',
    away: 'OAK',
    time: '20:05',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'CLE',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'CWS',
    away: 'NYM',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'DET',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'MIN',
    away: 'TOR',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'NYY',
    away: 'STL',
    time: '19:05',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'TB',
    away: 'SD',
    time: '18:50',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'HOU',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'LAA',
    away: 'SEA',
    time: '21:38',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'TEX',
    away: 'OAK',
    time: '19:05',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'CLE',
    away: 'PIT',
    time: '18:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'CWS',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'DET',
    away: 'BOS',
    time: '18:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'MIN',
    away: 'TOR',
    time: '19:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'NYY',
    away: 'STL',
    time: '13:05',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'TB',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'HOU',
    away: 'KC',
    time: '14:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'LAA',
    away: 'SEA',
    time: '16:07',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'TEX',
    away: 'OAK',
    time: '14:35',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'CLE',
    away: 'PIT',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'CWS',
    away: 'NYM',
    time: '14:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'DET',
    away: 'BOS',
    time: '13:40',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'MIN',
    away: 'TOR',
    time: '14:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'NYY',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'TB',
    away: 'SD',
    time: '13:40',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'OAK',
    away: 'SEA',
    time: '19:07',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'TEX',
    away: 'NYY',
    time: '20:05',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'KC',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'BAL',
    away: 'CWS',
    time: '13:05',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'TB',
    away: 'MIN',
    time: '18:50',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'LAA',
    away: 'LAD',
    time: '21:38',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'OAK',
    away: 'SEA',
    time: '21:40',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'TEX',
    away: 'NYY',
    time: '20:05',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'KC',
    away: 'CLE',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'BAL',
    away: 'CWS',
    time: '18:35',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'TB',
    away: 'MIN',
    time: '18:50',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'TOR',
    away: 'PHI',
    time: '19:07',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'LAA',
    away: 'LAD',
    time: '21:38',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'OAK',
    away: 'SEA',
    time: '21:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'TEX',
    away: 'NYY',
    time: '20:05',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'KC',
    away: 'CLE',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'BAL',
    away: 'CWS',
    time: '18:35',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'TB',
    away: 'MIN',
    time: '18:50',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'TOR',
    away: 'PHI',
    time: '15:07',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'OAK',
    away: 'SEA',
    time: '15:37',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'TEX',
    away: 'LAA',
    time: '20:05',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'TB',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'HOU',
    away: 'AZ',
    time: '20:10',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'OAK',
    away: 'DET',
    time: '21:40',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'TEX',
    away: 'LAA',
    time: '20:05',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'KC',
    away: 'MIN',
    time: '20:10',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'BAL',
    away: 'TB',
    time: '19:05',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'BOS',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'HOU',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'OAK',
    away: 'DET',
    time: '16:07',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'TEX',
    away: 'LAA',
    time: '19:15',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'KC',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'BAL',
    away: 'TB',
    time: '16:05',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'BOS',
    away: 'CWS',
    time: '19:15',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'HOU',
    away: 'AZ',
    time: '14:10',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'OAK',
    away: 'DET',
    time: '16:07',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'TEX',
    away: 'LAA',
    time: '14:35',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'KC',
    away: 'MIN',
    time: '14:10',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'BAL',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'BOS',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'CWS',
    away: 'CLE',
    time: '19:40',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'MIN',
    away: 'LAA',
    time: '19:40',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'NYY',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'TOR',
    away: 'NYM',
    time: '19:07',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'HOU',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'SEA',
    away: 'SD',
    time: '21:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'CWS',
    away: 'CLE',
    time: '19:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'DET',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'MIN',
    away: 'LAA',
    time: '19:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'NYY',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'TOR',
    away: 'NYM',
    time: '19:07',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'HOU',
    away: 'OAK',
    time: '20:10',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'SEA',
    away: 'SD',
    time: '21:40',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'CWS',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'DET',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'MIN',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'BOS',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'NYY',
    away: 'KC',
    time: '19:05',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'TOR',
    away: 'NYM',
    time: '15:07',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'HOU',
    away: 'OAK',
    time: '14:10',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'SEA',
    away: 'TEX',
    time: '21:40',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'CLE',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'DET',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'NYY',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'LAA',
    away: 'HOU',
    time: '21:38',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'SEA',
    away: 'TEX',
    time: '22:10',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'CLE',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'CWS',
    away: 'OAK',
    time: '19:40',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'DET',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'MIN',
    away: 'CIN',
    time: '20:10',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'NYY',
    away: 'BOS',
    time: '19:05',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'TOR',
    away: 'STL',
    time: '19:07',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'LAA',
    away: 'HOU',
    time: '21:38',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'SEA',
    away: 'TEX',
    time: '21:40',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'CLE',
    away: 'TB',
    time: '18:10',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'CWS',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'DET',
    away: 'BAL',
    time: '18:10',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'MIN',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'NYY',
    away: 'BOS',
    time: '13:05',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'TOR',
    away: 'STL',
    time: '15:07',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'LAA',
    away: 'HOU',
    time: '16:07',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'SEA',
    away: 'TEX',
    time: '16:10',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'CLE',
    away: 'TB',
    time: '13:40',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'CWS',
    away: 'OAK',
    time: '14:10',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'DET',
    away: 'BAL',
    time: '13:40',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'MIN',
    away: 'CIN',
    time: '14:10',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'NYY',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'TOR',
    away: 'STL',
    time: '13:37',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'LAA',
    away: 'CWS',
    time: '21:38',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'CLE',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'KC',
    away: 'DET',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'LAA',
    away: 'CWS',
    time: '21:38',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'SEA',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'TEX',
    away: 'TOR',
    time: '20:05',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'CLE',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'KC',
    away: 'DET',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'BAL',
    away: 'SF',
    time: '18:35',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'LAA',
    away: 'CWS',
    time: '16:07',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'SEA',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'TEX',
    away: 'TOR',
    time: '20:05',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'CLE',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'KC',
    away: 'DET',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'BAL',
    away: 'SF',
    time: '18:35',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'HOU',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'SEA',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'TEX',
    away: 'TOR',
    time: '14:35',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'CLE',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'BAL',
    away: 'SF',
    time: '13:05',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'TB',
    away: 'BOS',
    time: '18:50',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'HOU',
    away: 'LAA',
    time: '20:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'OAK',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'TEX',
    away: 'SEA',
    time: '20:05',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'KC',
    away: 'SF',
    time: '20:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'BAL',
    away: 'DET',
    time: '19:05',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'BOS',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'TB',
    away: 'TOR',
    time: '18:50',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'HOU',
    away: 'LAA',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'OAK',
    away: 'NYY',
    time: '21:07',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'TEX',
    away: 'SEA',
    time: '19:05',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'KC',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'BAL',
    away: 'DET',
    time: '16:05',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'BOS',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'TB',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'HOU',
    away: 'LAA',
    time: '14:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'OAK',
    away: 'NYY',
    time: '16:07',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'TEX',
    away: 'SEA',
    time: '14:35',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'KC',
    away: 'SF',
    time: '14:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'BAL',
    away: 'DET',
    time: '13:35',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'BOS',
    away: 'MIN',
    time: '13:35',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'TB',
    away: 'TOR',
    time: '13:40',
  },
  {
    week: 27,
    date: '2024-09-23',
    home: 'HOU',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-23',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'HOU',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'OAK',
    away: 'TEX',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'CLE',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'CWS',
    away: 'LAA',
    time: '19:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'DET',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'MIN',
    away: 'MIA',
    time: '19:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'HOU',
    away: 'SEA',
    time: '14:10',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'OAK',
    away: 'TEX',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'CLE',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'CWS',
    away: 'LAA',
    time: '19:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'DET',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'MIN',
    away: 'MIA',
    time: '19:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'TOR',
    away: 'BOS',
    time: '19:07',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'OAK',
    away: 'TEX',
    time: '15:37',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'CWS',
    away: 'LAA',
    time: '14:10',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'DET',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'MIN',
    away: 'MIA',
    time: '19:40',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'NYY',
    away: 'BAL',
    time: '19:05',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'LAA',
    away: 'TEX',
    time: '21:38',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'SEA',
    away: 'OAK',
    time: '22:10',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'CLE',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'DET',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'MIN',
    away: 'BAL',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'BOS',
    away: 'TB',
    time: '19:10',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'NYY',
    away: 'PIT',
    time: '19:05',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'TOR',
    away: 'MIA',
    time: '19:07',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'LAA',
    away: 'TEX',
    time: '21:38',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'SEA',
    away: 'OAK',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'CLE',
    away: 'HOU',
    time: '18:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'DET',
    away: 'CWS',
    time: '13:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'MIN',
    away: 'BAL',
    time: '14:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'BOS',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'NYY',
    away: 'PIT',
    time: '13:05',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'TOR',
    away: 'MIA',
    time: '15:07',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'LAA',
    away: 'TEX',
    time: '15:07',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'SEA',
    away: 'OAK',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'CLE',
    away: 'HOU',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'DET',
    away: 'CWS',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'MIN',
    away: 'BAL',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'BOS',
    away: 'TB',
    time: '15:05',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'NYY',
    away: 'PIT',
    time: '15:05',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'TOR',
    away: 'MIA',
    time: '15:07',
  },
];

export const MLB_2024_NL_SCHEDULE: MlbSchedule = [
  {
    week: 0,
    date: '2024-03-20',
    home: 'SD',
    away: 'LAD',
    time: '03:05',
  },
  {
    week: 0,
    date: '2024-03-21',
    home: 'LAD',
    away: 'SD',
    time: '03:05',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'AZ',
    away: 'COL',
    time: '22:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'LAD',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'SD',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'CIN',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'MIA',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'NYM',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 1,
    date: '2024-03-28',
    home: 'PHI',
    away: 'ATL',
    time: '15:05',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'AZ',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'LAD',
    away: 'STL',
    time: '22:10',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'SD',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 1,
    date: '2024-03-29',
    home: 'MIA',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'AZ',
    away: 'COL',
    time: '20:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'LAD',
    away: 'STL',
    time: '21:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'SD',
    away: 'SF',
    time: '19:15',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'CIN',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'MIA',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'NYM',
    away: 'MIL',
    time: '13:40',
  },
  {
    week: 1,
    date: '2024-03-30',
    home: 'PHI',
    away: 'ATL',
    time: '16:05',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'AZ',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'LAD',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'SD',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'CIN',
    away: 'WSH',
    time: '13:40',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'MIA',
    away: 'PIT',
    time: '13:40',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'NYM',
    away: 'MIL',
    time: '13:40',
  },
  {
    week: 1,
    date: '2024-03-31',
    home: 'PHI',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'AZ',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'LAD',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'SD',
    away: 'STL',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'CHC',
    away: 'COL',
    time: '14:20',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'MIA',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'NYM',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'PHI',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 2,
    date: '2024-04-01',
    home: 'WSH',
    away: 'PIT',
    time: '16:05',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'AZ',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'LAD',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'SD',
    away: 'STL',
    time: '21:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'CHC',
    away: 'COL',
    time: '19:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'MIL',
    away: 'MIN',
    time: '16:10',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'MIA',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'NYM',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 2,
    date: '2024-04-02',
    home: 'PHI',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'AZ',
    away: 'NYY',
    time: '15:40',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'LAD',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'SD',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'CHC',
    away: 'COL',
    time: '19:40',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'MIL',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'MIA',
    away: 'LAA',
    time: '13:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'NYM',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'PHI',
    away: 'CIN',
    time: '13:05',
  },
  {
    week: 2,
    date: '2024-04-03',
    home: 'WSH',
    away: 'PIT',
    time: '18:45',
  },
  {
    week: 2,
    date: '2024-04-04',
    home: 'STL',
    away: 'MIA',
    time: '16:15',
  },
  {
    week: 2,
    date: '2024-04-04',
    home: 'WSH',
    away: 'PIT',
    time: '16:05',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'COL',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'SF',
    away: 'SD',
    time: '16:35',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'CHC',
    away: 'LAD',
    time: '14:20',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'CIN',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'MIL',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'PIT',
    away: 'BAL',
    time: '16:12',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'ATL',
    away: 'AZ',
    time: '19:20',
  },
  {
    week: 2,
    date: '2024-04-05',
    home: 'WSH',
    away: 'PHI',
    time: '18:45',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'COL',
    away: 'TB',
    time: '20:10',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'SF',
    away: 'SD',
    time: '21:05',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'CHC',
    away: 'LAD',
    time: '16:05',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'CIN',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'MIL',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'PIT',
    away: 'BAL',
    time: '16:05',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'STL',
    away: 'MIA',
    time: '14:15',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'ATL',
    away: 'AZ',
    time: '19:20',
  },
  {
    week: 2,
    date: '2024-04-06',
    home: 'WSH',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'COL',
    away: 'TB',
    time: '15:10',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'SF',
    away: 'SD',
    time: '16:05',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'CHC',
    away: 'LAD',
    time: '14:20',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'CIN',
    away: 'NYM',
    time: '13:40',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'MIL',
    away: 'SEA',
    time: '14:10',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'PIT',
    away: 'BAL',
    time: '13:35',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'STL',
    away: 'MIA',
    time: '14:15',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'ATL',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 2,
    date: '2024-04-07',
    home: 'WSH',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'COL',
    away: 'AZ',
    time: '20:40',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'SD',
    away: 'CHC',
    time: '21:40',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'SF',
    away: 'WSH',
    time: '21:45',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'CIN',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'PIT',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'STL',
    away: 'PHI',
    time: '19:45',
  },
  {
    week: 3,
    date: '2024-04-08',
    home: 'ATL',
    away: 'NYM',
    time: '19:20',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'COL',
    away: 'AZ',
    time: '20:40',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'SD',
    away: 'CHC',
    time: '22:05',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'SF',
    away: 'WSH',
    time: '21:45',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'CIN',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'PIT',
    away: 'DET',
    time: '12:35',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'STL',
    away: 'PHI',
    time: '19:45',
  },
  {
    week: 3,
    date: '2024-04-09',
    home: 'ATL',
    away: 'NYM',
    time: '19:20',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'COL',
    away: 'AZ',
    time: '15:10',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'SD',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'SF',
    away: 'WSH',
    time: '15:45',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'CIN',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'STL',
    away: 'PHI',
    time: '13:15',
  },
  {
    week: 3,
    date: '2024-04-10',
    home: 'ATL',
    away: 'NYM',
    time: '19:20',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'CIN',
    away: 'MIL',
    time: '13:10',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'ATL',
    away: 'NYM',
    time: '12:20',
  },
  {
    week: 3,
    date: '2024-04-11',
    home: 'PHI',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'AZ',
    away: 'STL',
    time: '21:40',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'LAD',
    away: 'SD',
    time: '22:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'MIA',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'NYM',
    away: 'KC',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-12',
    home: 'PHI',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'AZ',
    away: 'STL',
    time: '20:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'LAD',
    away: 'SD',
    time: '21:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'MIA',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'NYM',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 3,
    date: '2024-04-13',
    home: 'PHI',
    away: 'PIT',
    time: '16:05',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'AZ',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'LAD',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'MIA',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'NYM',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 3,
    date: '2024-04-14',
    home: 'PHI',
    away: 'PIT',
    time: '13:35',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'AZ',
    away: 'CHC',
    time: '21:40',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'LAD',
    away: 'WSH',
    time: '22:10',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'MIL',
    away: 'SD',
    time: '19:40',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'MIA',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'NYM',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-15',
    home: 'PHI',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'AZ',
    away: 'CHC',
    time: '21:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'LAD',
    away: 'WSH',
    time: '22:10',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'MIL',
    away: 'SD',
    time: '19:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'MIA',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'NYM',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-16',
    home: 'PHI',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'AZ',
    away: 'CHC',
    time: '15:40',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'LAD',
    away: 'WSH',
    time: '15:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'MIL',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'MIA',
    away: 'SF',
    time: '12:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'NYM',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 4,
    date: '2024-04-17',
    home: 'PHI',
    away: 'COL',
    time: '18:05',
  },
  {
    week: 4,
    date: '2024-04-18',
    home: 'SF',
    away: 'AZ',
    time: '21:45',
  },
  {
    week: 4,
    date: '2024-04-18',
    home: 'CHC',
    away: 'MIA',
    time: '19:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'COL',
    away: 'SEA',
    time: '20:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'LAD',
    away: 'NYM',
    time: '22:10',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'SD',
    away: 'TOR',
    time: '21:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'SF',
    away: 'AZ',
    time: '22:15',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'CHC',
    away: 'MIA',
    time: '14:20',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'CIN',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'PIT',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'STL',
    away: 'MIL',
    time: '20:15',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'ATL',
    away: 'TEX',
    time: '19:20',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'PHI',
    away: 'CWS',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-19',
    home: 'WSH',
    away: 'HOU',
    time: '18:45',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'COL',
    away: 'SEA',
    time: '20:10',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'LAD',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'SD',
    away: 'TOR',
    time: '20:40',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'SF',
    away: 'AZ',
    time: '16:05',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'CHC',
    away: 'MIA',
    time: '14:20',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'CIN',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'PIT',
    away: 'BOS',
    time: '16:05',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'STL',
    away: 'MIL',
    time: '14:15',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'ATL',
    away: 'TEX',
    time: '19:20',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'PHI',
    away: 'CWS',
    time: '18:05',
  },
  {
    week: 4,
    date: '2024-04-20',
    home: 'WSH',
    away: 'HOU',
    time: '16:05',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'COL',
    away: 'SEA',
    time: '15:10',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'LAD',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'SD',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'SF',
    away: 'AZ',
    time: '16:05',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'CHC',
    away: 'MIA',
    time: '14:20',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'CIN',
    away: 'LAA',
    time: '13:40',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'PIT',
    away: 'BOS',
    time: '13:35',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'STL',
    away: 'MIL',
    time: '14:15',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'ATL',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'PHI',
    away: 'CWS',
    time: '13:35',
  },
  {
    week: 4,
    date: '2024-04-21',
    home: 'WSH',
    away: 'HOU',
    time: '13:35',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'COL',
    away: 'SD',
    time: '20:40',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'SF',
    away: 'NYM',
    time: '21:45',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'CIN',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'STL',
    away: 'AZ',
    time: '19:45',
  },
  {
    week: 5,
    date: '2024-04-22',
    home: 'ATL',
    away: 'MIA',
    time: '19:20',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'COL',
    away: 'SD',
    time: '20:40',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'SF',
    away: 'NYM',
    time: '21:45',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'CHC',
    away: 'HOU',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'CIN',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'STL',
    away: 'AZ',
    time: '19:45',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'ATL',
    away: 'MIA',
    time: '19:20',
  },
  {
    week: 5,
    date: '2024-04-23',
    home: 'WSH',
    away: 'LAD',
    time: '18:45',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'COL',
    away: 'SD',
    time: '20:40',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'SF',
    away: 'NYM',
    time: '15:45',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'CHC',
    away: 'HOU',
    time: '19:40',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'CIN',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'STL',
    away: 'AZ',
    time: '13:15',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'ATL',
    away: 'MIA',
    time: '19:20',
  },
  {
    week: 5,
    date: '2024-04-24',
    home: 'WSH',
    away: 'LAD',
    time: '18:45',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'COL',
    away: 'SD',
    time: '15:10',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'CHC',
    away: 'HOU',
    time: '14:20',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'CIN',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'PIT',
    away: 'MIL',
    time: '12:35',
  },
  {
    week: 5,
    date: '2024-04-25',
    home: 'WSH',
    away: 'LAD',
    time: '16:05',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'SD',
    away: 'PHI',
    time: '21:40',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'SF',
    away: 'PIT',
    time: '22:15',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'MIL',
    away: 'NYY',
    time: '20:10',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'ATL',
    away: 'CLE',
    time: '19:20',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'MIA',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 5,
    date: '2024-04-26',
    home: 'NYM',
    away: 'STL',
    time: '19:10',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'COL',
    away: 'HOU',
    time: '18:05',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'SD',
    away: 'PHI',
    time: '20:40',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'SF',
    away: 'PIT',
    time: '21:05',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'MIL',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'ATL',
    away: 'CLE',
    time: '19:20',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'MIA',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 5,
    date: '2024-04-27',
    home: 'NYM',
    away: 'STL',
    time: '16:05',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'COL',
    away: 'HOU',
    time: '16:05',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'SD',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'SF',
    away: 'PIT',
    time: '16:05',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'MIL',
    away: 'NYY',
    time: '14:10',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'ATL',
    away: 'CLE',
    time: '13:35',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'MIA',
    away: 'WSH',
    time: '13:40',
  },
  {
    week: 5,
    date: '2024-04-28',
    home: 'NYM',
    away: 'STL',
    time: '13:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'AZ',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'SD',
    away: 'CIN',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'MIL',
    away: 'TB',
    time: '19:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'MIA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-04-29',
    home: 'NYM',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'AZ',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'SD',
    away: 'CIN',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'MIL',
    away: 'TB',
    time: '19:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'MIA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-04-30',
    home: 'NYM',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'AZ',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'SD',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'MIL',
    away: 'TB',
    time: '13:10',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'MIA',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-05-01',
    home: 'NYM',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-05-02',
    home: 'MIA',
    away: 'COL',
    time: '12:10',
  },
  {
    week: 6,
    date: '2024-05-02',
    home: 'NYM',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'AZ',
    away: 'SD',
    time: '21:40',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'LAD',
    away: 'ATL',
    time: '22:10',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'CHC',
    away: 'MIL',
    time: '14:20',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'CIN',
    away: 'BAL',
    time: '18:10',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'PIT',
    away: 'COL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'STL',
    away: 'CWS',
    time: '20:15',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'PHI',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-05-03',
    home: 'WSH',
    away: 'TOR',
    time: '18:45',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'AZ',
    away: 'SD',
    time: '20:10',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'LAD',
    away: 'ATL',
    time: '21:10',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'CHC',
    away: 'MIL',
    time: '14:20',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'CIN',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'PIT',
    away: 'COL',
    time: '16:05',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'STL',
    away: 'CWS',
    time: '14:15',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'PHI',
    away: 'SF',
    time: '18:05',
  },
  {
    week: 6,
    date: '2024-05-04',
    home: 'WSH',
    away: 'TOR',
    time: '16:05',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'AZ',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'LAD',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'CHC',
    away: 'MIL',
    time: '14:20',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'CIN',
    away: 'BAL',
    time: '16:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'PIT',
    away: 'COL',
    time: '13:35',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'STL',
    away: 'CWS',
    time: '14:15',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'PHI',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 6,
    date: '2024-05-05',
    home: 'WSH',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'LAD',
    away: 'MIA',
    time: '22:10',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'CHC',
    away: 'SD',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'PIT',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'STL',
    away: 'NYM',
    time: '19:45',
  },
  {
    week: 7,
    date: '2024-05-06',
    home: 'PHI',
    away: 'SF',
    time: '16:05',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'COL',
    away: 'SF',
    time: '20:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'LAD',
    away: 'MIA',
    time: '22:10',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'CHC',
    away: 'SD',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'CIN',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'PIT',
    away: 'LAA',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'STL',
    away: 'NYM',
    time: '19:45',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'ATL',
    away: 'BOS',
    time: '19:20',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'PHI',
    away: 'TOR',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-07',
    home: 'WSH',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'COL',
    away: 'SF',
    time: '20:40',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'LAD',
    away: 'MIA',
    time: '15:10',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'CHC',
    away: 'SD',
    time: '14:20',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'CIN',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'PIT',
    away: 'LAA',
    time: '12:35',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'STL',
    away: 'NYM',
    time: '13:15',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'ATL',
    away: 'BOS',
    time: '19:20',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'PHI',
    away: 'TOR',
    time: '13:05',
  },
  {
    week: 7,
    date: '2024-05-08',
    home: 'WSH',
    away: 'BAL',
    time: '18:45',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'COL',
    away: 'SF',
    time: '15:10',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'CIN',
    away: 'AZ',
    time: '13:10',
  },
  {
    week: 7,
    date: '2024-05-09',
    home: 'MIL',
    away: 'STL',
    time: '19:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'COL',
    away: 'TEX',
    time: '20:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'SD',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'SF',
    away: 'CIN',
    time: '22:15',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'MIL',
    away: 'STL',
    time: '20:10',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'MIA',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 7,
    date: '2024-05-10',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'COL',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'SD',
    away: 'LAD',
    time: '20:40',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'SF',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'MIL',
    away: 'STL',
    time: '19:15',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'PIT',
    away: 'CHC',
    time: '16:05',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'MIA',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 7,
    date: '2024-05-11',
    home: 'NYM',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'COL',
    away: 'TEX',
    time: '15:10',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'SD',
    away: 'LAD',
    time: '16:10',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'SF',
    away: 'CIN',
    time: '16:05',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'MIL',
    away: 'STL',
    time: '14:10',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'PIT',
    away: 'CHC',
    time: '13:35',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'MIA',
    away: 'PHI',
    time: '13:40',
  },
  {
    week: 7,
    date: '2024-05-12',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'AZ',
    away: 'CIN',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'SD',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'SF',
    away: 'LAD',
    time: '21:45',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'MIL',
    away: 'PIT',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'ATL',
    away: 'CHC',
    time: '19:20',
  },
  {
    week: 8,
    date: '2024-05-13',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'AZ',
    away: 'CIN',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'SD',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'SF',
    away: 'LAD',
    time: '21:45',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'MIL',
    away: 'PIT',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'ATL',
    away: 'CHC',
    time: '19:20',
  },
  {
    week: 8,
    date: '2024-05-14',
    home: 'NYM',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'AZ',
    away: 'CIN',
    time: '15:40',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'SD',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'SF',
    away: 'LAD',
    time: '21:45',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'MIL',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'ATL',
    away: 'CHC',
    time: '19:20',
  },
  {
    week: 8,
    date: '2024-05-15',
    home: 'PHI',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 8,
    date: '2024-05-16',
    home: 'LAD',
    away: 'CIN',
    time: '22:10',
  },
  {
    week: 8,
    date: '2024-05-16',
    home: 'CHC',
    away: 'PIT',
    time: '19:40',
  },
  {
    week: 8,
    date: '2024-05-16',
    home: 'PHI',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'AZ',
    away: 'DET',
    time: '21:40',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'LAD',
    away: 'CIN',
    time: '22:10',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'SF',
    away: 'COL',
    time: '22:15',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'CHC',
    away: 'PIT',
    time: '14:20',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'STL',
    away: 'BOS',
    time: '20:15',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'ATL',
    away: 'SD',
    time: '19:20',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'MIA',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-17',
    home: 'PHI',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'AZ',
    away: 'DET',
    time: '20:10',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'LAD',
    away: 'CIN',
    time: '21:10',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'SF',
    away: 'COL',
    time: '16:05',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'CHC',
    away: 'PIT',
    time: '14:20',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'STL',
    away: 'BOS',
    time: '19:15',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'ATL',
    away: 'SD',
    time: '19:15',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'MIA',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 8,
    date: '2024-05-18',
    home: 'PHI',
    away: 'WSH',
    time: '18:05',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'AZ',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'LAD',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'SF',
    away: 'COL',
    time: '16:05',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'CHC',
    away: 'PIT',
    time: '14:20',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'STL',
    away: 'BOS',
    time: '14:15',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'ATL',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'MIA',
    away: 'NYM',
    time: '13:40',
  },
  {
    week: 8,
    date: '2024-05-19',
    home: 'PHI',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'LAD',
    away: 'AZ',
    time: '22:10',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'STL',
    away: 'BAL',
    time: '19:45',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'ATL',
    away: 'SD',
    time: '12:20',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'MIA',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-20',
    home: 'WSH',
    away: 'MIN',
    time: '18:45',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'LAD',
    away: 'AZ',
    time: '22:10',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'CHC',
    away: 'ATL',
    time: '19:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'CIN',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'PIT',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'STL',
    away: 'BAL',
    time: '19:45',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'MIA',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'PHI',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-21',
    home: 'WSH',
    away: 'MIN',
    time: '18:45',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'LAD',
    away: 'AZ',
    time: '22:10',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'CHC',
    away: 'ATL',
    time: '19:40',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'CIN',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'PIT',
    away: 'SF',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'STL',
    away: 'BAL',
    time: '13:15',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'MIA',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'PHI',
    away: 'TEX',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-22',
    home: 'WSH',
    away: 'MIN',
    time: '13:05',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'CHC',
    away: 'ATL',
    time: '14:20',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'CIN',
    away: 'SD',
    time: '13:10',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'PIT',
    away: 'SF',
    time: '12:35',
  },
  {
    week: 9,
    date: '2024-05-23',
    home: 'PHI',
    away: 'TEX',
    time: '13:05',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'AZ',
    away: 'MIA',
    time: '21:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'COL',
    away: 'PHI',
    time: '20:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'SD',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'CIN',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'PIT',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'STL',
    away: 'CHC',
    time: '20:15',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'NYM',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 9,
    date: '2024-05-24',
    home: 'WSH',
    away: 'SEA',
    time: '18:45',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'AZ',
    away: 'MIA',
    time: '22:10',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'COL',
    away: 'PHI',
    time: '21:10',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'SD',
    away: 'NYY',
    time: '21:40',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'CIN',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'PIT',
    away: 'ATL',
    time: '16:05',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'STL',
    away: 'CHC',
    time: '19:15',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'NYM',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 9,
    date: '2024-05-25',
    home: 'WSH',
    away: 'SEA',
    time: '16:05',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'AZ',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'COL',
    away: 'PHI',
    time: '15:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'SD',
    away: 'NYY',
    time: '16:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'CIN',
    away: 'LAD',
    time: '13:40',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'PIT',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'STL',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'NYM',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 9,
    date: '2024-05-26',
    home: 'WSH',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'COL',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'SD',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'SF',
    away: 'PHI',
    time: '17:05',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'CIN',
    away: 'STL',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'MIL',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'ATL',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-27',
    home: 'NYM',
    away: 'LAD',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'COL',
    away: 'CLE',
    time: '20:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'SD',
    away: 'MIA',
    time: '21:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'SF',
    away: 'PHI',
    time: '21:45',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'MIL',
    away: 'CHC',
    time: '19:40',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'ATL',
    away: 'WSH',
    time: '19:20',
  },
  {
    week: 10,
    date: '2024-05-28',
    home: 'NYM',
    away: 'LAD',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'COL',
    away: 'CLE',
    time: '20:40',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'SD',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'SF',
    away: 'PHI',
    time: '15:45',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'CIN',
    away: 'STL',
    time: '13:10',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'MIL',
    away: 'CHC',
    time: '19:40',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'ATL',
    away: 'WSH',
    time: '19:20',
  },
  {
    week: 10,
    date: '2024-05-29',
    home: 'NYM',
    away: 'LAD',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'MIL',
    away: 'CHC',
    time: '13:10',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'ATL',
    away: 'WSH',
    time: '19:20',
  },
  {
    week: 10,
    date: '2024-05-30',
    home: 'NYM',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'LAD',
    away: 'COL',
    time: '22:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'SF',
    away: 'NYY',
    time: '22:15',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'CHC',
    away: 'CIN',
    time: '14:20',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'MIL',
    away: 'CWS',
    time: '20:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'ATL',
    away: 'OAK',
    time: '19:20',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'MIA',
    away: 'TEX',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'NYM',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 10,
    date: '2024-05-31',
    home: 'PHI',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'LAD',
    away: 'COL',
    time: '22:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'SF',
    away: 'NYY',
    time: '22:05',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'CHC',
    away: 'CIN',
    time: '19:15',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'MIL',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'ATL',
    away: 'OAK',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'MIA',
    away: 'TEX',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'NYM',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-01',
    home: 'PHI',
    away: 'STL',
    time: '19:15',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'LAD',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'SF',
    away: 'NYY',
    time: '16:05',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'CHC',
    away: 'CIN',
    time: '14:20',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'MIL',
    away: 'CWS',
    time: '14:10',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'ATL',
    away: 'OAK',
    time: '13:35',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'MIA',
    away: 'TEX',
    time: '13:40',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'NYM',
    away: 'AZ',
    time: '13:40',
  },
  {
    week: 10,
    date: '2024-06-02',
    home: 'PHI',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'AZ',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'COL',
    away: 'CIN',
    time: '20:40',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'PHI',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-03',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'AZ',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'COL',
    away: 'CIN',
    time: '20:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'CHC',
    away: 'CWS',
    time: '20:05',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'PIT',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'MIA',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'PHI',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-04',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'AZ',
    away: 'SF',
    time: '15:40',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'COL',
    away: 'CIN',
    time: '15:10',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'CHC',
    away: 'CWS',
    time: '20:05',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'PIT',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'MIA',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'PHI',
    away: 'MIL',
    time: '16:05',
  },
  {
    week: 11,
    date: '2024-06-05',
    home: 'WSH',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'SD',
    away: 'AZ',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'CIN',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'PIT',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'STL',
    away: 'COL',
    time: '19:45',
  },
  {
    week: 11,
    date: '2024-06-06',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'SD',
    away: 'AZ',
    time: '21:40',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'CIN',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'PIT',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'STL',
    away: 'COL',
    time: '20:15',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'MIA',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 11,
    date: '2024-06-07',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'SD',
    away: 'AZ',
    time: '20:40',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'CIN',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'PIT',
    away: 'MIN',
    time: '16:05',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'STL',
    away: 'COL',
    time: '16:15',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'MIA',
    away: 'CLE',
    time: '19:35',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'NYM',
    away: 'PHI',
    time: '13:10',
  },
  {
    week: 11,
    date: '2024-06-08',
    home: 'WSH',
    away: 'ATL',
    time: '16:05',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'SD',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'CIN',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'PIT',
    away: 'MIN',
    time: '13:35',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'STL',
    away: 'COL',
    time: '14:15',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'MIA',
    away: 'CLE',
    time: '13:40',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'PHI',
    away: 'NYM',
    time: '10:10',
  },
  {
    week: 11,
    date: '2024-06-09',
    home: 'WSH',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'SD',
    away: 'OAK',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'SF',
    away: 'HOU',
    time: '21:45',
  },
  {
    week: 12,
    date: '2024-06-10',
    home: 'MIL',
    away: 'TOR',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'AZ',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'LAD',
    away: 'TEX',
    time: '22:10',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'SD',
    away: 'OAK',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'SF',
    away: 'HOU',
    time: '21:45',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'CIN',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'MIL',
    away: 'TOR',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'STL',
    away: 'PIT',
    time: '19:45',
  },
  {
    week: 12,
    date: '2024-06-11',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'AZ',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'LAD',
    away: 'TEX',
    time: '22:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'SD',
    away: 'OAK',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'SF',
    away: 'HOU',
    time: '15:45',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'CIN',
    away: 'CLE',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'MIL',
    away: 'TOR',
    time: '14:10',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'STL',
    away: 'PIT',
    time: '19:45',
  },
  {
    week: 12,
    date: '2024-06-12',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'AZ',
    away: 'LAA',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'LAD',
    away: 'TEX',
    time: '22:10',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'STL',
    away: 'PIT',
    time: '14:15',
  },
  {
    week: 12,
    date: '2024-06-13',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'AZ',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'COL',
    away: 'PIT',
    time: '20:40',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'LAD',
    away: 'KC',
    time: '22:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'SF',
    away: 'LAA',
    time: '22:15',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'MIL',
    away: 'CIN',
    time: '20:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'ATL',
    away: 'TB',
    time: '19:20',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'NYM',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 12,
    date: '2024-06-14',
    home: 'WSH',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'AZ',
    away: 'CWS',
    time: '22:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'COL',
    away: 'PIT',
    time: '21:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'LAD',
    away: 'KC',
    time: '21:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'SF',
    away: 'LAA',
    time: '16:05',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'MIL',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'ATL',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'NYM',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-15',
    home: 'WSH',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'AZ',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'COL',
    away: 'PIT',
    time: '15:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'LAD',
    away: 'KC',
    time: '16:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'SF',
    away: 'LAA',
    time: '16:05',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'MIL',
    away: 'CIN',
    time: '14:10',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'ATL',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'NYM',
    away: 'SD',
    time: '13:40',
  },
  {
    week: 12,
    date: '2024-06-16',
    home: 'WSH',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'COL',
    away: 'LAD',
    time: '20:40',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'CHC',
    away: 'SF',
    time: '20:05',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'ATL',
    away: 'DET',
    time: '19:20',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'MIA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-17',
    home: 'PHI',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'COL',
    away: 'LAD',
    time: '20:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'CHC',
    away: 'SF',
    time: '20:05',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'ATL',
    away: 'DET',
    time: '19:20',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'MIA',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'PHI',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-18',
    home: 'WSH',
    away: 'AZ',
    time: '18:45',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'COL',
    away: 'LAD',
    time: '20:40',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'CHC',
    away: 'SF',
    time: '14:20',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'PIT',
    away: 'CIN',
    time: '12:35',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'ATL',
    away: 'DET',
    time: '12:20',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'MIA',
    away: 'STL',
    time: '12:40',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'PHI',
    away: 'SD',
    time: '13:05',
  },
  {
    week: 13,
    date: '2024-06-19',
    home: 'WSH',
    away: 'AZ',
    time: '16:05',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'COL',
    away: 'LAD',
    time: '15:10',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'SD',
    away: 'MIL',
    time: '21:40',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'STL',
    away: 'SF',
    time: '19:15',
  },
  {
    week: 13,
    date: '2024-06-20',
    home: 'WSH',
    away: 'AZ',
    time: '13:05',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'COL',
    away: 'WSH',
    time: '20:40',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'LAD',
    away: 'LAA',
    time: '22:10',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'SD',
    away: 'MIL',
    time: '21:40',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'CHC',
    away: 'NYM',
    time: '14:20',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'CIN',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'PIT',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'MIA',
    away: 'SEA',
    time: '19:10',
  },
  {
    week: 13,
    date: '2024-06-21',
    home: 'PHI',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'COL',
    away: 'WSH',
    time: '21:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'LAD',
    away: 'LAA',
    time: '22:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'SD',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'CHC',
    away: 'NYM',
    time: '14:20',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'CIN',
    away: 'BOS',
    time: '16:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'PIT',
    away: 'TB',
    time: '16:05',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'STL',
    away: 'SF',
    time: '14:15',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'MIA',
    away: 'SEA',
    time: '16:10',
  },
  {
    week: 13,
    date: '2024-06-22',
    home: 'PHI',
    away: 'AZ',
    time: '16:05',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'COL',
    away: 'WSH',
    time: '15:10',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'SD',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'CHC',
    away: 'NYM',
    time: '14:20',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'CIN',
    away: 'BOS',
    time: '13:40',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'PIT',
    away: 'TB',
    time: '13:35',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'STL',
    away: 'SF',
    time: '14:15',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'MIA',
    away: 'SEA',
    time: '13:40',
  },
  {
    week: 13,
    date: '2024-06-23',
    home: 'PHI',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'SD',
    away: 'WSH',
    time: '21:40',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'SF',
    away: 'CHC',
    time: '21:45',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'CIN',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'MIL',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-24',
    home: 'STL',
    away: 'ATL',
    time: '19:45',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'AZ',
    away: 'MIN',
    time: '21:40',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'SD',
    away: 'WSH',
    time: '21:40',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'SF',
    away: 'CHC',
    time: '21:45',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'CIN',
    away: 'PIT',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'MIL',
    away: 'TEX',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'STL',
    away: 'ATL',
    time: '19:45',
  },
  {
    week: 14,
    date: '2024-06-25',
    home: 'NYM',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'AZ',
    away: 'MIN',
    time: '21:40',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'SD',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'SF',
    away: 'CHC',
    time: '21:45',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'CIN',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'MIL',
    away: 'TEX',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'STL',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 14,
    date: '2024-06-26',
    home: 'NYM',
    away: 'NYY',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'AZ',
    away: 'MIN',
    time: '15:40',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'SF',
    away: 'CHC',
    time: '15:45',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'STL',
    away: 'CIN',
    time: '19:45',
  },
  {
    week: 14,
    date: '2024-06-27',
    home: 'PHI',
    away: 'MIA',
    time: '18:20',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'AZ',
    away: 'OAK',
    time: '21:40',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'SF',
    away: 'LAD',
    time: '22:15',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'MIL',
    away: 'CHC',
    time: '20:10',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'STL',
    away: 'CIN',
    time: '20:15',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'ATL',
    away: 'PIT',
    time: '19:20',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'NYM',
    away: 'HOU',
    time: '19:10',
  },
  {
    week: 14,
    date: '2024-06-28',
    home: 'PHI',
    away: 'MIA',
    time: '18:20',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'AZ',
    away: 'OAK',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'SF',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'MIL',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'STL',
    away: 'CIN',
    time: '14:15',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'ATL',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'NYM',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-29',
    home: 'PHI',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'AZ',
    away: 'OAK',
    time: '16:10',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'SF',
    away: 'LAD',
    time: '16:05',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'MIL',
    away: 'CHC',
    time: '14:10',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'STL',
    away: 'CIN',
    time: '14:15',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'ATL',
    away: 'PIT',
    time: '13:35',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'NYM',
    away: 'HOU',
    time: '13:40',
  },
  {
    week: 14,
    date: '2024-06-30',
    home: 'PHI',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 15,
    date: '2024-07-01',
    home: 'COL',
    away: 'MIL',
    time: '20:40',
  },
  {
    week: 15,
    date: '2024-07-01',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'COL',
    away: 'MIL',
    time: '20:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'LAD',
    away: 'AZ',
    time: '22:10',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'CHC',
    away: 'PHI',
    time: '20:05',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'ATL',
    away: 'SF',
    time: '19:20',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'MIA',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-02',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'COL',
    away: 'MIL',
    time: '20:40',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'LAD',
    away: 'AZ',
    time: '22:10',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'CHC',
    away: 'PHI',
    time: '20:05',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'ATL',
    away: 'SF',
    time: '19:20',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'MIA',
    away: 'BOS',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-03',
    home: 'WSH',
    away: 'NYM',
    time: '18:45',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'COL',
    away: 'MIL',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'LAD',
    away: 'AZ',
    time: '21:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'CHC',
    away: 'PHI',
    time: '14:20',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'PIT',
    away: 'STL',
    time: '12:35',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'ATL',
    away: 'SF',
    time: '19:20',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'MIA',
    away: 'BOS',
    time: '13:10',
  },
  {
    week: 15,
    date: '2024-07-04',
    home: 'WSH',
    away: 'NYM',
    time: '11:05',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'COL',
    away: 'KC',
    time: '20:10',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'LAD',
    away: 'MIL',
    time: '22:10',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'SD',
    away: 'AZ',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'CHC',
    away: 'LAA',
    time: '14:20',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'CIN',
    away: 'DET',
    time: '19:10',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'PIT',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'ATL',
    away: 'PHI',
    time: '19:20',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'MIA',
    away: 'CWS',
    time: '19:10',
  },
  {
    week: 15,
    date: '2024-07-05',
    home: 'WSH',
    away: 'STL',
    time: '18:45',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'COL',
    away: 'KC',
    time: '21:10',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'LAD',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'SD',
    away: 'AZ',
    time: '21:40',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'CHC',
    away: 'LAA',
    time: '14:20',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'CIN',
    away: 'DET',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'PIT',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'ATL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'MIA',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-06',
    home: 'WSH',
    away: 'STL',
    time: '16:05',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'COL',
    away: 'KC',
    time: '15:10',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'LAD',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'SD',
    away: 'AZ',
    time: '16:10',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'CHC',
    away: 'LAA',
    time: '14:20',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'CIN',
    away: 'DET',
    time: '13:40',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'PIT',
    away: 'NYM',
    time: '13:35',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'ATL',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'MIA',
    away: 'CWS',
    time: '13:40',
  },
  {
    week: 15,
    date: '2024-07-07',
    home: 'WSH',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'AZ',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'CIN',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'PIT',
    away: 'NYM',
    time: '12:35',
  },
  {
    week: 16,
    date: '2024-07-08',
    home: 'WSH',
    away: 'STL',
    time: '16:05',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'AZ',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'SD',
    away: 'SEA',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'SF',
    away: 'TOR',
    time: '21:45',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'CIN',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'MIL',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'STL',
    away: 'KC',
    time: '19:45',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-09',
    home: 'PHI',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'AZ',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'SD',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'SF',
    away: 'TOR',
    time: '21:45',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'CIN',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'MIL',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'STL',
    away: 'KC',
    time: '19:45',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-10',
    home: 'PHI',
    away: 'LAD',
    time: '19:05',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'AZ',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'SF',
    away: 'TOR',
    time: '15:45',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'CIN',
    away: 'COL',
    time: '13:10',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'MIL',
    away: 'PIT',
    time: '14:10',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'NYM',
    away: 'WSH',
    time: '13:10',
  },
  {
    week: 16,
    date: '2024-07-11',
    home: 'PHI',
    away: 'LAD',
    time: '18:05',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'AZ',
    away: 'TOR',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'SD',
    away: 'ATL',
    time: '21:40',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'SF',
    away: 'MIN',
    time: '22:15',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'CIN',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'MIL',
    away: 'WSH',
    time: '20:10',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'STL',
    away: 'CHC',
    time: '20:15',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'NYM',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 16,
    date: '2024-07-12',
    home: 'PHI',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'AZ',
    away: 'TOR',
    time: '22:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'SD',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'SF',
    away: 'MIN',
    time: '19:15',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'CIN',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'MIL',
    away: 'WSH',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'STL',
    away: 'CHC',
    time: '14:15',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'NYM',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-13',
    home: 'PHI',
    away: 'OAK',
    time: '16:05',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'AZ',
    away: 'TOR',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'SD',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'SF',
    away: 'MIN',
    time: '16:05',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'CIN',
    away: 'MIA',
    time: '13:40',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'MIL',
    away: 'WSH',
    time: '14:10',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'STL',
    away: 'CHC',
    time: '14:15',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'NYM',
    away: 'COL',
    time: '13:40',
  },
  {
    week: 16,
    date: '2024-07-14',
    home: 'PHI',
    away: 'OAK',
    time: '13:35',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'COL',
    away: 'SF',
    time: '20:40',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'LAD',
    away: 'BOS',
    time: '22:10',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'CHC',
    away: 'AZ',
    time: '14:20',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'PIT',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'ATL',
    away: 'STL',
    time: '19:20',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'MIA',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 17,
    date: '2024-07-19',
    home: 'WSH',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'COL',
    away: 'SF',
    time: '20:10',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'LAD',
    away: 'BOS',
    time: '19:15',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'CHC',
    away: 'AZ',
    time: '19:15',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'PIT',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'ATL',
    away: 'STL',
    time: '19:20',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'MIA',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 17,
    date: '2024-07-20',
    home: 'WSH',
    away: 'CIN',
    time: '18:45',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'COL',
    away: 'SF',
    time: '15:10',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'LAD',
    away: 'BOS',
    time: '16:10',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'CHC',
    away: 'AZ',
    time: '14:20',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'PIT',
    away: 'PHI',
    time: '13:35',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'ATL',
    away: 'STL',
    time: '13:35',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'MIA',
    away: 'NYM',
    time: '13:40',
  },
  {
    week: 17,
    date: '2024-07-21',
    home: 'WSH',
    away: 'CIN',
    time: '13:35',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'COL',
    away: 'BOS',
    time: '20:40',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'LAD',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'CHC',
    away: 'MIL',
    time: '20:05',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'ATL',
    away: 'CIN',
    time: '19:20',
  },
  {
    week: 18,
    date: '2024-07-22',
    home: 'MIA',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'COL',
    away: 'BOS',
    time: '20:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'LAD',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'CHC',
    away: 'MIL',
    time: '20:05',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'PIT',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'ATL',
    away: 'CIN',
    time: '19:20',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'MIA',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-23',
    home: 'WSH',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'COL',
    away: 'BOS',
    time: '15:10',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'LAD',
    away: 'SF',
    time: '22:10',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'CHC',
    away: 'MIL',
    time: '14:20',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'PIT',
    away: 'STL',
    time: '12:35',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'ATL',
    away: 'CIN',
    time: '12:20',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'MIA',
    away: 'BAL',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-24',
    home: 'WSH',
    away: 'SD',
    time: '18:45',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'LAD',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'MIA',
    away: 'BAL',
    time: '12:10',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 18,
    date: '2024-07-25',
    home: 'WSH',
    away: 'SD',
    time: '12:05',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'AZ',
    away: 'PIT',
    time: '21:40',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'SF',
    away: 'COL',
    time: '22:15',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'MIL',
    away: 'MIA',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'STL',
    away: 'WSH',
    time: '20:15',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'NYM',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 18,
    date: '2024-07-26',
    home: 'PHI',
    away: 'CLE',
    time: '18:40',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'AZ',
    away: 'PIT',
    time: '20:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'SF',
    away: 'COL',
    time: '19:05',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'MIL',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'STL',
    away: 'WSH',
    time: '19:15',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'NYM',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 18,
    date: '2024-07-27',
    home: 'PHI',
    away: 'CLE',
    time: '18:05',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'AZ',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'SF',
    away: 'COL',
    time: '16:05',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'MIL',
    away: 'MIA',
    time: '14:10',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'STL',
    away: 'WSH',
    time: '14:15',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'NYM',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 18,
    date: '2024-07-28',
    home: 'PHI',
    away: 'CLE',
    time: '13:35',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'AZ',
    away: 'WSH',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'CIN',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'MIL',
    away: 'ATL',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'STL',
    away: 'TEX',
    time: '19:45',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'NYM',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-29',
    home: 'PHI',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'AZ',
    away: 'WSH',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'SD',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'SF',
    away: 'OAK',
    time: '21:45',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'CIN',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'MIL',
    away: 'ATL',
    time: '20:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'STL',
    away: 'TEX',
    time: '19:45',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'NYM',
    away: 'MIN',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-30',
    home: 'PHI',
    away: 'NYY',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'AZ',
    away: 'WSH',
    time: '15:40',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'SD',
    away: 'LAD',
    time: '20:40',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'SF',
    away: 'OAK',
    time: '21:45',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'CIN',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'MIL',
    away: 'ATL',
    time: '14:10',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'STL',
    away: 'TEX',
    time: '14:15',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'NYM',
    away: 'MIN',
    time: '13:10',
  },
  {
    week: 19,
    date: '2024-07-31',
    home: 'PHI',
    away: 'NYY',
    time: '12:35',
  },
  {
    week: 19,
    date: '2024-08-01',
    home: 'CHC',
    away: 'STL',
    time: '20:05',
  },
  {
    week: 19,
    date: '2024-08-01',
    home: 'ATL',
    away: 'MIA',
    time: '19:20',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'SD',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'CIN',
    away: 'SF',
    time: '19:10',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'PIT',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'ATL',
    away: 'MIA',
    time: '19:20',
  },
  {
    week: 19,
    date: '2024-08-02',
    home: 'WSH',
    away: 'MIL',
    time: '18:45',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'SD',
    away: 'COL',
    time: '20:40',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'CIN',
    away: 'SF',
    time: '19:15',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'PIT',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'ATL',
    away: 'MIA',
    time: '19:20',
  },
  {
    week: 19,
    date: '2024-08-03',
    home: 'WSH',
    away: 'MIL',
    time: '16:05',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'SD',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'CHC',
    away: 'STL',
    time: '14:20',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'CIN',
    away: 'SF',
    time: '13:40',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'PIT',
    away: 'AZ',
    time: '13:35',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'ATL',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 19,
    date: '2024-08-04',
    home: 'WSH',
    away: 'MIL',
    time: '13:35',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'LAD',
    away: 'PHI',
    time: '22:10',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'CHC',
    away: 'MIN',
    time: '20:05',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'MIA',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-05',
    home: 'WSH',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'COL',
    away: 'NYM',
    time: '20:40',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'LAD',
    away: 'PHI',
    time: '22:10',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'CHC',
    away: 'MIN',
    time: '20:05',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'PIT',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'STL',
    away: 'TB',
    time: '19:45',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'ATL',
    away: 'MIL',
    time: '19:20',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'MIA',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-06',
    home: 'WSH',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'COL',
    away: 'NYM',
    time: '20:40',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'LAD',
    away: 'PHI',
    time: '22:10',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'CHC',
    away: 'MIN',
    time: '14:20',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'PIT',
    away: 'SD',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'STL',
    away: 'TB',
    time: '19:45',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'ATL',
    away: 'MIL',
    time: '19:20',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'MIA',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 20,
    date: '2024-08-07',
    home: 'WSH',
    away: 'SF',
    time: '18:45',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'AZ',
    away: 'PHI',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'COL',
    away: 'NYM',
    time: '15:10',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'PIT',
    away: 'SD',
    time: '12:35',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'STL',
    away: 'TB',
    time: '19:15',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'ATL',
    away: 'MIL',
    time: '12:20',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'MIA',
    away: 'CIN',
    time: '18:10',
  },
  {
    week: 20,
    date: '2024-08-08',
    home: 'WSH',
    away: 'SF',
    time: '16:05',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'AZ',
    away: 'PHI',
    time: '21:40',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'COL',
    away: 'ATL',
    time: '20:40',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'LAD',
    away: 'PIT',
    time: '22:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'SF',
    away: 'DET',
    time: '22:15',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'MIL',
    away: 'CIN',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'MIA',
    away: 'SD',
    time: '19:10',
  },
  {
    week: 20,
    date: '2024-08-09',
    home: 'WSH',
    away: 'LAA',
    time: '18:45',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'AZ',
    away: 'PHI',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'COL',
    away: 'ATL',
    time: '20:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'LAD',
    away: 'PIT',
    time: '21:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'SF',
    away: 'DET',
    time: '16:05',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'MIL',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'MIA',
    away: 'SD',
    time: '16:10',
  },
  {
    week: 20,
    date: '2024-08-10',
    home: 'WSH',
    away: 'LAA',
    time: '18:45',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'AZ',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'COL',
    away: 'ATL',
    time: '15:10',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'LAD',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'SF',
    away: 'DET',
    time: '16:05',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'MIL',
    away: 'CIN',
    time: '14:10',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'MIA',
    away: 'SD',
    time: '13:40',
  },
  {
    week: 20,
    date: '2024-08-11',
    home: 'WSH',
    away: 'LAA',
    time: '13:35',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'AZ',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'SD',
    away: 'PIT',
    time: '21:40',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'SF',
    away: 'ATL',
    time: '21:45',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-12',
    home: 'MIL',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'AZ',
    away: 'COL',
    time: '21:40',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'SD',
    away: 'PIT',
    time: '21:40',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'SF',
    away: 'ATL',
    time: '21:45',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'MIL',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'NYM',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-13',
    home: 'PHI',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'AZ',
    away: 'COL',
    time: '15:40',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'SD',
    away: 'PIT',
    time: '16:10',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'SF',
    away: 'ATL',
    time: '21:45',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'CIN',
    away: 'STL',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'MIL',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'NYM',
    away: 'OAK',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-14',
    home: 'PHI',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'SF',
    away: 'ATL',
    time: '15:45',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'MIL',
    away: 'LAD',
    time: '14:10',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'NYM',
    away: 'OAK',
    time: '13:10',
  },
  {
    week: 21,
    date: '2024-08-15',
    home: 'PHI',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'COL',
    away: 'SD',
    time: '20:40',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'CHC',
    away: 'TOR',
    time: '14:20',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'CIN',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'MIL',
    away: 'CLE',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'PIT',
    away: 'SEA',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'STL',
    away: 'LAD',
    time: '20:15',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'NYM',
    away: 'MIA',
    time: '19:10',
  },
  {
    week: 21,
    date: '2024-08-16',
    home: 'PHI',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'COL',
    away: 'SD',
    time: '20:10',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'CHC',
    away: 'TOR',
    time: '14:20',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'CIN',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'MIL',
    away: 'CLE',
    time: '19:15',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'PIT',
    away: 'SEA',
    time: '13:05',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'STL',
    away: 'LAD',
    time: '19:15',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'NYM',
    away: 'MIA',
    time: '16:10',
  },
  {
    week: 21,
    date: '2024-08-17',
    home: 'PHI',
    away: 'WSH',
    time: '18:05',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'COL',
    away: 'SD',
    time: '15:10',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'CHC',
    away: 'TOR',
    time: '14:20',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'CIN',
    away: 'KC',
    time: '13:40',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'MIL',
    away: 'CLE',
    time: '14:10',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'PIT',
    away: 'SEA',
    time: '13:35',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'STL',
    away: 'LAD',
    time: '14:15',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'NYM',
    away: 'MIA',
    time: '13:40',
  },
  {
    week: 21,
    date: '2024-08-18',
    home: 'PHI',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'LAD',
    away: 'SEA',
    time: '22:10',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'SD',
    away: 'MIN',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'SF',
    away: 'CWS',
    time: '21:45',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'MIA',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-19',
    home: 'NYM',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'LAD',
    away: 'SEA',
    time: '22:10',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'SD',
    away: 'MIN',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'SF',
    away: 'CWS',
    time: '21:45',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'CHC',
    away: 'DET',
    time: '20:05',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'STL',
    away: 'MIL',
    time: '19:45',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'ATL',
    away: 'PHI',
    time: '19:20',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'MIA',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'NYM',
    away: 'BAL',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-20',
    home: 'WSH',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'LAD',
    away: 'SEA',
    time: '22:10',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'SD',
    away: 'MIN',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'SF',
    away: 'CWS',
    time: '15:45',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'CHC',
    away: 'DET',
    time: '20:05',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'STL',
    away: 'MIL',
    time: '19:45',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'ATL',
    away: 'PHI',
    time: '19:20',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'MIA',
    away: 'AZ',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'NYM',
    away: 'BAL',
    time: '13:10',
  },
  {
    week: 22,
    date: '2024-08-21',
    home: 'WSH',
    away: 'COL',
    time: '18:45',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'SD',
    away: 'NYM',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'CHC',
    away: 'DET',
    time: '14:20',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'STL',
    away: 'MIL',
    time: '14:15',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'ATL',
    away: 'PHI',
    time: '19:15',
  },
  {
    week: 22,
    date: '2024-08-22',
    home: 'WSH',
    away: 'COL',
    time: '13:05',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'LAD',
    away: 'TB',
    time: '22:10',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'SD',
    away: 'NYM',
    time: '21:40',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'ATL',
    away: 'WSH',
    time: '19:20',
  },
  {
    week: 22,
    date: '2024-08-23',
    home: 'MIA',
    away: 'CHC',
    time: '19:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'LAD',
    away: 'TB',
    time: '21:10',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'SD',
    away: 'NYM',
    time: '20:40',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'PIT',
    away: 'CIN',
    time: '18:40',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'ATL',
    away: 'WSH',
    time: '19:20',
  },
  {
    week: 22,
    date: '2024-08-24',
    home: 'MIA',
    away: 'CHC',
    time: '16:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'LAD',
    away: 'TB',
    time: '16:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'SD',
    away: 'NYM',
    time: '16:10',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'PIT',
    away: 'CIN',
    time: '13:35',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'ATL',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 22,
    date: '2024-08-25',
    home: 'MIA',
    away: 'CHC',
    time: '13:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'COL',
    away: 'MIA',
    time: '20:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'STL',
    away: 'SD',
    time: '19:45',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'PHI',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-26',
    home: 'WSH',
    away: 'NYY',
    time: '18:45',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'AZ',
    away: 'NYM',
    time: '21:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'COL',
    away: 'MIA',
    time: '20:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'LAD',
    away: 'BAL',
    time: '22:10',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'CIN',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'MIL',
    away: 'SF',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'PIT',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'STL',
    away: 'SD',
    time: '19:45',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'PHI',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-27',
    home: 'WSH',
    away: 'NYY',
    time: '18:45',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'AZ',
    away: 'NYM',
    time: '21:40',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'COL',
    away: 'MIA',
    time: '20:40',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'LAD',
    away: 'BAL',
    time: '22:10',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'CIN',
    away: 'OAK',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'MIL',
    away: 'SF',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'PIT',
    away: 'CHC',
    time: '12:35',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'STL',
    away: 'SD',
    time: '19:45',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'PHI',
    away: 'HOU',
    time: '16:05',
  },
  {
    week: 23,
    date: '2024-08-28',
    home: 'WSH',
    away: 'NYY',
    time: '18:45',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'AZ',
    away: 'NYM',
    time: '15:40',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'COL',
    away: 'MIA',
    time: '15:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'LAD',
    away: 'BAL',
    time: '22:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'CIN',
    away: 'OAK',
    time: '17:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'MIL',
    away: 'SF',
    time: '14:10',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'STL',
    away: 'SD',
    time: '14:15',
  },
  {
    week: 23,
    date: '2024-08-29',
    home: 'PHI',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'AZ',
    away: 'LAD',
    time: '21:40',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'COL',
    away: 'BAL',
    time: '20:40',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'SF',
    away: 'MIA',
    time: '22:15',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'CIN',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'PHI',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 23,
    date: '2024-08-30',
    home: 'WSH',
    away: 'CHC',
    time: '18:45',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'AZ',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'COL',
    away: 'BAL',
    time: '20:10',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'SF',
    away: 'MIA',
    time: '21:05',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'CIN',
    away: 'MIL',
    time: '19:15',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'PHI',
    away: 'ATL',
    time: '19:15',
  },
  {
    week: 23,
    date: '2024-08-31',
    home: 'WSH',
    away: 'CHC',
    time: '16:05',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'AZ',
    away: 'LAD',
    time: '16:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'COL',
    away: 'BAL',
    time: '15:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'SF',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'CIN',
    away: 'MIL',
    time: '12:10',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'PHI',
    away: 'ATL',
    time: '13:35',
  },
  {
    week: 23,
    date: '2024-09-01',
    home: 'WSH',
    away: 'CHC',
    time: '13:35',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'AZ',
    away: 'LAD',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'SD',
    away: 'DET',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'CHC',
    away: 'PIT',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'CIN',
    away: 'HOU',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'MIL',
    away: 'STL',
    time: '14:10',
  },
  {
    week: 24,
    date: '2024-09-02',
    home: 'NYM',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'SF',
    away: 'AZ',
    time: '21:45',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'CHC',
    away: 'PIT',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'MIL',
    away: 'STL',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'ATL',
    away: 'COL',
    time: '19:20',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'MIA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-03',
    home: 'NYM',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'SD',
    away: 'DET',
    time: '21:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'SF',
    away: 'AZ',
    time: '21:45',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'CHC',
    away: 'PIT',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'CIN',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'MIL',
    away: 'STL',
    time: '19:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'ATL',
    away: 'COL',
    time: '19:20',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'MIA',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-04',
    home: 'NYM',
    away: 'BOS',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'SD',
    away: 'DET',
    time: '20:40',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'SF',
    away: 'AZ',
    time: '15:45',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'CIN',
    away: 'HOU',
    time: '13:10',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'PIT',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'ATL',
    away: 'COL',
    time: '19:20',
  },
  {
    week: 24,
    date: '2024-09-05',
    home: 'MIA',
    away: 'PHI',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'LAD',
    away: 'CLE',
    time: '22:10',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'SD',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'CHC',
    away: 'NYY',
    time: '14:20',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'MIL',
    away: 'COL',
    time: '20:10',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'PIT',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'STL',
    away: 'SEA',
    time: '20:15',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'ATL',
    away: 'TOR',
    time: '19:20',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'MIA',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-06',
    home: 'NYM',
    away: 'CIN',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'LAD',
    away: 'CLE',
    time: '21:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'SD',
    away: 'SF',
    time: '20:40',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'CHC',
    away: 'NYY',
    time: '14:20',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'MIL',
    away: 'COL',
    time: '19:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'PIT',
    away: 'WSH',
    time: '18:40',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'STL',
    away: 'SEA',
    time: '19:15',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'ATL',
    away: 'TOR',
    time: '19:20',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'MIA',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-07',
    home: 'NYM',
    away: 'CIN',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'LAD',
    away: 'CLE',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'SD',
    away: 'SF',
    time: '16:10',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'CHC',
    away: 'NYY',
    time: '14:20',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'MIL',
    away: 'COL',
    time: '14:10',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'PIT',
    away: 'WSH',
    time: '13:35',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'STL',
    away: 'SEA',
    time: '14:15',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'ATL',
    away: 'TOR',
    time: '13:35',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'MIA',
    away: 'PHI',
    time: '13:40',
  },
  {
    week: 24,
    date: '2024-09-08',
    home: 'NYM',
    away: 'CIN',
    time: '13:40',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'LAD',
    away: 'CHC',
    time: '22:10',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'PIT',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-09',
    home: 'PHI',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'AZ',
    away: 'TEX',
    time: '21:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'LAD',
    away: 'CHC',
    time: '22:10',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'SF',
    away: 'MIL',
    time: '21:45',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'PIT',
    away: 'MIA',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'STL',
    away: 'CIN',
    time: '19:45',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'PHI',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-10',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'AZ',
    away: 'TEX',
    time: '15:40',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'LAD',
    away: 'CHC',
    time: '22:10',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'SF',
    away: 'MIL',
    time: '21:45',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'PIT',
    away: 'MIA',
    time: '12:35',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'STL',
    away: 'CIN',
    time: '19:45',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'PHI',
    away: 'TB',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-11',
    home: 'WSH',
    away: 'ATL',
    time: '18:45',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'SF',
    away: 'MIL',
    time: '21:45',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'STL',
    away: 'CIN',
    time: '13:15',
  },
  {
    week: 25,
    date: '2024-09-12',
    home: 'WSH',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'AZ',
    away: 'MIL',
    time: '21:40',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'COL',
    away: 'CHC',
    time: '20:40',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'SF',
    away: 'SD',
    time: '22:15',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'PIT',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'ATL',
    away: 'LAD',
    time: '19:20',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'PHI',
    away: 'NYM',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-13',
    home: 'WSH',
    away: 'MIA',
    time: '18:45',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'AZ',
    away: 'MIL',
    time: '20:10',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'COL',
    away: 'CHC',
    time: '20:10',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'SF',
    away: 'SD',
    time: '21:05',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'PIT',
    away: 'KC',
    time: '18:40',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'ATL',
    away: 'LAD',
    time: '19:20',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'PHI',
    away: 'NYM',
    time: '16:05',
  },
  {
    week: 25,
    date: '2024-09-14',
    home: 'WSH',
    away: 'MIA',
    time: '16:05',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'AZ',
    away: 'MIL',
    time: '16:10',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'COL',
    away: 'CHC',
    time: '15:10',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'SF',
    away: 'SD',
    time: '16:05',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'PIT',
    away: 'KC',
    time: '13:35',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'ATL',
    away: 'LAD',
    time: '13:35',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'PHI',
    away: 'NYM',
    time: '13:35',
  },
  {
    week: 25,
    date: '2024-09-15',
    home: 'WSH',
    away: 'MIA',
    time: '13:35',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'COL',
    away: 'AZ',
    time: '20:40',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'SD',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'CHC',
    away: 'OAK',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'MIL',
    away: 'PHI',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'STL',
    away: 'PIT',
    time: '19:45',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'ATL',
    away: 'LAD',
    time: '19:20',
  },
  {
    week: 26,
    date: '2024-09-16',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'COL',
    away: 'AZ',
    time: '20:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'SD',
    away: 'HOU',
    time: '21:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'CHC',
    away: 'OAK',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'CIN',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'MIL',
    away: 'PHI',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'STL',
    away: 'PIT',
    time: '19:45',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'MIA',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-17',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'COL',
    away: 'AZ',
    time: '15:10',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'SD',
    away: 'HOU',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'CHC',
    away: 'OAK',
    time: '14:20',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'CIN',
    away: 'ATL',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'MIL',
    away: 'PHI',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'STL',
    away: 'PIT',
    time: '19:45',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'MIA',
    away: 'LAD',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-18',
    home: 'NYM',
    away: 'WSH',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'CHC',
    away: 'WSH',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'CIN',
    away: 'ATL',
    time: '13:10',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'MIL',
    away: 'AZ',
    time: '19:40',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'STL',
    away: 'PIT',
    time: '19:15',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'MIA',
    away: 'LAD',
    time: '16:40',
  },
  {
    week: 26,
    date: '2024-09-19',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'LAD',
    away: 'COL',
    time: '22:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'SD',
    away: 'CWS',
    time: '21:40',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'CHC',
    away: 'WSH',
    time: '14:20',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'CIN',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'MIL',
    away: 'AZ',
    time: '20:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'STL',
    away: 'CLE',
    time: '20:15',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'MIA',
    away: 'ATL',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-20',
    home: 'NYM',
    away: 'PHI',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'LAD',
    away: 'COL',
    time: '21:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'SD',
    away: 'CWS',
    time: '20:40',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'CHC',
    away: 'WSH',
    time: '14:20',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'CIN',
    away: 'PIT',
    time: '18:40',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'MIL',
    away: 'AZ',
    time: '19:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'STL',
    away: 'CLE',
    time: '19:15',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'MIA',
    away: 'ATL',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-21',
    home: 'NYM',
    away: 'PHI',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'LAD',
    away: 'COL',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'SD',
    away: 'CWS',
    time: '16:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'CHC',
    away: 'WSH',
    time: '14:20',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'CIN',
    away: 'PIT',
    time: '13:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'MIL',
    away: 'AZ',
    time: '14:10',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'STL',
    away: 'CLE',
    time: '14:15',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'MIA',
    away: 'ATL',
    time: '13:40',
  },
  {
    week: 26,
    date: '2024-09-22',
    home: 'NYM',
    away: 'PHI',
    time: '13:40',
  },
  {
    week: 27,
    date: '2024-09-23',
    home: 'AZ',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-23',
    home: 'PHI',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'AZ',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'COL',
    away: 'STL',
    time: '20:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'LAD',
    away: 'SD',
    time: '22:10',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'ATL',
    away: 'NYM',
    time: '19:20',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'PHI',
    away: 'CHC',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-24',
    home: 'WSH',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'AZ',
    away: 'SF',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'COL',
    away: 'STL',
    time: '20:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'LAD',
    away: 'SD',
    time: '22:10',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'PIT',
    away: 'MIL',
    time: '18:40',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'ATL',
    away: 'NYM',
    time: '19:20',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'PHI',
    away: 'CHC',
    time: '18:05',
  },
  {
    week: 27,
    date: '2024-09-25',
    home: 'WSH',
    away: 'KC',
    time: '18:45',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'COL',
    away: 'STL',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'LAD',
    away: 'SD',
    time: '22:10',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'PIT',
    away: 'MIL',
    time: '12:35',
  },
  {
    week: 27,
    date: '2024-09-26',
    home: 'WSH',
    away: 'KC',
    time: '13:05',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'AZ',
    away: 'SD',
    time: '21:40',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'COL',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'SF',
    away: 'STL',
    time: '22:15',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'CHC',
    away: 'CIN',
    time: '14:20',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'MIL',
    away: 'NYM',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'ATL',
    away: 'KC',
    time: '19:20',
  },
  {
    week: 27,
    date: '2024-09-27',
    home: 'WSH',
    away: 'PHI',
    time: '18:45',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'AZ',
    away: 'SD',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'COL',
    away: 'LAD',
    time: '20:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'SF',
    away: 'STL',
    time: '16:05',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'CHC',
    away: 'CIN',
    time: '14:20',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'MIL',
    away: 'NYM',
    time: '19:10',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'ATL',
    away: 'KC',
    time: '19:20',
  },
  {
    week: 27,
    date: '2024-09-28',
    home: 'WSH',
    away: 'PHI',
    time: '16:05',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'AZ',
    away: 'SD',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'COL',
    away: 'LAD',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'SF',
    away: 'STL',
    time: '15:05',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'CHC',
    away: 'CIN',
    time: '15:20',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'MIL',
    away: 'NYM',
    time: '15:10',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'ATL',
    away: 'KC',
    time: '15:20',
  },
  {
    week: 27,
    date: '2024-09-29',
    home: 'WSH',
    away: 'PHI',
    time: '15:05',
  },
];

export const MLB_2025_SCHEDULE = [...MLB_2025_AL_SCHEDULE, ...MLB_2025_NL_SCHEDULE];

export const MLB_2024_SCHEDULE = [...MLB_2024_AL_SCHEDULE, ...MLB_2024_NL_SCHEDULE];

export type MlbAggregateMetrics = {
  travel_distance_in_miles_total: number;
  travel_distance_in_miles_average: number;
  travel_time_in_minutes_total: number;
  travel_time_in_minutes_average: number;
  twenty_games_streak_count: number;
  same_city_conflict_day_count_by_team: {
    [team: string]: number;
  };
  true_home_off_day_count: number;
  home_off_day_count: number;
  home_off_day_counts_by_pattern: {
    HOH: number;
    AOH: number;
    HOA: number;
  };
  home_weekend_average: number;
  home_match_average_by_month: {
    [month: string]: number;
  };
  home_series_in_july_average: number;
  home_weekends_in_july_average: number;
  carry_over_monday_count: number;
  semi_repeater_count: number;
  home_series_count_by_match_count: {
    [matchCount: number]: number;
  };
  away_series_count_by_match_count: {
    [matchCount: number]: number;
  };
  home_stand_count_by_series_length: {
    [length: number]: number;
  };
  road_trip_count_by_series_length: {
    [length: number]: number;
  };
};

export const MLB_2025_AGGREGATE_METRICS: MlbAggregateMetrics = {
  travel_distance_in_miles_total: 1071532,
  travel_distance_in_miles_average: 35718,
  travel_time_in_minutes_total: 128584,
  travel_time_in_minutes_average: 4286,
  twenty_games_streak_count: 0,
  same_city_conflict_day_count_by_team: {
    AZ: 0,
    ATL: 0,
    BAL: 6,
    BOS: 0,
    CHC: 3,
    CWS: 3,
    CIN: 0,
    CLE: 0,
    COL: 0,
    DET: 0,
    HOU: 0,
    KC: 0,
    LAA: 6,
    LAD: 6,
    MIA: 0,
    MIL: 0,
    MIN: 0,
    NYM: 5,
    NYY: 5,
    OAK: 55,
    PHI: 0,
    PIT: 0,
    SD: 0,
    SEA: 0,
    SF: 55,
    STL: 0,
    TB: 0,
    TEX: 0,
    TOR: 0,
    WSH: 6,
  },
  true_home_off_day_count: 319,
  home_off_day_count: 499,
  home_off_day_counts_by_pattern: {
    HOH: 147,
    AOH: 172,
    HOA: 180,
  },
  home_weekend_average: 13.5,
  home_match_average_by_month: {
    January: 0.0,
    February: 0.0,
    March: 2.23,
    April: 13.1,
    May: 13.77,
    June: 13.27,
    July: 12.27,
    August: 13.97,
    September: 12.4,
    October: 0.0,
    November: 0.0,
    December: 0.0,
  },
  home_series_in_july_average: 4.07,
  home_weekends_in_july_average: 2.0,
  carry_over_monday_count: 6,
  semi_repeater_count: 70,
  home_series_count_by_match_count: {
    1: 12,
    2: 19,
    3: 668,
    4: 94,
  },
  away_series_count_by_match_count: {
    1: 12,
    2: 19,
    3: 668,
    4: 94,
  },
  home_stand_count_by_series_length: {
    1: 49,
    2: 294,
    3: 52,
    4: 0,
  },
  road_trip_count_by_series_length: {
    1: 63,
    2: 268,
    3: 62,
    4: 2,
  },
};

export const MLB_2024_FULL_SCHEDULE_METRICS: MlbAggregateMetrics = {
  travel_distance_in_miles_total: 1061482,
  travel_distance_in_miles_average: 35383,
  travel_time_in_minutes_total: 127378,
  travel_time_in_minutes_average: 4246,
  twenty_games_streak_count: 0,
  same_city_conflict_day_count_by_team: {
    AZ: 0,
    ATL: 0,
    BAL: 6,
    BOS: 0,
    CHC: 6,
    CWS: 6,
    CIN: 0,
    CLE: 0,
    COL: 0,
    DET: 0,
    HOU: 0,
    KC: 0,
    LAA: 1,
    LAD: 1,
    MIA: 0,
    MIL: 0,
    MIN: 0,
    NYM: 3,
    NYY: 3,
    OAK: 6,
    PHI: 0,
    PIT: 0,
    SD: 0,
    SEA: 0,
    SF: 6,
    STL: 0,
    TB: 0,
    TEX: 0,
    TOR: 0,
    WSH: 6,
  },
  true_home_off_day_count: 321,
  home_off_day_count: 494,
  home_off_day_counts_by_pattern: {
    HOH: 136,
    AOH: 185,
    HOA: 173,
  },
  home_weekend_average: 13.53,
  home_match_average_by_month: {
    January: 0.0,
    February: 0.0,
    March: 1.83,
    April: 13.37,
    May: 13.7,
    June: 13.37,
    July: 12.23,
    August: 13.63,
    September: 12.8,
    October: 0.0,
    November: 0.0,
    December: 0.0,
  },
  home_series_in_july_average: 4.0,
  home_weekends_in_july_average: 2.0,
  carry_over_monday_count: 24,
  semi_repeater_count: 87,
  home_series_count_by_match_count: {
    1: 17,
    2: 51,
    3: 607,
    4: 122,
  },
  away_series_count_by_match_count: {
    1: 17,
    2: 51,
    3: 607,
    4: 122,
  },
  home_stand_count_by_series_length: {
    1: 50,
    2: 284,
    3: 57,
    4: 2,
  },
  road_trip_count_by_series_length: {
    1: 58,
    2: 270,
    3: 65,
    4: 1,
  },
};
export type TeamMatchCounts = {
  home: number;
  away: number;
};

export type MlbTeamMetricEntry = {
  total_matches: number;
  home_matches: number;
  away_match: number;
  twenty_games_streak_count: number;
  opponent_match_counts_by_division: {
    'National League East': Record<MlbTeamType, TeamMatchCounts>;
    'American League East': Record<MlbTeamType, TeamMatchCounts>;
    'National League Central': Record<MlbTeamType, TeamMatchCounts>;
    'American League Central': Record<MlbTeamType, TeamMatchCounts>;
    'National League West': Record<MlbTeamType, TeamMatchCounts>;
    'American League West': Record<MlbTeamType, TeamMatchCounts>;
  };
  travel_distance_miles_total: number;
  travel_time_in_minutes_total: number;
  home_off_days_by_pattern: {
    HOH: number;
    AOH: number;
    HOA: number;
  };
  true_home_off_day_count: number;
  home_off_day_count: number;
  home_weekend_count: number;
  home_match_count_by_month: {
    [month: string]: number;
  };
  home_series_in_july_count: number;
  home_weekends_in_july_count: number;
  carry_over_monday_count: number;
  semi_repeater_count: number;
  home_series_count_by_match_count: {
    [matchCount: number]: number;
  };
  away_series_count_by_match_count: {
    [matchCount: number]: number;
  };
  home_stand_count_by_series_length: {
    [length: number]: number;
  };
  road_trip_count_by_series_length: {
    [length: number]: number;
  };
};

export type MLBTeamMetricsIndex = {
  [K in MlbTeamType]: MlbTeamMetricEntry;
};

export const MLB_2025_TEAM_METRICS_INDEX: MLBTeamMetricsIndex = {
  AZ: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 4,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 4,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'National League West': {
        COL: {
          home: 6,
          away: 7,
        },
        LAD: {
          home: 7,
          away: 6,
        },
        SD: {
          home: 6,
          away: 7,
        },
        SF: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 42146,
    travel_time_in_minutes_total: 5058,
    home_off_days_by_pattern: {
      HOH: 8,
      AOH: 3,
      HOA: 6,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 4,
      April: 12,
      May: 15,
      June: 11,
      July: 12,
      August: 12,
      September: 15,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      1: 2,
      2: 9,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
  },
  ATL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 4,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 4,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        MIA: {
          home: 7,
          away: 6,
        },
        NYM: {
          home: 6,
          away: 7,
        },
        PHI: {
          home: 6,
          away: 7,
        },
        WSH: {
          home: 7,
          away: 6,
        },
      },
    },
    travel_distance_miles_total: 33373,
    travel_time_in_minutes_total: 4005,
    home_off_days_by_pattern: {
      HOH: 7,
      AOH: 6,
      HOA: 5,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 18,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 12,
      May: 16,
      June: 13,
      July: 12,
      August: 13,
      September: 15,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
  },
  BAL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BOS: {
          home: 7,
          away: 6,
        },
        NYY: {
          home: 7,
          away: 6,
        },
        TB: {
          home: 6,
          away: 7,
        },
        TOR: {
          home: 6,
          away: 7,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 4,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 4,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 35797,
    travel_time_in_minutes_total: 4296,
    home_off_days_by_pattern: {
      HOH: 8,
      AOH: 7,
      HOA: 4,
    },
    true_home_off_day_count: 15,
    home_off_day_count: 19,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 1,
      April: 14,
      May: 14,
      June: 13,
      July: 12,
      August: 14,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 12,
      3: 1,
      1: 0,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      2: 12,
      3: 0,
      4: 0,
    },
  },
  BOS: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 6,
          away: 7,
        },
        NYY: {
          home: 6,
          away: 7,
        },
        TB: {
          home: 7,
          away: 6,
        },
        TOR: {
          home: 7,
          away: 6,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 4,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 4,
        },
      },
    },
    travel_distance_miles_total: 38146,
    travel_time_in_minutes_total: 4577,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 7,
      HOA: 5,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 15,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 14,
      May: 16,
      June: 13,
      July: 12,
      August: 14,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 3,
      1: 1,
      2: 1,
      3: 22,
    },
    home_stand_count_by_series_length: {
      2: 10,
      3: 1,
      1: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 2,
      2: 9,
      1: 3,
      4: 0,
    },
  },
  CHC: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 4,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 4,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CIN: {
          home: 6,
          away: 7,
        },
        MIL: {
          home: 7,
          away: 6,
        },
        PIT: {
          home: 7,
          away: 6,
        },
        STL: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 25969,
    travel_time_in_minutes_total: 3116,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 6,
      HOA: 7,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 19,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 2,
      April: 14,
      May: 14,
      June: 11,
      July: 12,
      August: 13,
      September: 15,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      2: 2,
      3: 23,
      4: 2,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      1: 3,
      2: 9,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
  },
  CWS: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 4,
        },
        NYY: {
          home: 4,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CLE: {
          home: 7,
          away: 6,
        },
        DET: {
          home: 7,
          away: 6,
        },
        KC: {
          home: 6,
          away: 7,
        },
        MIN: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 26885,
    travel_time_in_minutes_total: 3226,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 5,
      HOA: 7,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 10,
      May: 13,
      June: 16,
      July: 13,
      August: 16,
      September: 9,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      3: 2,
      2: 9,
      1: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 10,
      3: 1,
      1: 3,
      4: 0,
    },
  },
  CIN: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 4,
        },
        MIA: {
          home: 4,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 7,
          away: 6,
        },
        MIL: {
          home: 6,
          away: 7,
        },
        PIT: {
          home: 6,
          away: 7,
        },
        STL: {
          home: 7,
          away: 6,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 26331,
    travel_time_in_minutes_total: 3160,
    home_off_days_by_pattern: {
      HOH: 7,
      AOH: 6,
      HOA: 1,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 14,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 4,
      April: 11,
      May: 13,
      June: 15,
      July: 14,
      August: 11,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 5,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 7,
      3: 3,
      1: 3,
      4: 0,
    },
  },
  CLE: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 4,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 4,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 6,
          away: 7,
        },
        DET: {
          home: 6,
          away: 7,
        },
        KC: {
          home: 7,
          away: 6,
        },
        MIN: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 26183,
    travel_time_in_minutes_total: 3142,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 5,
      HOA: 6,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 15,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 15,
      May: 12,
      June: 13,
      July: 13,
      August: 15,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    home_stand_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      4: 1,
      2: 7,
      3: 2,
      1: 3,
    },
  },
  COL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 7,
          away: 6,
        },
        LAD: {
          home: 7,
          away: 6,
        },
        SD: {
          home: 6,
          away: 7,
        },
        SF: {
          home: 6,
          away: 7,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 4,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 4,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 37472,
    travel_time_in_minutes_total: 4497,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 6,
      HOA: 4,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 14,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 15,
      May: 13,
      June: 12,
      July: 12,
      August: 17,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      1: 2,
      2: 2,
      3: 21,
      4: 3,
    },
    home_stand_count_by_series_length: {
      2: 11,
      1: 1,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      4: 1,
      2: 9,
      1: 3,
      3: 1,
    },
  },
  DET: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 4,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 6,
          away: 7,
        },
        CLE: {
          home: 7,
          away: 6,
        },
        KC: {
          home: 7,
          away: 6,
        },
        MIN: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 4,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 29135,
    travel_time_in_minutes_total: 3496,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 3,
      HOA: 8,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 16,
      May: 13,
      June: 15,
      July: 13,
      August: 12,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 10,
      3: 1,
      1: 3,
      4: 0,
    },
  },
  HOU: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 4,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 4,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        LAA: {
          home: 7,
          away: 6,
        },
        OAK: {
          home: 6,
          away: 7,
        },
        SEA: {
          home: 7,
          away: 6,
        },
        TEX: {
          home: 6,
          away: 7,
        },
      },
    },
    travel_distance_miles_total: 38114,
    travel_time_in_minutes_total: 4574,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 6,
      HOA: 6,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 14,
      May: 15,
      June: 13,
      July: 13,
      August: 12,
      September: 10,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    home_stand_count_by_series_length: {
      3: 3,
      1: 2,
      2: 8,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 3,
      1: 2,
      2: 8,
      4: 0,
    },
  },
  KC: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 7,
          away: 6,
        },
        CLE: {
          home: 6,
          away: 7,
        },
        DET: {
          home: 6,
          away: 7,
        },
        MIN: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 4,
        },
        TEX: {
          home: 4,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 30541,
    travel_time_in_minutes_total: 3665,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 8,
      HOA: 5,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 18,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 13,
      May: 15,
      June: 13,
      July: 12,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 10,
      1: 1,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      3: 2,
      2: 9,
      4: 0,
    },
  },
  LAA: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 4,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 4,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 6,
          away: 7,
        },
        OAK: {
          home: 6,
          away: 7,
        },
        SEA: {
          home: 7,
          away: 6,
        },
        TEX: {
          home: 7,
          away: 6,
        },
      },
    },
    travel_distance_miles_total: 44557,
    travel_time_in_minutes_total: 5347,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 3,
      HOA: 6,
    },
    true_home_off_day_count: 8,
    home_off_day_count: 14,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 12,
      May: 16,
      June: 15,
      July: 14,
      August: 12,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 0,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    home_stand_count_by_series_length: {
      2: 8,
      3: 3,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 4,
      2: 7,
      1: 1,
      4: 0,
    },
  },
  LAD: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 6,
          away: 7,
        },
        COL: {
          home: 6,
          away: 7,
        },
        SD: {
          home: 7,
          away: 6,
        },
        SF: {
          home: 7,
          away: 6,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 4,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 4,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 47617,
    travel_time_in_minutes_total: 5714,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 7,
      HOA: 9,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 19,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 14,
      May: 11,
      June: 15,
      July: 12,
      August: 15,
      September: 10,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      2: 2,
      3: 23,
      4: 2,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 3,
      2: 9,
      3: 2,
      4: 0,
    },
  },
  MIA: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 6,
          away: 7,
        },
        NYM: {
          home: 6,
          away: 7,
        },
        PHI: {
          home: 7,
          away: 6,
        },
        WSH: {
          home: 7,
          away: 6,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 4,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 4,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 39896,
    travel_time_in_minutes_total: 4787,
    home_off_days_by_pattern: {
      HOH: 2,
      AOH: 7,
      HOA: 8,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 5,
      April: 11,
      May: 14,
      June: 11,
      July: 12,
      August: 15,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      1: 1,
      3: 3,
      4: 0,
    },
  },
  MIL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 4,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 4,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 6,
          away: 7,
        },
        CIN: {
          home: 7,
          away: 6,
        },
        PIT: {
          home: 6,
          away: 7,
        },
        STL: {
          home: 7,
          away: 6,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 30741,
    travel_time_in_minutes_total: 3689,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 6,
      HOA: 7,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 1,
      April: 12,
      May: 15,
      June: 16,
      July: 12,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      3: 22,
      4: 3,
      1: 1,
      2: 1,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    home_stand_count_by_series_length: {
      2: 11,
      1: 2,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 11,
      3: 1,
      1: 2,
      4: 0,
    },
  },
  MIN: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 7,
          away: 6,
        },
        CLE: {
          home: 6,
          away: 7,
        },
        DET: {
          home: 7,
          away: 6,
        },
        KC: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 4,
        },
        SEA: {
          home: 4,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 31579,
    travel_time_in_minutes_total: 3790,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 7,
      HOA: 5,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 15,
      May: 15,
      June: 13,
      July: 12,
      August: 13,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    home_stand_count_by_series_length: {
      2: 10,
      3: 2,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 2,
      1: 3,
      2: 9,
      4: 0,
    },
  },
  NYM: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 4,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 7,
          away: 6,
        },
        MIA: {
          home: 7,
          away: 6,
        },
        PHI: {
          home: 6,
          away: 7,
        },
        WSH: {
          home: 6,
          away: 7,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 4,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 35826,
    travel_time_in_minutes_total: 4299,
    home_off_days_by_pattern: {
      HOH: 2,
      AOH: 7,
      HOA: 9,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 15,
      May: 15,
      June: 11,
      July: 12,
      August: 19,
      September: 9,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    home_stand_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 2,
      2: 9,
      1: 3,
      4: 0,
    },
  },
  NYY: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 6,
          away: 7,
        },
        BOS: {
          home: 7,
          away: 6,
        },
        TB: {
          home: 7,
          away: 6,
        },
        TOR: {
          home: 6,
          away: 7,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 4,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 4,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 32378,
    travel_time_in_minutes_total: 3885,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 10,
      HOA: 3,
    },
    true_home_off_day_count: 14,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 12,
      May: 12,
      June: 16,
      July: 13,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      3: 1,
      2: 11,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
  },
  OAK: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 4,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 4,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 7,
          away: 6,
        },
        LAA: {
          home: 7,
          away: 6,
        },
        SEA: {
          home: 6,
          away: 7,
        },
        TEX: {
          home: 6,
          away: 7,
        },
      },
    },
    travel_distance_miles_total: 49857,
    travel_time_in_minutes_total: 5983,
    home_off_days_by_pattern: {
      HOH: 7,
      AOH: 3,
      HOA: 8,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 1,
      April: 14,
      May: 13,
      June: 14,
      July: 12,
      August: 15,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      1: 1,
      2: 11,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      2: 9,
      3: 2,
      4: 0,
    },
  },
  PHI: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 4,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 4,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 7,
          away: 6,
        },
        MIA: {
          home: 6,
          away: 7,
        },
        NYM: {
          home: 7,
          away: 6,
        },
        WSH: {
          home: 6,
          away: 7,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 33927,
    travel_time_in_minutes_total: 4071,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 4,
      HOA: 6,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 15,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 1,
      April: 14,
      May: 15,
      June: 11,
      July: 11,
      August: 16,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      1: 2,
      2: 2,
      3: 21,
      4: 3,
    },
    home_stand_count_by_series_length: {
      3: 1,
      2: 11,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 10,
      1: 2,
      3: 2,
      4: 0,
    },
  },
  PIT: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 4,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 4,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 6,
          away: 7,
        },
        CIN: {
          home: 7,
          away: 6,
        },
        MIL: {
          home: 7,
          away: 6,
        },
        STL: {
          home: 6,
          away: 7,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 28544,
    travel_time_in_minutes_total: 3425,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 6,
      HOA: 5,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 14,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 15,
      May: 14,
      June: 16,
      July: 11,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 10,
      1: 3,
      3: 1,
      4: 0,
    },
  },
  SD: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 7,
          away: 6,
        },
        COL: {
          home: 7,
          away: 6,
        },
        LAD: {
          home: 6,
          away: 7,
        },
        SF: {
          home: 6,
          away: 7,
        },
      },
      'National League East': {
        ATL: {
          home: 4,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 4,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 45471,
    travel_time_in_minutes_total: 5457,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 4,
      HOA: 8,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 5,
      April: 13,
      May: 11,
      June: 10,
      July: 13,
      August: 13,
      September: 16,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 10,
      1: 3,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
  },
  SEA: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 4,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 4,
        },
      },
      'American League West': {
        HOU: {
          home: 6,
          away: 7,
        },
        LAA: {
          home: 6,
          away: 7,
        },
        OAK: {
          home: 7,
          away: 6,
        },
        TEX: {
          home: 7,
          away: 6,
        },
      },
    },
    travel_distance_miles_total: 44549,
    travel_time_in_minutes_total: 5346,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 5,
      HOA: 9,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 5,
      April: 13,
      May: 11,
      June: 11,
      July: 13,
      August: 15,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 5,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 0,
    home_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 10,
      3: 2,
      1: 0,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 1,
      3: 5,
      2: 5,
      4: 0,
    },
  },
  SF: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 6,
          away: 7,
        },
        COL: {
          home: 7,
          away: 6,
        },
        LAD: {
          home: 6,
          away: 7,
        },
        SD: {
          home: 7,
          away: 6,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 4,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 4,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 46456,
    travel_time_in_minutes_total: 5575,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 5,
      HOA: 5,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 16,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 13,
      May: 13,
      June: 16,
      July: 12,
      August: 15,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    home_stand_count_by_series_length: {
      2: 8,
      1: 1,
      3: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 4,
      1: 1,
      2: 7,
      4: 0,
    },
  },
  STL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 4,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 4,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 7,
          away: 6,
        },
        CIN: {
          home: 6,
          away: 7,
        },
        MIL: {
          home: 6,
          away: 7,
        },
        PIT: {
          home: 7,
          away: 6,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 27589,
    travel_time_in_minutes_total: 3311,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 5,
      HOA: 3,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 13,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 11,
      May: 12,
      June: 16,
      July: 13,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      1: 2,
      3: 2,
      4: 0,
    },
  },
  TB: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 7,
          away: 6,
        },
        BOS: {
          home: 6,
          away: 7,
        },
        NYY: {
          home: 6,
          away: 7,
        },
        TOR: {
          home: 7,
          away: 6,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 4,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 4,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 38714,
    travel_time_in_minutes_total: 4646,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 5,
      HOA: 7,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 14,
      May: 13,
      June: 14,
      July: 11,
      August: 11,
      September: 14,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 0,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      3: 2,
      1: 1,
      2: 10,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 11,
      1: 1,
      3: 1,
      4: 0,
    },
  },
  TEX: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 4,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 4,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 7,
          away: 6,
        },
        LAA: {
          home: 6,
          away: 7,
        },
        OAK: {
          home: 7,
          away: 6,
        },
        SEA: {
          home: 6,
          away: 7,
        },
      },
    },
    travel_distance_miles_total: 38271,
    travel_time_in_minutes_total: 4592,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 5,
      HOA: 8,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 18,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 4,
      April: 12,
      May: 16,
      June: 11,
      July: 11,
      August: 15,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      1: 2,
      2: 9,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      2: 9,
      3: 2,
      4: 0,
    },
  },
  TOR: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 7,
          away: 6,
        },
        BOS: {
          home: 6,
          away: 7,
        },
        NYY: {
          home: 7,
          away: 6,
        },
        TB: {
          home: 6,
          away: 7,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 4,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 4,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 32818,
    travel_time_in_minutes_total: 3938,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 7,
      HOA: 3,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 14,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 5,
      April: 10,
      May: 16,
      June: 11,
      July: 12,
      August: 15,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 0,
    home_series_count_by_match_count: {
      4: 3,
      3: 23,
      1: 0,
      2: 0,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 2,
      2: 10,
      1: 0,
      4: 0,
    },
  },
  WSH: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 4,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 6,
          away: 7,
        },
        MIA: {
          home: 6,
          away: 7,
        },
        NYM: {
          home: 7,
          away: 6,
        },
        PHI: {
          home: 7,
          away: 6,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 4,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 32649,
    travel_time_in_minutes_total: 3918,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 8,
      HOA: 7,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 19,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 13,
      May: 12,
      June: 13,
      July: 12,
      August: 16,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      1: 1,
      2: 1,
      3: 22,
      4: 3,
    },
    away_series_count_by_match_count: {
      3: 23,
      4: 3,
      1: 0,
      2: 0,
    },
    home_stand_count_by_series_length: {
      2: 13,
      1: 1,
      3: 0,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      3: 2,
      2: 9,
      4: 0,
    },
  },
};

export const MLB_2024_TEAM_METRICS_INDEX: MLBTeamMetricsIndex = {
  AZ: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League East': {
        ATL: {
          home: 4,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 4,
        },
        PHI: {
          home: 4,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 4,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'National League West': {
        COL: {
          home: 7,
          away: 6,
        },
        LAD: {
          home: 7,
          away: 6,
        },
        SD: {
          home: 6,
          away: 7,
        },
        SF: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 2,
          away: 2,
        },
      },
    },
    travel_distance_miles_total: 41668,
    travel_time_in_minutes_total: 5000,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 7,
      HOA: 7,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 11,
      May: 13,
      June: 15,
      July: 13,
      August: 12,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 12,
      1: 2,
      3: 0,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
  },
  ATL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 4,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 4,
          away: 3,
        },
        SD: {
          home: 4,
          away: 3,
        },
        SF: {
          home: 3,
          away: 4,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 2,
          away: 2,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        MIA: {
          home: 7,
          away: 6,
        },
        NYM: {
          home: 6,
          away: 7,
        },
        PHI: {
          home: 6,
          away: 7,
        },
        WSH: {
          home: 7,
          away: 6,
        },
      },
    },
    travel_distance_miles_total: 32152,
    travel_time_in_minutes_total: 3858,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 8,
      HOA: 4,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 16,
      May: 14,
      June: 11,
      July: 12,
      August: 13,
      September: 15,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 2,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      3: 19,
      4: 5,
      2: 2,
      1: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 3,
      3: 18,
      4: 5,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
  },
  BAL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 2,
          away: 2,
        },
      },
      'American League East': {
        BOS: {
          home: 7,
          away: 6,
        },
        NYY: {
          home: 7,
          away: 6,
        },
        TB: {
          home: 6,
          away: 7,
        },
        TOR: {
          home: 6,
          away: 7,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 4,
        },
        CLE: {
          home: 3,
          away: 4,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 4,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 4,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 32991,
    travel_time_in_minutes_total: 3959,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 5,
      HOA: 6,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 15,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 14,
      May: 15,
      June: 15,
      July: 12,
      August: 10,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    away_series_count_by_match_count: {
      3: 21,
      2: 1,
      4: 4,
      1: 0,
    },
    home_stand_count_by_series_length: {
      3: 2,
      2: 10,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
  },
  BOS: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 2,
          away: 2,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 6,
          away: 7,
        },
        NYY: {
          home: 6,
          away: 7,
        },
        TB: {
          home: 7,
          away: 6,
        },
        TOR: {
          home: 7,
          away: 6,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 4,
        },
        CLE: {
          home: 4,
          away: 3,
        },
        DET: {
          home: 4,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 4,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 36014,
    travel_time_in_minutes_total: 4322,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 10,
      HOA: 2,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 15,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 14,
      May: 14,
      June: 16,
      July: 12,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      3: 1,
      2: 10,
      1: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 1,
      2: 10,
      1: 3,
      4: 0,
    },
  },
  CHC: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 4,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 4,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 4,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 4,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 2,
          away: 2,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CIN: {
          home: 6,
          away: 7,
        },
        MIL: {
          home: 6,
          away: 7,
        },
        PIT: {
          home: 7,
          away: 6,
        },
        STL: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 28008,
    travel_time_in_minutes_total: 3361,
    home_off_days_by_pattern: {
      HOH: 7,
      AOH: 3,
      HOA: 4,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 14,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 13,
      May: 14,
      June: 13,
      July: 12,
      August: 13,
      September: 16,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    home_stand_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 10,
      3: 2,
      1: 1,
      4: 0,
    },
  },
  CWS: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 4,
          away: 3,
        },
        BOS: {
          home: 4,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 2,
          away: 2,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CLE: {
          home: 7,
          away: 6,
        },
        DET: {
          home: 7,
          away: 6,
        },
        KC: {
          home: 6,
          away: 7,
        },
        MIN: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 4,
        },
        TEX: {
          home: 3,
          away: 4,
        },
      },
    },
    travel_distance_miles_total: 30302,
    travel_time_in_minutes_total: 3636,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 5,
      HOA: 7,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 3,
      April: 14,
      May: 15,
      June: 13,
      July: 12,
      August: 14,
      September: 10,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      3: 2,
      2: 9,
      1: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 12,
      1: 2,
      3: 0,
      4: 0,
    },
  },
  CIN: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 4,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 4,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 4,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 4,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 7,
          away: 6,
        },
        MIL: {
          home: 7,
          away: 6,
        },
        PIT: {
          home: 6,
          away: 7,
        },
        STL: {
          home: 6,
          away: 7,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 2,
          away: 2,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 27731,
    travel_time_in_minutes_total: 3328,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 7,
      HOA: 3,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 16,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 3,
      April: 14,
      May: 15,
      June: 12,
      July: 13,
      August: 14,
      September: 10,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      1: 2,
      2: 3,
      3: 19,
      4: 4,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      4: 1,
      1: 0,
    },
    road_trip_count_by_series_length: {
      1: 1,
      2: 8,
      3: 3,
      4: 0,
    },
  },
  CLE: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 4,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 4,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 4,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 2,
          away: 2,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 6,
          away: 7,
        },
        DET: {
          home: 7,
          away: 6,
        },
        KC: {
          home: 6,
          away: 7,
        },
        MIN: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 4,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 29925,
    travel_time_in_minutes_total: 3591,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 6,
      HOA: 3,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 14,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 12,
      May: 13,
      June: 11,
      July: 13,
      August: 18,
      September: 14,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 5,
      3: 18,
      1: 1,
      2: 3,
    },
    home_stand_count_by_series_length: {
      2: 11,
      1: 1,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      4: 1,
      1: 3,
      2: 7,
      3: 2,
    },
  },
  COL: {
    total_matches: 161,
    home_matches: 81,
    away_match: 80,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 6,
          away: 7,
        },
        LAD: {
          home: 7,
          away: 6,
        },
        SD: {
          home: 7,
          away: 6,
        },
        SF: {
          home: 6,
          away: 6,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 4,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 4,
        },
        MIL: {
          home: 4,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 4,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 2,
          away: 2,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 33834,
    travel_time_in_minutes_total: 4060,
    home_off_days_by_pattern: {
      HOH: 1,
      AOH: 7,
      HOA: 8,
    },
    true_home_off_day_count: 8,
    home_off_day_count: 16,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 15,
      May: 12,
      June: 13,
      July: 13,
      August: 15,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 3,
      3: 22,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 7,
      3: 3,
      1: 3,
      4: 0,
    },
  },
  DET: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 4,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 4,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 2,
          away: 2,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 6,
          away: 7,
        },
        CLE: {
          home: 6,
          away: 7,
        },
        KC: {
          home: 7,
          away: 6,
        },
        MIN: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 4,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 4,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 31156,
    travel_time_in_minutes_total: 3739,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 9,
      HOA: 4,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 16,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 16,
      May: 13,
      June: 12,
      July: 12,
      August: 15,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      3: 19,
      4: 5,
      2: 2,
      1: 0,
    },
    away_series_count_by_match_count: {
      1: 2,
      2: 3,
      3: 19,
      4: 4,
    },
    home_stand_count_by_series_length: {
      1: 1,
      2: 11,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 3,
      1: 1,
      2: 9,
      4: 0,
    },
  },
  HOU: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 2,
          away: 2,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 4,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 4,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 4,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 4,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        LAA: {
          home: 7,
          away: 6,
        },
        OAK: {
          home: 7,
          away: 6,
        },
        SEA: {
          home: 6,
          away: 7,
        },
        TEX: {
          home: 6,
          away: 7,
        },
      },
    },
    travel_distance_miles_total: 38323,
    travel_time_in_minutes_total: 4599,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 5,
      HOA: 8,
    },
    true_home_off_day_count: 8,
    home_off_day_count: 16,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 4,
      April: 10,
      May: 16,
      June: 13,
      July: 12,
      August: 12,
      September: 14,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 20,
      2: 2,
      1: 1,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      3: 3,
      1: 2,
      4: 0,
    },
  },
  KC: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 4,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 4,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 2,
          away: 2,
        },
      },
      'American League Central': {
        CWS: {
          home: 7,
          away: 6,
        },
        CLE: {
          home: 7,
          away: 6,
        },
        DET: {
          home: 6,
          away: 7,
        },
        MIN: {
          home: 6,
          away: 7,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 4,
        },
        LAA: {
          home: 3,
          away: 4,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 30750,
    travel_time_in_minutes_total: 3690,
    home_off_days_by_pattern: {
      HOH: 8,
      AOH: 3,
      HOA: 4,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 15,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 14,
      May: 13,
      June: 16,
      July: 12,
      August: 11,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      4: 4,
      3: 20,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 10,
      1: 1,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      2: 9,
      3: 2,
      4: 0,
    },
  },
  LAA: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 2,
          away: 2,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 4,
        },
        TOR: {
          home: 3,
          away: 4,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 4,
          away: 3,
        },
        KC: {
          home: 4,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 6,
          away: 7,
        },
        OAK: {
          home: 7,
          away: 6,
        },
        SEA: {
          home: 7,
          away: 6,
        },
        TEX: {
          home: 6,
          away: 7,
        },
      },
    },
    travel_distance_miles_total: 45307,
    travel_time_in_minutes_total: 5437,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 4,
      HOA: 7,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 14,
      May: 14,
      June: 16,
      July: 13,
      August: 12,
      September: 12,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 7,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
  },
  LAD: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 6,
          away: 7,
        },
        COL: {
          home: 6,
          away: 7,
        },
        SD: {
          home: 7,
          away: 6,
        },
        SF: {
          home: 7,
          away: 6,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 4,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 4,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 4,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 4,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 2,
          away: 2,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 44233,
    travel_time_in_minutes_total: 5308,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 6,
      HOA: 8,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 19,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 5,
      April: 12,
      May: 14,
      June: 10,
      July: 13,
      August: 15,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 2,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      1: 1,
      4: 3,
      3: 22,
      2: 1,
    },
    away_series_count_by_match_count: {
      1: 1,
      3: 20,
      4: 4,
      2: 2,
    },
    home_stand_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 3,
      2: 9,
      3: 2,
      4: 0,
    },
  },
  MIA: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 4,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 6,
          away: 7,
        },
        NYM: {
          home: 7,
          away: 6,
        },
        PHI: {
          home: 7,
          away: 6,
        },
        WSH: {
          home: 6,
          away: 7,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 2,
          away: 2,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 4,
        },
        CIN: {
          home: 4,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 4,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 42318,
    travel_time_in_minutes_total: 5078,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 8,
      HOA: 5,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 4,
      April: 14,
      May: 12,
      June: 13,
      July: 13,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 2,
    semi_repeater_count: 0,
    home_series_count_by_match_count: {
      4: 5,
      3: 19,
      2: 2,
      1: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 3,
      3: 18,
      4: 5,
    },
    home_stand_count_by_series_length: {
      2: 11,
      1: 1,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 2,
      2: 10,
      1: 1,
      4: 0,
    },
  },
  MIL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 4,
          away: 3,
        },
        COL: {
          home: 3,
          away: 4,
        },
        LAD: {
          home: 4,
          away: 3,
        },
        SD: {
          home: 3,
          away: 4,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 7,
          away: 6,
        },
        CIN: {
          home: 6,
          away: 7,
        },
        PIT: {
          home: 6,
          away: 7,
        },
        STL: {
          home: 7,
          away: 6,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 2,
          away: 2,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 29571,
    travel_time_in_minutes_total: 3548,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 4,
      HOA: 7,
    },
    true_home_off_day_count: 8,
    home_off_day_count: 15,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 13,
      May: 13,
      June: 14,
      July: 12,
      August: 13,
      September: 16,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      2: 1,
      3: 21,
      4: 4,
      1: 0,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 2,
      4: 4,
      3: 20,
    },
    home_stand_count_by_series_length: {
      2: 10,
      1: 3,
      3: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 11,
      3: 1,
      1: 2,
      4: 0,
    },
  },
  MIN: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 4,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 2,
          away: 2,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 7,
          away: 6,
        },
        CLE: {
          home: 6,
          away: 7,
        },
        DET: {
          home: 6,
          away: 7,
        },
        KC: {
          home: 7,
          away: 6,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 4,
          away: 3,
        },
        SEA: {
          home: 4,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 4,
        },
      },
    },
    travel_distance_miles_total: 33335,
    travel_time_in_minutes_total: 4000,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 9,
      HOA: 1,
    },
    true_home_off_day_count: 13,
    home_off_day_count: 14,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 13,
      May: 17,
      June: 10,
      July: 11,
      August: 17,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      3: 20,
      4: 4,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 2,
      4: 4,
      3: 20,
    },
    home_stand_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 3,
      2: 8,
      1: 2,
      4: 0,
    },
  },
  NYM: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 4,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 4,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 7,
          away: 6,
        },
        MIA: {
          home: 6,
          away: 7,
        },
        PHI: {
          home: 7,
          away: 6,
        },
        WSH: {
          home: 6,
          away: 7,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 2,
          away: 2,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 4,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 4,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 33438,
    travel_time_in_minutes_total: 4013,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 7,
      HOA: 4,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 14,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 14,
      May: 15,
      June: 14,
      July: 13,
      August: 9,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 2,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      1: 2,
      2: 3,
      3: 19,
      4: 4,
    },
    away_series_count_by_match_count: {
      3: 18,
      4: 5,
      2: 3,
      1: 1,
    },
    home_stand_count_by_series_length: {
      3: 3,
      2: 9,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      3: 3,
      1: 2,
      4: 0,
    },
  },
  NYY: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 2,
          away: 2,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 6,
          away: 7,
        },
        BOS: {
          home: 7,
          away: 6,
        },
        TB: {
          home: 7,
          away: 6,
        },
        TOR: {
          home: 6,
          away: 7,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 4,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 4,
        },
        LAA: {
          home: 3,
          away: 3,
        },
        OAK: {
          home: 4,
          away: 3,
        },
        SEA: {
          home: 4,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 32167,
    travel_time_in_minutes_total: 3860,
    home_off_days_by_pattern: {
      HOH: 2,
      AOH: 9,
      HOA: 5,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 16,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 13,
      May: 13,
      June: 12,
      July: 12,
      August: 17,
      September: 14,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 11,
      3: 1,
      1: 1,
      4: 0,
    },
  },
  OAK: {
    total_matches: 161,
    home_matches: 80,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 2,
          away: 2,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 0,
          away: 3,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 4,
        },
        TB: {
          home: 4,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 0,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 0,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 4,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 4,
        },
      },
      'American League West': {
        HOU: {
          home: 6,
          away: 7,
        },
        LAA: {
          home: 6,
          away: 7,
        },
        SEA: {
          home: 7,
          away: 6,
        },
        TEX: {
          home: 6,
          away: 6,
        },
      },
    },
    travel_distance_miles_total: 45710,
    travel_time_in_minutes_total: 5485,
    home_off_days_by_pattern: {
      HOH: 2,
      AOH: 7,
      HOA: 8,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 11,
      May: 13,
      June: 12,
      July: 12,
      August: 15,
      September: 13,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      4: 3,
      3: 22,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 10,
      3: 2,
      1: 0,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      3: 3,
      1: 1,
      4: 0,
    },
  },
  PHI: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 4,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 4,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 7,
          away: 6,
        },
        MIA: {
          home: 6,
          away: 7,
        },
        NYM: {
          home: 6,
          away: 7,
        },
        WSH: {
          home: 7,
          away: 6,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 2,
          away: 2,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 4,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 4,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 31882,
    travel_time_in_minutes_total: 3826,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 5,
      HOA: 9,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 19,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 3,
      April: 13,
      May: 15,
      June: 16,
      July: 12,
      August: 12,
      September: 10,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 1,
    home_series_count_by_match_count: {
      1: 2,
      2: 4,
      3: 17,
      4: 5,
    },
    away_series_count_by_match_count: {
      3: 20,
      4: 4,
      2: 2,
      1: 1,
    },
    home_stand_count_by_series_length: {
      3: 3,
      2: 8,
      1: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 9,
      3: 2,
      1: 3,
      4: 0,
    },
  },
  PIT: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 4,
        },
        NYM: {
          home: 4,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 4,
        },
        WSH: {
          home: 4,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 6,
          away: 7,
        },
        CIN: {
          home: 7,
          away: 6,
        },
        MIL: {
          home: 7,
          away: 6,
        },
        STL: {
          home: 6,
          away: 7,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 2,
          away: 2,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 26725,
    travel_time_in_minutes_total: 3207,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 8,
      HOA: 5,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 17,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 12,
      May: 15,
      June: 12,
      July: 13,
      August: 16,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      3: 21,
      2: 1,
      4: 4,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      1: 1,
      2: 2,
      3: 20,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 1,
      2: 11,
      1: 2,
      4: 0,
    },
  },
  SD: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 7,
          away: 6,
        },
        COL: {
          home: 6,
          away: 7,
        },
        LAD: {
          home: 6,
          away: 7,
        },
        SF: {
          home: 7,
          away: 6,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 4,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 4,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 4,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 4,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 0,
        },
        SEA: {
          home: 2,
          away: 2,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 46146,
    travel_time_in_minutes_total: 5538,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 3,
      HOA: 11,
    },
    true_home_off_day_count: 8,
    home_off_day_count: 19,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 5,
      April: 14,
      May: 13,
      June: 14,
      July: 10,
      August: 13,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      1: 2,
      4: 4,
      3: 19,
      2: 3,
    },
    away_series_count_by_match_count: {
      1: 1,
      3: 22,
      4: 3,
      2: 1,
    },
    home_stand_count_by_series_length: {
      1: 4,
      2: 9,
      3: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 4,
      2: 10,
      3: 1,
      4: 0,
    },
  },
  SEA: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 2,
          away: 2,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 0,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 3,
          away: 0,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 3,
        },
        BOS: {
          home: 4,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 4,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 4,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 4,
        },
      },
      'American League West': {
        HOU: {
          home: 7,
          away: 6,
        },
        LAA: {
          home: 6,
          away: 7,
        },
        OAK: {
          home: 6,
          away: 7,
        },
        TEX: {
          home: 7,
          away: 6,
        },
      },
    },
    travel_distance_miles_total: 47430,
    travel_time_in_minutes_total: 5692,
    home_off_days_by_pattern: {
      HOH: 3,
      AOH: 6,
      HOA: 10,
    },
    true_home_off_day_count: 9,
    home_off_day_count: 19,
    home_weekend_count: 14,
    home_match_count_by_month: {
      March: 4,
      April: 14,
      May: 12,
      June: 12,
      July: 12,
      August: 15,
      September: 12,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 8,
      3: 3,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 7,
      3: 4,
      1: 0,
      4: 0,
    },
  },
  SF: {
    total_matches: 161,
    home_matches: 80,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 7,
          away: 6,
        },
        COL: {
          home: 6,
          away: 6,
        },
        LAD: {
          home: 6,
          away: 7,
        },
        SD: {
          home: 6,
          away: 7,
        },
      },
      'National League East': {
        ATL: {
          home: 4,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 4,
        },
        WSH: {
          home: 3,
          away: 4,
        },
      },
      'American League East': {
        BAL: {
          home: 0,
          away: 3,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 4,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 3,
        },
        STL: {
          home: 3,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 3,
          away: 0,
        },
        KC: {
          home: 0,
          away: 3,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 2,
          away: 2,
        },
        SEA: {
          home: 0,
          away: 3,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 45645,
    travel_time_in_minutes_total: 5477,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 4,
      HOA: 8,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 18,
    home_weekend_count: 14,
    home_match_count_by_month: {
      April: 16,
      May: 13,
      June: 15,
      July: 11,
      August: 12,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 3,
    home_series_count_by_match_count: {
      3: 22,
      4: 3,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 20,
      1: 1,
      2: 2,
    },
    home_stand_count_by_series_length: {
      2: 9,
      3: 2,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      3: 3,
      1: 2,
      4: 0,
    },
  },
  STL: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 4,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 4,
        },
        SD: {
          home: 4,
          away: 3,
        },
        SF: {
          home: 3,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 3,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 3,
        },
        PHI: {
          home: 3,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 4,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 0,
        },
        BOS: {
          home: 3,
          away: 0,
        },
        NYY: {
          home: 0,
          away: 3,
        },
        TB: {
          home: 3,
          away: 0,
        },
        TOR: {
          home: 0,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 6,
          away: 7,
        },
        CIN: {
          home: 7,
          away: 6,
        },
        MIL: {
          home: 6,
          away: 7,
        },
        PIT: {
          home: 7,
          away: 6,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 0,
        },
        CLE: {
          home: 3,
          away: 0,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 2,
          away: 2,
        },
        MIN: {
          home: 0,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 0,
          away: 3,
        },
        LAA: {
          home: 0,
          away: 3,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 3,
          away: 0,
        },
      },
    },
    travel_distance_miles_total: 29978,
    travel_time_in_minutes_total: 3597,
    home_off_days_by_pattern: {
      HOH: 7,
      AOH: 7,
      HOA: 4,
    },
    true_home_off_day_count: 14,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 12,
      May: 15,
      June: 17,
      July: 11,
      August: 13,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 0,
    home_series_count_by_match_count: {
      1: 2,
      2: 3,
      3: 19,
      4: 4,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      3: 2,
      2: 8,
      4: 1,
      1: 2,
    },
    road_trip_count_by_series_length: {
      2: 10,
      3: 1,
      1: 3,
      4: 0,
    },
  },
  TB: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 0,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 2,
          away: 2,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 7,
          away: 6,
        },
        BOS: {
          home: 6,
          away: 7,
        },
        NYY: {
          home: 6,
          away: 7,
        },
        TOR: {
          home: 7,
          away: 6,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 0,
          away: 3,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 4,
        },
        DET: {
          home: 3,
          away: 3,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 4,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 3,
        },
        LAA: {
          home: 4,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 4,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 34955,
    travel_time_in_minutes_total: 4195,
    home_off_days_by_pattern: {
      HOH: 6,
      AOH: 6,
      HOA: 5,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 17,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 4,
      April: 13,
      May: 18,
      June: 13,
      July: 11,
      August: 11,
      September: 11,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 2,
    semi_repeater_count: 4,
    home_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 8,
      1: 1,
      3: 3,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      1: 1,
      3: 3,
      4: 0,
    },
  },
  TEX: {
    total_matches: 161,
    home_matches: 81,
    away_match: 80,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 2,
          away: 2,
        },
        COL: {
          home: 0,
          away: 3,
        },
        LAD: {
          home: 0,
          away: 3,
        },
        SD: {
          home: 3,
          away: 0,
        },
        SF: {
          home: 3,
          away: 0,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 0,
          away: 3,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 0,
          away: 3,
        },
        WSH: {
          home: 3,
          away: 0,
        },
      },
      'American League East': {
        BAL: {
          home: 3,
          away: 4,
        },
        BOS: {
          home: 3,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 3,
        },
        TB: {
          home: 3,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 3,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 0,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 0,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 4,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 4,
        },
        KC: {
          home: 3,
          away: 3,
        },
        MIN: {
          home: 4,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 7,
          away: 6,
        },
        LAA: {
          home: 7,
          away: 6,
        },
        OAK: {
          home: 6,
          away: 6,
        },
        SEA: {
          home: 6,
          away: 7,
        },
      },
    },
    travel_distance_miles_total: 37228,
    travel_time_in_minutes_total: 4467,
    home_off_days_by_pattern: {
      HOH: 5,
      AOH: 6,
      HOA: 7,
    },
    true_home_off_day_count: 11,
    home_off_day_count: 18,
    home_weekend_count: 13,
    home_match_count_by_month: {
      March: 3,
      April: 14,
      May: 10,
      June: 12,
      July: 13,
      August: 15,
      September: 14,
      January: 0,
      February: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 1,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      1: 1,
      2: 2,
      4: 4,
      3: 20,
    },
    away_series_count_by_match_count: {
      3: 22,
      4: 3,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      2: 10,
      3: 2,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      1: 2,
      3: 2,
      2: 9,
      4: 0,
    },
  },
  TOR: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 0,
          away: 3,
        },
        COL: {
          home: 3,
          away: 0,
        },
        LAD: {
          home: 3,
          away: 0,
        },
        SD: {
          home: 0,
          away: 3,
        },
        SF: {
          home: 0,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 0,
          away: 3,
        },
        MIA: {
          home: 3,
          away: 0,
        },
        NYM: {
          home: 3,
          away: 0,
        },
        PHI: {
          home: 2,
          away: 2,
        },
        WSH: {
          home: 0,
          away: 3,
        },
      },
      'American League East': {
        BAL: {
          home: 7,
          away: 6,
        },
        BOS: {
          home: 6,
          away: 7,
        },
        NYY: {
          home: 7,
          away: 6,
        },
        TB: {
          home: 6,
          away: 7,
        },
      },
      'National League Central': {
        CHC: {
          home: 0,
          away: 3,
        },
        CIN: {
          home: 3,
          away: 0,
        },
        MIL: {
          home: 0,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 0,
        },
        STL: {
          home: 3,
          away: 0,
        },
      },
      'American League Central': {
        CWS: {
          home: 3,
          away: 3,
        },
        CLE: {
          home: 3,
          away: 3,
        },
        DET: {
          home: 3,
          away: 4,
        },
        KC: {
          home: 3,
          away: 4,
        },
        MIN: {
          home: 3,
          away: 3,
        },
      },
      'American League West': {
        HOU: {
          home: 4,
          away: 3,
        },
        LAA: {
          home: 4,
          away: 3,
        },
        OAK: {
          home: 3,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 3,
        },
        TEX: {
          home: 3,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 33447,
    travel_time_in_minutes_total: 4014,
    home_off_days_by_pattern: {
      HOH: 4,
      AOH: 6,
      HOA: 5,
    },
    true_home_off_day_count: 10,
    home_off_day_count: 15,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 14,
      May: 11,
      June: 16,
      July: 13,
      August: 13,
      September: 14,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 0,
    semi_repeater_count: 5,
    home_series_count_by_match_count: {
      3: 21,
      4: 4,
      2: 1,
      1: 0,
    },
    away_series_count_by_match_count: {
      4: 4,
      3: 21,
      2: 1,
      1: 0,
    },
    home_stand_count_by_series_length: {
      3: 2,
      2: 9,
      1: 2,
      4: 0,
    },
    road_trip_count_by_series_length: {
      3: 2,
      2: 9,
      1: 2,
      4: 0,
    },
  },
  WSH: {
    total_matches: 162,
    home_matches: 81,
    away_match: 81,
    twenty_games_streak_count: 0,
    opponent_match_counts_by_division: {
      'National League West': {
        AZ: {
          home: 3,
          away: 3,
        },
        COL: {
          home: 3,
          away: 3,
        },
        LAD: {
          home: 3,
          away: 3,
        },
        SD: {
          home: 3,
          away: 3,
        },
        SF: {
          home: 4,
          away: 3,
        },
      },
      'National League East': {
        ATL: {
          home: 6,
          away: 7,
        },
        MIA: {
          home: 7,
          away: 6,
        },
        NYM: {
          home: 7,
          away: 6,
        },
        PHI: {
          home: 6,
          away: 7,
        },
      },
      'American League East': {
        BAL: {
          home: 2,
          away: 2,
        },
        BOS: {
          home: 0,
          away: 3,
        },
        NYY: {
          home: 3,
          away: 0,
        },
        TB: {
          home: 0,
          away: 3,
        },
        TOR: {
          home: 3,
          away: 0,
        },
      },
      'National League Central': {
        CHC: {
          home: 3,
          away: 4,
        },
        CIN: {
          home: 3,
          away: 3,
        },
        MIL: {
          home: 3,
          away: 3,
        },
        PIT: {
          home: 3,
          away: 4,
        },
        STL: {
          home: 4,
          away: 3,
        },
      },
      'American League Central': {
        CWS: {
          home: 0,
          away: 3,
        },
        CLE: {
          home: 0,
          away: 3,
        },
        DET: {
          home: 0,
          away: 3,
        },
        KC: {
          home: 3,
          away: 0,
        },
        MIN: {
          home: 3,
          away: 0,
        },
      },
      'American League West': {
        HOU: {
          home: 3,
          away: 0,
        },
        LAA: {
          home: 3,
          away: 0,
        },
        OAK: {
          home: 0,
          away: 3,
        },
        SEA: {
          home: 3,
          away: 0,
        },
        TEX: {
          home: 0,
          away: 3,
        },
      },
    },
    travel_distance_miles_total: 29113,
    travel_time_in_minutes_total: 3494,
    home_off_days_by_pattern: {
      HOH: 7,
      AOH: 5,
      HOA: 4,
    },
    true_home_off_day_count: 12,
    home_off_day_count: 16,
    home_weekend_count: 13,
    home_match_count_by_month: {
      April: 12,
      May: 11,
      June: 13,
      July: 14,
      August: 18,
      September: 13,
      January: 0,
      February: 0,
      March: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    home_series_in_july_count: 4,
    home_weekends_in_july_count: 2,
    carry_over_monday_count: 2,
    semi_repeater_count: 2,
    home_series_count_by_match_count: {
      1: 1,
      2: 3,
      3: 18,
      4: 5,
    },
    away_series_count_by_match_count: {
      1: 1,
      2: 3,
      3: 18,
      4: 5,
    },
    home_stand_count_by_series_length: {
      3: 2,
      2: 10,
      1: 1,
      4: 0,
    },
    road_trip_count_by_series_length: {
      2: 8,
      3: 3,
      1: 2,
      4: 0,
    },
  },
};
