import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CloseButton,
  Flex,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Tabs,
  Title,
  rem,
} from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import { createFileRoute, getRouteApi, useNavigate } from '@tanstack/react-router';
import cx from 'clsx';
import { z } from 'zod';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBaseball,
  faCalendarDay,
  faChevronRight,
  faGlobe,
  faClock,
  faEye,
} from '@fortawesome/sharp-regular-svg-icons';

import { useAppStore } from 'stores/appStore';

import ScheduleCalendar from 'components/ScheduleCalendar';
import ScheduleList from 'components/ScheduleList';
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';

import { MLB_TEAM_KEYS } from 'utils/scheduleConsts';
// import TeamSummary from 'components/TeamSummary';
import DateSummary from 'components/DateSummary';
import ScheduleEyeChart from 'components/ScheduleEyeChart';
import ScheduleMap from 'components/ScheduleMap';
import TeamReport from 'components/TeamReport';
import classes from './schedules.module.css';

const TAB_NAMES = ['calendar', 'eyechart', 'team', 'date', 'map', 'timeline'] as const;
type TabNameType = (typeof TAB_NAMES)[number];

function SchedulesPage() {
  const navigate = useNavigate({ from: '/schedules' });
  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();

  const [fetchScheduleData, currentScheduleId, fetchMlbSchedule] = useAppStore((state) => [
    state.fetchScheduleData,
    state.currentScheduleId,
    state.fetchScheduleData,
  ]);

  const [isAppLoading] = useAppStore((state) => [state.isAppLoading, state.setIsAppLoading]);
  const [isScheduleLoading] = useAppStore((state) => [state.isScheduleLoading]);
  const [currentScheduleName] = useAppStore((state) => [state.currentScheduleName]);
  const [comparedScheduleIdList, setComparedScheduleIdList] = useAppStore((state) => [
    state.comparedScheduleIdList,
    state.setComparedScheduleIdList,
  ]);

  // Load in `compare` search param from URL.
  const compare = Array.from(routeSearch.compare || []);
  useEffect(() => {
    if (compare.join(',') !== comparedScheduleIdList.join(',')) {
      setComparedScheduleIdList(compare);
    }
  }, []);

  // Handle resizing of the schedule panel.
  const [panelSize, setPanelSize] = useState(20);
  const [panelMinSize, setPanelMinSize] = useState(20);
  const [panelMaxSize, setPanelMaxSize] = useState(50);
  const [panelCollapsedSize, setPanelCollapsedSize] = useState(10);
  const PANEL_SIZE_IN_PIXELS = 300;
  const PANEL_MIN_SIZE_IN_PIXELS = 160;
  const PANEL_MAX_SIZE_IN_PIXELS = 600;
  const PANEL_COLLAPSED_SIZE_IN_PIXELS = 20;
  const [ref, rect] = useResizeObserver();
  const [sidebarRef] = useResizeObserver();
  const collapsiblePanelRef = useRef<ImperativePanelHandle | null>(null);
  useLayoutEffect(() => {
    const panelGroupContainer = ref?.current;
    if (!panelGroupContainer) {
      return;
    }
    const panelGroup = panelGroupContainer.querySelector('[data-panel-group]') as HTMLElement;
    const resizeHandle = panelGroup.querySelector('[data-resize-handle]') as HTMLElement;
    const width = panelGroup.offsetWidth - resizeHandle.offsetWidth;
    setPanelSize((PANEL_SIZE_IN_PIXELS / width) * 100);
    setPanelCollapsedSize((PANEL_COLLAPSED_SIZE_IN_PIXELS / width) * 100);
    setPanelMinSize((PANEL_MIN_SIZE_IN_PIXELS / width) * 100);
    setPanelMaxSize((PANEL_MAX_SIZE_IN_PIXELS / width) * 100);
  }, [ref, rect.width]);

  useEffect(() => {
    if (currentScheduleId !== '') {
      fetchScheduleData(currentScheduleId);
      fetchMlbSchedule(currentScheduleId);
      navigate({ search: (prev) => ({ ...prev, s: currentScheduleId }) });
    }
  }, [currentScheduleId]);

  if (isAppLoading) {
    return (
      <Box>
        <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'xl', blur: 2 }} />
      </Box>
    );
  }

  return (
    <Flex
      ref={ref}
      h="calc(100dvh - 64px)"
      w="100%"
      style={{
        flexGrow: 0,
      }}
    >
      <PanelGroup direction="horizontal" style={{ flexGrow: 0, overflow: 'hidden' }}>
        <Panel
          className={cx({
            [classes.collapsed]: collapsiblePanelRef?.current?.isCollapsed() || false,
          })}
          ref={collapsiblePanelRef}
          defaultSize={panelSize}
          collapsible
          collapsedSize={panelCollapsedSize}
          minSize={panelMinSize}
          maxSize={panelMaxSize}
          style={{
            backgroundColor: 'var(--mantine-color-gray-0)',
            borderRight:
              'calc(0.0625rem * var(--mantine-scale)) solid var(--_app-shell-border-color)',
          }}
        >
          <div ref={sidebarRef} style={{ width: '100%', height: '100%' }}>
            <Box className={classes.hiddenWhenCollapsed} pb={58}>
              <Group justify="space-between" align="start" p="xs" pb={0}>
                <Title order={4} py="xs">
                  Schedules
                </Title>
                <CloseButton
                  size="xs"
                  onClick={() => {
                    const panel = collapsiblePanelRef.current;
                    if (panel) {
                      panel.collapse();
                    }
                  }}
                />
              </Group>
              <ScheduleList />
            </Box>
            <Box
              className={classes.shownWhenCollapsed}
              onClick={() => {
                const panel = collapsiblePanelRef.current;
                if (panel) {
                  panel.expand();
                }
              }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  paddingTop: '12px',
                }}
              />
            </Box>
          </div>
        </Panel>
        <PanelResizeHandle />
        <Panel
          style={{
            overflow: 'hidden',
            display: 'block',
            position: 'relative',
          }}
        >
          <LoadingOverlay
            visible={isScheduleLoading}
            zIndex={1000}
            loaderProps={{ style: { position: 'relative' } }}
            h="100%"
            w="100%"
            overlayProps={{ blur: 1 }}
          />
          <Flex
            flex={1}
            direction="column"
            gap={0}
            w="100%"
            h="100%"
            style={{ overflow: 'hidden' }}
          >
            <Stack mx="md" mt="xs" gap="5px">
              <Title order={3}>{currentScheduleName}</Title>
              <Checkbox
                label="Compare"
                variant="outline"
                radius="xs"
                size="xs"
                checked={comparedScheduleIdList.includes(currentScheduleId)}
                onChange={(e) => {
                  let newList = [...comparedScheduleIdList];
                  if (e.target.checked && !comparedScheduleIdList.includes(currentScheduleId)) {
                    newList = [...newList, currentScheduleId];
                  } else {
                    newList = newList.filter((id) => id !== currentScheduleId);
                  }
                  setComparedScheduleIdList(newList);
                  navigate({
                    search: (prev) => ({
                      ...prev,
                      compare: newList.length ? newList : undefined,
                    }),
                  });
                }}
              />
            </Stack>
            <Tabs
              p="xs"
              variant="outline"
              classNames={{
                panel: classes.panel,
              }}
              radius="xs"
              value={routeSearch.t}
              onChange={(value) => {
                const nextTab = (value as TabNameType) || 'eyechart';
                navigate({ search: (prev) => ({ ...prev, t: nextTab }) });
              }}
              w="100%"
              h="calc(100% - 75px)"
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab
                  value="eyechart"
                  leftSection={
                    <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faEye} />
                  }
                >
                  Eye Chart
                </Tabs.Tab>
                <Tabs.Tab
                  value="calendar"
                  leftSection={
                    <FontAwesomeIcon
                      style={{ width: rem(12), height: rem(12) }}
                      icon={faCalendarDay}
                    />
                  }
                >
                  Calendar
                </Tabs.Tab>

                <Tabs.Tab
                  value="team"
                  leftSection={
                    <FontAwesomeIcon
                      style={{ width: rem(12), height: rem(12) }}
                      icon={faBaseball}
                    />
                  }
                >
                  Team
                </Tabs.Tab>
                <Tabs.Tab
                  value="date"
                  leftSection={
                    <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faClock} />
                  }
                >
                  Date
                </Tabs.Tab>
                <Tabs.Tab
                  value="map"
                  leftSection={
                    <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faGlobe} />
                  }
                >
                  Map
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="eyechart" p="0">
                <ScrollArea h="100%" p="0" mr={-8} pr={8}>
                  <ScheduleEyeChart />
                </ScrollArea>
              </Tabs.Panel>
              <Tabs.Panel value="calendar" p="xs">
                <ScheduleCalendar />
              </Tabs.Panel>
              <Tabs.Panel value="date" p="xs">
                <DateSummary />
              </Tabs.Panel>
              <Tabs.Panel value="team" p="xs">
                <TeamReport />
              </Tabs.Panel>
              <Tabs.Panel value="map" p="xs">
                <ScheduleMap />
              </Tabs.Panel>
            </Tabs>
          </Flex>
        </Panel>
      </PanelGroup>
    </Flex>
  );
}

const schedulesSearchSchema = z.object({
  // current schedule
  s: z.string().catch('').optional(),
  // tab
  t: z.enum(TAB_NAMES).catch('eyechart'),
  // team name
  team: z
    .enum(MLB_TEAM_KEYS as [string])
    .catch('NYY')
    .optional(),
  // compare
  compare: z.string().array().optional(),
});

export const Route = createFileRoute('/_authenticated/schedules')({
  component: SchedulesPage,
  validateSearch: (search) => schedulesSearchSchema.parse(search),
});
