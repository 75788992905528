import { Box, Container, Divider, Flex, MultiSelect, Select, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  ByeGuarantee,
  ConstraintEntry,
  ConstraintSlot,
  ConstraintSlotTranslations,
  ConstraintType,
  ConstraintTypeTranslations,
  HolidaySlot,
  HolidaySlotTranslations,
  HomeAwayGuarantee,
  MatchupGuarantee,
  NETWORK_KEYS_PRIMETIME,
  NETWORK_KEYS_SIMPLE,
  NETWORK_KEYS_SIMPLE_EXTENDED,
  NetworkTypeSimple,
  NetworkTypeSimpleExtended,
  PrimetimeMinMax,
  RecursivePartial,
  StadiumBlock,
  MLB_TEAM_KEYS,
  TeamGuarantee,
  TeamType,
  WEEK_KEYS,
  WEEK_KEYS_BYE,
  WEEK_KEYS_EXTENDED,
} from 'utils/scheduleConsts';

const MLB_TEAM_MATCHUPS = [
  'BAL-CIN',
  'BAL-CLE',
  'BAL-DAL',
  'BAL-HOU',
  'BAL-KC',
  'BAL-LAC',
  'BAL-NYG',
  'BAL-PIT',
  'BAL-TB',
  'BOS-ATL',
  'BOS-BAL',
  'BOS-CIN',
  'BOS-CLE',
  'BOS-COL',
  'BOS-DET',
  'BOS-HOU',
  'BOS-KC',
  'BOS-LAC',
  'BOS-MIA',
  'BOS-NYG',
  'BOS-NYY',
  'BOS-OAK',
  'BOS-PHI',
  'BOS-PIT',
  'BOS-TB',
  'BOS-TEX',
  'BOS-TOR',
  'BOS-WAS',
  'CHW-ANA',
  'CHW-ARI',
  'CHW-BAL',
  'CHW-BOS',
  'CHW-CIN',
  'CHW-CLE',
  'CHW-COL',
  'CHW-DET',
  'CHW-HOU',
  'CHW-KC',
  'CHW-LAC',
  'CHW-MIA',
  'CHW-NYG',
  'CHW-NYY',
  'CHW-OAK',
  'CHW-PHI',
  'CHW-PIT',
  'CHW-TB',
  'CHW-TEX',
  'CHW-TOR',
  'CHW-WAS',
  'CIN-ARI',
  'CIN-BAL',
  'CIN-BOS',
  'CIN-CHW',
  'CIN-CLE',
  'CIN-COL',
  'CIN-DET',
  'CIN-HOU',
  'CIN-KC',
  'CIN-LAC',
  'CIN-MIA',
  'CIN-NYG',
  'CIN-NYY',
  'CIN-OAK',
  'CIN-PHI',
  'CIN-PIT',
  'CIN-TB',
  'CIN-TEX',
  'CIN-TOR',
  'CIN-WAS',
  'CLE-ANA',
  'CLE-ARI',
  'CLE-BAL',
  'CLE-BOS',
  'CLE-CHW',
  'CLE-CIN',
  'CLE-COL',
  'CLE-DET',
  'CLE-HOU',
  'CLE-KC',
  'CLE-LAC',
  'CLE-MIA',
  'CLE-NYG',
  'CLE-NYY',
  'CLE-OAK',
  'CLE-PHI',
  'CLE-PIT',
  'CLE-TB',
  'CLE-TEX',
  'CLE-TOR',
  'CLE-WAS',
  'COL-ANA',
  'COL-ARI',
  'COL-BAL',
  'COL-BOS',
  'COL-CHW',
  'COL-CIN',
  'COL-CLE',
  'COL-DET',
  'COL-HOU',
  'COL-KC',
  'COL-LAC',
  'COL-MIA',
  'COL-NYG',
  'COL-NYY',
  'COL-OAK',
  'COL-PHI',
  'COL-PIT',
  'COL-TB',
  'COL-TEX',
  'COL-TOR',
  'COL-WAS',
  'DET-ANA',
  'DET-ARI',
  'DET-BAL',
  'DET-BOS',
  'DET-CHW',
  'DET-CIN',
  'DET-CLE',
  'DET-COL',
  'DET-HOU',
  'DET-KC',
  'DET-LAC',
  'DET-MIA',
  'DET-NYG',
  'DET-NYY',
  'DET-OAK',
  'DET-PHI',
  'DET-PIT',
  'DET-TB',
  'DET-TEX',
  'DET-TOR',
  'DET-WAS',
  'HOU-ANA',
  'HOU-ARI',
  'HOU-BAL',
  'HOU-BOS',
  'HOU-CHW',
  'HOU-CIN',
  'HOU-CLE',
  'HOU-COL',
  'HOU-DET',
  'HOU-KC',
  'HOU-LAC',
  'HOU-MIA',
  'HOU-NYG',
  'HOU-NYY',
  'HOU-OAK',
  'HOU-PHI',
  'HOU-PIT',
  'HOU-TB',
  'HOU-TEX',
  'HOU-TOR',
  'HOU-WAS',
  'KC-ANA',
  'KC-ARI',
  'KC-BAL',
  'KC-BOS',
  'KC-CHW',
  'KC-CIN',
  'KC-CLE',
  'KC-COL',
  'KC-DET',
  'KC-HOU',
  'KC-LAC',
  'KC-MIA',
  'KC-NYG',
  'KC-NYY',
  'KC-OAK',
  'KC-PHI',
  'KC-PIT',
  'KC-TB',
  'KC-TEX',
  'KC-TOR',
  'KC-WAS',
  'LAC-ANA',
  'LAC-ARI',
  'LAC-BAL',
  'LAC-BOS',
  'LAC-CHW',
  'LAC-CIN',
  'LAC-CLE',
  'LAC-COL',
  'LAC-DET',
  'LAC-HOU',
  'LAC-KC',
  'LAC-MIA',
  'LAC-NYG',
  'LAC-NYY',
  'LAC-OAK',
  'LAC-PHI',
  'LAC-PIT',
  'LAC-TB',
  'LAC-TEX',
  'LAC-TOR',
  'LAC-WAS',
  'MIA-ANA',
  'MIA-ARI',
  'MIA-BAL',
  'MIA-BOS',
  'MIA-CHW',
  'MIA-CIN',
  'MIA-CLE',
  'MIA-COL',
  'MIA-DET',
  'MIA-HOU',
  'MIA-KC',
  'MIA-LAC',
  'MIA-NYG',
  'MIA-NYY',
  'MIA-OAK',
  'MIA-PHI',
  'MIA-PIT',
  'MIA-TB',
  'MIA-TEX',
  'MIA-TOR',
  'MIA-WAS',
  'NYG-ANA',
  'NYG-ARI',
  'NYG-BAL',
  'NYG-BOS',
  'NYG-CHW',
  'NYG-CIN',
  'NYG-CLE',
  'NYG-COL',
  'NYG-DET',
  'NYG-HOU',
  'NYG-KC',
  'NYG-LAC',
  'NYG-MIA',
  'NYG-NYY',
  'NYG-OAK',
  'NYG-PHI',
  'NYG-PIT',
  'NYG-TB',
  'NYG-TEX',
  'NYG-TOR',
  'NYG-WAS',
  'NYY-ANA',
  'NYY-ARI',
  'NYY-BAL',
  'NYY-BOS',
  'NYY-CHW',
  'NYY-CIN',
  'NYY-CLE',
  'NYY-COL',
  'NYY-DET',
  'NYY-HOU',
  'NYY-KC',
  'NYY-LAC',
  'NYY-MIA',
  'NYY-NYG',
  'NYY-OAK',
  'NYY-PHI',
  'NYY-PIT',
  'NYY-TB',
  'NYY-TEX',
  'NYY-TOR',
  'NYY-WAS',
  'OAK-ANA',
  'OAK-ARI',
  'OAK-BAL',
  'OAK-BOS',
  'OAK-CHW',
  'OAK-CIN',
  'OAK-CLE',
  'OAK-COL',
  'OAK-DET',
  'OAK-HOU',
  'OAK-KC',
  'OAK-LAC',
  'OAK-MIA',
  'OAK-NYG',
  'OAK-NYY',
  'OAK-PHI',
  'OAK-PIT',
  'OAK-TB',
  'OAK-TEX',
  'OAK-TOR',
  'OAK-WAS',
  'PHI-ANA',
  'PHI-ARI',
  'PHI-BAL',
  'PHI-BOS',
  'PHI-CHW',
  'PHI-CIN',
  'PHI-CLE',
  'PHI-COL',
  'PHI-DET',
  'PHI-HOU',
  'PHI-KC',
  'PHI-LAC',
  'PHI-MIA',
  'PHI-NYG',
  'PHI-NYY',
  'PHI-OAK',
  'PHI-PIT',
  'PHI-TB',
  'PHI-TEX',
  'PHI-TOR',
  'PHI-WAS',
  'PIT-ANA',
  'PIT-ARI',
  'PIT-BAL',
  'PIT-BOS',
  'PIT-CHW',
  'PIT-CIN',
  'PIT-CLE',
  'PIT-COL',
  'PIT-DET',
  'PIT-HOU',
  'PIT-KC',
  'PIT-LAC',
  'PIT-MIA',
  'PIT-NYG',
  'PIT-NYY',
  'PIT-OAK',
  'PIT-PHI',
  'PIT-TB',
  'PIT-TEX',
  'PIT-TOR',
  'PIT-WAS',
  'TB-ANA',
  'TB-ARI',
  'TB-BAL',
  'TB-BOS',
  'TB-CHW',
  'TB-CIN',
  'TB-CLE',
  'TB-COL',
  'TB-DET',
  'TB-HOU',
  'TB-KC',
  'TB-LAC',
  'TB-MIA',
  'TB-NYG',
  'TB-NYY',
  'TB-OAK',
  'TB-PHI',
  'TB-PIT',
  'TB-TEX',
  'TB-TOR',
  'TB-WAS',
  'TEX-ANA',
  'TEX-ARI',
  'TEX-BAL',
  'TEX-BOS',
  'TEX-CHW',
  'TEX-CIN',
  'TEX-CLE',
  'TEX-COL',
  'TEX-DET',
  'TEX-HOU',
  'TEX-KC',
  'TEX-LAC',
  'TEX-MIA',
  'TEX-NYG',
  'TEX-NYY',
  'TEX-OAK',
  'TEX-PHI',
  'TEX-PIT',
  'TEX-TB',
  'TEX-TOR',
  'TEX-WAS',
  'TOR-ANA',
  'TOR-ARI',
  'TOR-BAL',
  'TOR-BOS',
  'TOR-CHW',
  'TOR-CIN',
  'TOR-CLE',
  'TOR-COL',
  'TOR-DET',
  'TOR-HOU',
  'TOR-KC',
  'TOR-LAC',
  'TOR-MIA',
  'TOR-NYG',
  'TOR-NYY',
  'TOR-OAK',
  'TOR-PHI',
  'TOR-PIT',
  'TOR-TB',
  'TOR-TEX',
  'TOR-WAS',
  'WAS-ANA',
  'WAS-ARI',
  'WAS-BAL',
  'WAS-BOS',
  'WAS-CHW',
  'WAS-CIN',
  'WAS-CLE',
  'WAS-COL',
  'WAS-DET',
  'WAS-HOU',
  'WAS-KC',
  'WAS-LAC',
  'WAS-MIA',
  'WAS-NYG',
  'WAS-NYY',
  'WAS-OAK',
  'WAS-PHI',
  'WAS-PIT',
  'WAS-TB',
  'WAS-TEX',
  'WAS-TOR',
] as const;

const StadiumBlockEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<StadiumBlock>;
  onChange: (value: RecursivePartial<StadiumBlock>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.slot]);
  return (
    <Flex gap="xl">
      <Select
        w="80"
        withCheckIcon={false}
        allowDeselect={false}
        value={value.params?.team}
        onChange={(team) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              team: team as (typeof MLB_TEAM_KEYS)[number],
            },
          })
        }
        data={MLB_TEAM_KEYS.slice().sort()}
        searchable
        description="Stadium"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="80"
        withCheckIcon={false}
        allowDeselect={false}
        data={WEEK_KEYS.map((week) => week.toString())}
        disabled={isWeekFieldDisabled}
        value={String(value.params?.week)}
        onChange={(week) => {
          onChange({
            ...value,
            params: {
              ...value.params,
              week: Number(week) as (typeof WEEK_KEYS)[number],
            },
          });
        }}
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="240"
        withCheckIcon={false}
        data={Object.entries(ConstraintSlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        searchable
        // @ts-ignore
        value={value.params?.slot?.length ? value.params?.slot : []}
        onChange={(slot) => {
          let currSlot = slot;
          let currWeek = value.params?.week ? Number(value.params.week) : undefined;
          const containsAll = slot.includes(ConstraintSlot.ALL);
          const containsTgiv = slot.includes(ConstraintSlot.TGIV);
          const includesChristmas = slot.includes(ConstraintSlot.CHRISTMAS);
          const includesBlackFriday = slot.includes(ConstraintSlot.BLACK_FRIDAY);
          if (containsAll) {
            currSlot = [ConstraintSlot.ALL];
          } else if (containsTgiv) {
            currSlot = [ConstraintSlot.TGIV];
            currWeek = 13;
          } else if (includesChristmas) {
            currSlot = [ConstraintSlot.CHRISTMAS];
            currWeek = 17;
          } else if (includesBlackFriday) {
            currSlot = [ConstraintSlot.BLACK_FRIDAY];
            currWeek = 13;
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              // @ts-ignore
              slot: currSlot,
              // @ts-ignore
              week: currWeek,
            },
          });
        }}
        description="Slot"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const MatchupGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<MatchupGuarantee>;
  onChange: (value: RecursivePartial<MatchupGuarantee>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.holiday_slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.holiday_slot]);
  const [enabledNetworks, setEnabledNetworks] = useState<NetworkTypeSimple[]>(
    NETWORK_KEYS_SIMPLE.slice()
  );
  return (
    <Flex gap="xl">
      <Select
        w="120"
        withCheckIcon={false}
        allowDeselect={false}
        data={MLB_TEAM_MATCHUPS}
        value={value.params?.matchup || ''}
        onChange={(matchup) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              matchup: matchup as (typeof MLB_TEAM_MATCHUPS)[number],
            },
          })
        }
        searchable
        description="Matchup"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        disabled={isWeekFieldDisabled}
        hidePickedOptions
        w="200"
        withCheckIcon={false}
        data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
        // @ts-ignore
        value={
          value.params?.week
            ? value.params?.week
                ?.sort((a, b) => Number(a) - Number(b))
                .map((week) => week!.toString())
            : []
        }
        onChange={(weekVal) => {
          onChange({
            ...value,
            params: {
              ...value.params,
              week: weekVal.includes('All')
                ? ['All']
                : weekVal
                    .map((week) => Number(week) as (typeof WEEK_KEYS)[number])
                    .sort((a, b) => a - b),
            },
          });
        }}
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="160"
        withCheckIcon={false}
        data={enabledNetworks
          .slice()
          .sort()
          .map((network) => ({
            label: network,
            value: network,
          }))}
        // @ts-ignore
        value={value.params?.network?.sort() || []}
        onChange={(network) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              network: network.sort() as (typeof NETWORK_KEYS_SIMPLE)[number][],
            },
          })
        }
        searchable
        description="Network"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="120"
        withCheckIcon={false}
        allowDeselect={false}
        data={[
          { label: 'True', value: 'true' },
          { label: 'False', value: 'false' },
        ]}
        value={value.params?.inclusive?.toString()}
        onChange={(inclusive) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              inclusive: inclusive === 'true',
            },
          })
        }
        searchable
        description="Inclusive"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="180"
        withCheckIcon={false}
        data={Object.entries(HolidaySlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        value={value.params?.holiday_slot || ''}
        onChange={(slot) => {
          const isTgiv = slot === HolidaySlot.TGIV;
          const isChristmas = slot === HolidaySlot.CHRISTMAS;
          const isBlackFriday = slot === HolidaySlot.BLACK_FRIDAY;

          let currWeek = value.params?.week?.length ? value.params.week : [];
          let currNetwork = value.params?.network?.length ? value.params.network : [];
          if (isBlackFriday) {
            currWeek = [13];
            currNetwork = ['Amz'];
            setEnabledNetworks(['Amz']);
          } else if (isTgiv) {
            currWeek = [13];
            const tgivNetworks = ['CBS', 'Fox', 'NBC'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              tgivNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(tgivNetworks);
          } else if (isChristmas) {
            currWeek = [17];
            const christmasNetworks = ['CBS', 'Fox'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              christmasNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(christmasNetworks);
          } else {
            setEnabledNetworks(NETWORK_KEYS_SIMPLE.slice());
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              week: currWeek,
              network: currNetwork,
              holiday_slot: slot ? (slot as HolidaySlot) : undefined,
            },
          });
        }}
        searchable
        description="Holiday (Optional)"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const TeamGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<TeamGuarantee>;
  onChange: (value: RecursivePartial<TeamGuarantee>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.holiday_slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.holiday_slot]);
  const [enabledNetworks, setEnabledNetworks] = useState<NetworkTypeSimple[]>(
    NETWORK_KEYS_SIMPLE.slice()
  );
  return (
    <Flex gap="xl">
      <MultiSelect
        hidePickedOptions
        w="160"
        withCheckIcon={false}
        data={MLB_TEAM_KEYS.slice().sort()}
        // @ts-ignore
        value={value.params?.team?.length ? value.params.team.sort() : []}
        onChange={(team) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              team: team.sort() as (typeof MLB_TEAM_KEYS)[number][],
            },
          })
        }
        searchable
        description="Teams"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        disabled={isWeekFieldDisabled}
        hidePickedOptions
        w="120"
        withCheckIcon={false}
        data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
        value={
          // @ts-ignore
          value.params?.week?.length
            ? value.params.week
                .sort((a, b) => Number(a) - Number(b))
                .map((week) => week!.toString())
                .sort()
            : []
        }
        onChange={(week) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              // @ts-ignore
              week: week.includes('All')
                ? ['All']
                : week.sort((a, b) => Number(a) - Number(b)).map((w) => Number(w)),
            },
          })
        }
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="160"
        withCheckIcon={false}
        data={enabledNetworks
          .slice()
          .sort()
          .map((network) => ({
            label: network,
            value: network,
          }))}
        // @ts-ignore
        value={value.params?.network?.length ? value.params.network.sort() : []}
        onChange={(network) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              network: network.sort() as (typeof NETWORK_KEYS_SIMPLE)[number][],
            },
          })
        }
        searchable
        description="Network"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.min_appearances?.toString()}
        onChange={(min_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              min_appearances: Number(min_appearances),
            },
          })
        }
        searchable
        description="Min"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.max_appearances?.toString()}
        onChange={(max_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              max_appearances: Number(max_appearances),
            },
          })
        }
        searchable
        description="Max"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="180"
        withCheckIcon={false}
        data={Object.entries(HolidaySlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        value={value.params?.holiday_slot || ''}
        onChange={(slot) => {
          const isTgiv = slot === HolidaySlot.TGIV;
          const isChristmas = slot === HolidaySlot.CHRISTMAS;
          const isBlackFriday = slot === HolidaySlot.BLACK_FRIDAY;

          let currWeek = value.params?.week?.length ? value.params.week : [];
          let currNetwork = value.params?.network?.length ? value.params.network : [];
          if (isBlackFriday) {
            currWeek = [13];
            currNetwork = ['Amz'];
            setEnabledNetworks(['Amz']);
          } else if (isTgiv) {
            currWeek = [13];
            const tgivNetworks = ['CBS', 'Fox', 'NBC'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              tgivNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(tgivNetworks);
          } else if (isChristmas) {
            currWeek = [17];
            const christmasNetworks = ['CBS', 'Fox'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              christmasNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(christmasNetworks);
          } else {
            setEnabledNetworks(NETWORK_KEYS_SIMPLE.slice());
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              week: currWeek,
              network: currNetwork,
              holiday_slot: slot ? (slot as HolidaySlot) : undefined,
            },
          });
        }}
        searchable
        description="Holiday (Optional)"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const ByeGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<ByeGuarantee>;
  onChange: (value: RecursivePartial<ByeGuarantee>) => void;
}) => (
  <Flex gap="xl">
    <Select
      w="80"
      withCheckIcon={false}
      allowDeselect={false}
      value={value.params?.team}
      onChange={(team) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            team: team as TeamType,
          },
        })
      }
      data={MLB_TEAM_KEYS.slice().sort() as TeamType[]}
      searchable
      description="Team"
      inputWrapperOrder={['input', 'description']}
    />
    <MultiSelect
      hidePickedOptions
      w="200"
      withCheckIcon={false}
      data={WEEK_KEYS_BYE.map((week) => week.toString())}
      // @ts-ignore
      value={
        value.params?.week
          ? value.params?.week
              ?.sort((a, b) => Number(a) - Number(b))
              .map((week) => week!.toString())
          : []
      }
      onChange={(weekVal) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            week: weekVal.length
              ? weekVal
                  .map((week) => Number(week) as (typeof WEEK_KEYS_BYE)[number])
                  .sort((a, b) => a - b)
              : [],
          },
        })
      }
      searchable
      description="Week"
      inputWrapperOrder={['input', 'description']}
    />
    <Select
      w="120"
      withCheckIcon={false}
      allowDeselect={false}
      data={[
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ]}
      value={value.params?.inclusive?.toString()}
      onChange={(inclusive) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            inclusive: inclusive === 'true',
          },
        })
      }
      searchable
      description="Inclusive"
      inputWrapperOrder={['input', 'description']}
    />
  </Flex>
);

const HomeAwayGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<HomeAwayGuarantee>;
  onChange: (value: RecursivePartial<HomeAwayGuarantee>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.holiday_slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.holiday_slot]);
  const [enabledNetworks, setEnabledNetworks] = useState<NetworkTypeSimpleExtended[]>(
    NETWORK_KEYS_SIMPLE_EXTENDED.slice()
  );
  return (
    <Flex gap="md">
      <MultiSelect
        hidePickedOptions
        w="120"
        withCheckIcon={false}
        data={MLB_TEAM_KEYS.slice().sort()}
        // @ts-ignore
        value={value.params?.team?.length ? value.params.team.sort() : []}
        onChange={(team) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              team: team.sort() as (typeof MLB_TEAM_KEYS)[number][],
            },
          })
        }
        searchable
        description="Teams"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="160"
        withCheckIcon={false}
        allowDeselect={false}
        data={[
          { label: 'Home', value: 'home' },
          { label: 'Away', value: 'away' },
        ]}
        value={value.params?.home_away?.toString()}
        onChange={(home_away) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              home_away: home_away as 'home' | 'away',
            },
          })
        }
        searchable
        description="Home/Away"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        disabled={isWeekFieldDisabled}
        hidePickedOptions
        w="100"
        withCheckIcon={false}
        data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
        value={
          // @ts-ignore
          value.params?.week?.length
            ? value.params.week
                .sort((a, b) => Number(a) - Number(b))
                .map((week) => week!.toString())
                .sort()
            : []
        }
        onChange={(week) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              // @ts-ignore
              week: week.includes('All')
                ? ['All']
                : week.sort((a, b) => Number(a) - Number(b)).map((w) => Number(w)),
            },
          })
        }
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="140"
        withCheckIcon={false}
        data={enabledNetworks
          .slice()
          .sort()
          .map((network) => ({
            label: network,
            value: network,
          }))}
        // @ts-ignore
        value={value.params?.network?.length ? value.params.network.sort() : []}
        onChange={(network) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              network: network.includes('All')
                ? ['All']
                : (network.sort() as (typeof NETWORK_KEYS_SIMPLE_EXTENDED)[number][]),
            },
          })
        }
        searchable
        description="Network"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="180"
        withCheckIcon={false}
        data={Object.entries(HolidaySlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        value={value.params?.holiday_slot || ''}
        onChange={(slot) => {
          const isTgiv = slot === HolidaySlot.TGIV;
          const isChristmas = slot === HolidaySlot.CHRISTMAS;
          const isBlackFriday = slot === HolidaySlot.BLACK_FRIDAY;

          let currWeek = value.params?.week?.length ? value.params.week : [];
          let currNetwork = value.params?.network?.length ? value.params.network : [];
          if (isBlackFriday) {
            currWeek = [13];
            currNetwork = ['Amz'];
            setEnabledNetworks(['Amz']);
          } else if (isTgiv) {
            currWeek = [13];
            const tgivNetworks = ['CBS', 'Fox', 'NBC'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              tgivNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(tgivNetworks);
          } else if (isChristmas) {
            currWeek = [17];
            const christmasNetworks = ['CBS', 'Fox'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              christmasNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(christmasNetworks);
          } else {
            setEnabledNetworks(NETWORK_KEYS_SIMPLE_EXTENDED.slice());
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              week: currWeek,
              network: currNetwork,
              holiday_slot: slot ? (slot as HolidaySlot) : undefined,
            },
          });
        }}
        searchable
        description="Holiday (Optional)"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.min_appearances?.toString()}
        onChange={(min_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              min_appearances: Number(min_appearances),
            },
          })
        }
        searchable
        description="Min"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.max_appearances?.toString()}
        onChange={(max_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              max_appearances: Number(max_appearances),
            },
          })
        }
        searchable
        description="Max"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const PrimetimeMinMaxEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<PrimetimeMinMax>;
  onChange: (value: RecursivePartial<PrimetimeMinMax>) => void;
}) => (
  <Flex gap="xl">
    <MultiSelect
      hidePickedOptions
      w="120"
      withCheckIcon={false}
      data={MLB_TEAM_KEYS.slice().sort()}
      // @ts-ignore
      value={value.params?.team?.length ? value.params.team.sort() : []}
      onChange={(team) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            team: team.sort() as (typeof MLB_TEAM_KEYS)[number][],
          },
        })
      }
      searchable
      description="Teams"
      inputWrapperOrder={['input', 'description']}
    />
    <MultiSelect
      hidePickedOptions
      w="140"
      withCheckIcon={false}
      data={NETWORK_KEYS_PRIMETIME}
      // @ts-ignore
      value={value.params?.network?.length ? value.params.network.sort() : []}
      onChange={(network) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            network: network.includes('All')
              ? ['All']
              : (network.sort() as (typeof NETWORK_KEYS_PRIMETIME)[number][]),
          },
        })
      }
      searchable
      description="Network"
      inputWrapperOrder={['input', 'description']}
    />
    <Select
      w="100"
      withCheckIcon={false}
      allowDeselect={false}
      data={[0, ...WEEK_KEYS].map((week) => week.toString())}
      value={value.params?.min_appearances?.toString()}
      onChange={(min_appearances) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            min_appearances: Number(min_appearances),
          },
        })
      }
      searchable
      description="Min"
      inputWrapperOrder={['input', 'description']}
    />
    <Select
      w="100"
      withCheckIcon={false}
      allowDeselect={false}
      data={[0, ...WEEK_KEYS].map((week) => week.toString())}
      value={value.params?.max_appearances?.toString()}
      onChange={(max_appearances) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            max_appearances: Number(max_appearances),
          },
        })
      }
      searchable
      description="Max"
      inputWrapperOrder={['input', 'description']}
    />
  </Flex>
);

export function ConstraintEditor({
  value,
  onChange,
}: {
  value: RecursivePartial<ConstraintEntry>;
  onChange: (value: RecursivePartial<ConstraintEntry>) => void;
}) {
  return (
    <Box>
      <Container fluid>
        <Stack>
          <Select
            w={240}
            label="Constraint type"
            data={Object.values(ConstraintType).map((type) => ({
              label: ConstraintTypeTranslations[type],
              value: type,
            }))}
            value={value.type}
            onChange={(_value, option) => onChange({ type: option.value as ConstraintType })}
          />
          <Divider />
          {value.type === ConstraintType.STADIUM_BLOCK && (
            <StadiumBlockEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.MATCHUP_GUARANTEE && (
            <MatchupGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.TEAM_GUARANTEE && (
            <TeamGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.BYE_GUARANTEE && (
            <ByeGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.HOME_AWAY_GUARANTEE && (
            <HomeAwayGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.PRIMETIME_MIN_MAX && (
            <PrimetimeMinMaxEntry value={value} onChange={onChange} />
          )}
        </Stack>
      </Container>
    </Box>
  );
}
